import { useCallback, useEffect, useMemo, useState, useRef, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import customToast from "../../../../components/CustomToast";
import ProfitAnalysisBox from "../../../../components/ProfitAnalysisBox";
import PageTitle from "../../../../components/PageTitle";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import serialise, { generateSelectData } from "../../../../utils/serialiseResponse";
import {RiEdit2Fill} from "react-icons/ri" ;
import { monthOptions, returnMonthFromIndex, yearOptions } from "../../../../utils/helper";
import { AiFillSave } from "react-icons/ai";
import { viewROI } from "../../../../services/profitCenter";
import Table from "../../../../components/Table";
import moment from "moment-timezone";
const DatePickers = lazy(() => import("../../../../components/DatePicker"))

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    border: "2px solid #36ABF9",
    borderRadius: "5px",
    color: "#9e9ad0",
    fontSize: "1.5rem",
    fontWeight: "500",

    width: "20rem",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",
    fontFamily: "inherit",
    fontSize: "1.5rem",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
};

const ROI = () => {
  const dispatch = useDispatch();
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [hq, setHq] = useState(null);
  const [expense, setExpense] = useState(0);
  const [products, setProducts] = useState([]);
  const [priceType, setPriceType] = useState({ value: "pts", label: "PTS" });
  const monthLabel = returnMonthFromIndex(month)
  console.log(month)
  console.log(monthLabel)
 
  
  // const headquarterSelect = useMemo(
  //   () => generateSelectData(headquarters.data, "name"),
  //   [headquarters]
  // );
  const priceOptions = useMemo(()=>[
    { value: "mrp", label: "MRP" },
    { value: "ptr", label: "PTR" },
    { value: "pts", label: "PTS" },
    { value: "cus1", label: "PRICE 1" },
    { value: "cus2", label: "PRICE 2" },
    { value: "cus3", label: "PRICE 3" },
  ],[]);
  // useEffect(() => {
  //   dispatch(setHeadquarters());
  //   return () => {
  //     dispatch(clearHeadquarters());
  //   };
  // }, [dispatch]);

  useEffect(()=>{
    if(!year )return ;

    viewROI({ month:month, year:year, hq:hq?.value, priceType:priceType.value}).then(({data})=>{
      console.log('data', data);
      setProducts(data);
    }).catch((err)=>{
      customToast.error("Sorry Something went wrong!")
    }) ;
  },[month, year, hq, priceType]) ;

  // useEffect(()=>{
  //   let e = 0 ;
  //   for(const x of products){
  //     e += x?.total || 0 ;
  //   }
  //   setExpense(e);
  // },[products]);


  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 50,
      //   disableFilters: true,
      //   maxWidth: 50,
      // },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        disableSortBy: true,
        disableFilters:true,
      },
      { Header: `Total Cost ${monthLabel}`,
        accessor: "totalCost",
        disableSortBy: true,
        disableFilters:true,
      },
      { Header: `Total Revenue ${monthLabel}`,
        accessor: "totalRevenue",
        disableSortBy: true,
        disableFilters:true,
      },
      { Header: `Total ${monthLabel}`,
        accessor: "roi",
        disableSortBy: true,
        disableFilters:true,
      },
      { Header: `ROI Percentage ${monthLabel}`,
        accessor: "roiPercentage",
        disableSortBy: true,
        disableFilters:true,
        Cell: (props) => {
          return (
            <span>{props?.row?.original?.roiPercentage ? props?.row?.original?.roiPercentage+" %" : ""}</span>
          )
        },
      },
      { Header: `Cumulative Total ${year ? " (till "+monthLabel+" "+year+")" : ""}`,
        accessor: "cumulativeRevenue",
        disableSortBy: true,
        disableFilters:true,
      },
      { Header: `Cumulative ROI ${year ? " (till "+monthLabel+" "+year+")" : ""}`,
        accessor: "cumulativeRoiPercentage",
        disableSortBy: true,
        disableFilters:true,
        Cell: (props) => {
          return (
            <span>{props?.row?.original?.cumulativeRoiPercentage ? props?.row?.original?.cumulativeRoiPercentage+" %" : ""}</span>
          )
        },
      },
    ],
    [month]
  );

  const tableData = useMemo(()=>serialise(products),[products]);
  
  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="ROI" previousUrl="/profit-analysis" />
        <section className="area-creation-content__form">
          <form >
            <div className="d-flex flex-wrap gap-5 justify-content-between ">
              <div className="d-flex flex-wrap gap-5 ">
                <div className="util-tp-filter">
                  <p className="mb-3">
                    Select Month <span className="asterisk-imp">*</span>
                  </p>
                  <Suspense>
                    <DatePickers
                      placeholder="Select Month"
                      setMonth={setMonth}
                      setYear={setYear}
                    />
                  </Suspense>
                </div>
                <div className="util-tp-filter">
                  <p>
                    Select Year <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    styles={selectStyles}
                    placeholder="Select Year"
                    className="mt-3"
                    options={yearOptions}
                    value={year}
                    onChange={(e) => setYear({ ...e })}
                  />
                </div>
                {/* <div className="util-tp-filter">
                  <p>
                    Select Headquarter <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    styles={selectStyles}
                    placeholder="Select HQ"
                    className="mt-3"
                    options={headquarterSelect}
                    value={hq}
                    onChange={(e) => setHq({ ...e })}
                  />
                </div> */}
                <div className="util-tp-filter">
                  <p>
                    Select Price <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    styles={selectStyles}
                    placeholder="Select Price"
                    className="mt-3"
                    options={priceOptions}
                    value={priceType}
                    onChange={(e) => setPriceType({ ...e })}
                  />
                </div>
              </div>
              {/* <ProfitAnalysisBox row1={`${products.length} Products`} 
                row2={`Period: ${month?.label} ${year} | ${new Date(year, Number(month)+1, 0).getDate()} Days`} 
                row3={`Rs ${expense}`} row4="TOTAL VALUE"
              /> */}
            </div>
          </form>
        </section>
      </div>
      <div className="filter-table pe-4">
        <Table columns={columns} data={tableData} />
      </div>
    </main>
  );
};

export default ROI;
