import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/v1/call-planning`;

export const addFinalCallPlanning = async (filters) => {
  const { data } = await axios.post(`${baseURL}`, filters, {
    headers: setAuth(),
  });

  return data;
};

export const getMonthlyCallPlanning = async (filters) => {
  const { data } = await axios.get(`${baseURL}/for-month`, {
    params: filters,
    headers: setAuth(),
  });

  return data;
};

export const viewCallPlanningForApproval = async () => {
  const { data } = await axios.get(`${baseURL}/view-for-approval`, {
    headers: setAuth(),
  });

  return data;
};

export const approveCallPlanning = async (filters) => {
  const { data } = await axios.put(`${baseURL}/approve`, filters, {
    headers: setAuth(),
  });

  return data;
};

export const rejectCallPlanning = async (filters) => {
  const { data } = await axios.put(`${baseURL}/reject`, filters, 
  {
    headers: setAuth(),
  });

  return data;
};

export const deleteCallPlanning = async (filters) => {
  const { data } = await axios.put(`${baseURL}/delete/${filters}`, {},
  {
    headers: setAuth(),
  });

  return data;
};

export const viewCallPlanningReport = async (filters) => {
  const { data } = await axios.get(`${baseURL}/reports`, {
    params: filters,
    headers: setAuth(),
  });

  return data;
};