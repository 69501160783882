import { AiFillEye } from "react-icons/ai";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUsers,
  setUsers,
} from "../../../../reducers/users/employeeReducer";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import PageTitle from "../../../../components/PageTitle";
import customToast from "../../../../components/CustomToast";
import { AiOutlineUpload } from "react-icons/ai";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import { addComment, viewComments } from "../../../../services/tickets";
import "../index.css";
import { formatDate } from "../../../../utils/helper";
const TicketDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [ticket, setTicket] = useState(null);
  const [comment, setComment] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [showAttachment, setShowAttachment] = useState(null);
  const [openImg, setOpenImg] = useState(false);
  const [comments, setComments] = useState([]);
  const loggedIn = useSelector(({ user }) => user);
  const [commentLoad,setCommentLoad] = useState(false);

  useEffect(() => {
    const fetch = () => {
      viewComments({ page: 1, limit: 10, ticketId: ticket._id })
        .then((data) => {
          setComments(data.data);
          // customToast.success(data.message || "comments fetched successfully");
        })
        .catch((err) => {
          customToast.error("Sorry failed to load comments");
        });
    };
    if (ticket) fetch();
    return () => {
      dispatch(clearUsers());
    };
  }, [ticket, dispatch]);
  useEffect(() => {
    setTicket(location?.state);
  }, [location]);

  const handleSetFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOpenFile = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!comment) return customToast.error("comment can not be empty!");
    setLoading(true);

    try {
      setCommentLoad(true);
      const formData = new FormData();
      formData.append("ticketId", ticket._id);
      formData.append("message", comment);
      formData.append(
        "userName",
        loggedIn.user.firstName + " " + loggedIn.user.lastName
      );
      if (file) {
        formData.append("file", file);
      }
      const data = await addComment(formData);
      setComments([data.data, ...comments]);
      setCommentLoad(false);
      setFile(null);
      customToast.success(data.message || "comment added successfully.");
    } catch (err) {
      console.log(err);
      customToast.error("Sorry something went wrong!");
    } finally {
      setLoading(false);
      setComment("");
    }
  };

  const videoRef = useRef(null);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      }
    }
  };

  useEffect(() => {
    if (showAttachment) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
    } else {
      document.body.style.overflow = "";
      document.body.style.position = "";
      document.body.style.width = "";
    }

    return () => {
      document.body.style.overflow = "";
      document.body.style.position = "";
      document.body.style.width = "";
    };
  }, [showAttachment]);

  return (
    <div className="ps-4 w-100">
      <div className="main-content admin-content">
        <div
          className="area-creation-content"
          style={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          <PageTitle title="Ticket Details" hierarchy={true} />

          <section className="area-creation-content__form">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="row w-100 align-items-start">
                <div className="col-8">
                  <div className="d-flex">
                    <div className="mt-2 col-4">
                      <label htmlFor="title">Title</label>
                      <br />
                      <p className="w-100 accordion-body p-0" id="city">
                        {ticket?.title}
                      </p>
                    </div>
                    <div className="mt-2 col-4">
                      <label htmlFor="title">Status</label>
                      <br />
                      <p
                        className={`w-100 accordion-body p-0 ${
                          ticket?.status === 0
                            ? "text-warning"
                            : ticket?.status === 1
                            ? "text-success"
                            : ticket?.status === -1
                            ? "text-danger"
                            : "text-grey"
                        }`}
                        id="city"
                      >
                        {ticket?.status === 0
                          ? "Pending"
                          : ticket?.status === 1
                          ? "Closed"
                          : ticket?.status === -1
                          ? "Rejected"
                          : "N/A"}
                      </p>
                    </div>
                    <div className="mt-2 col-4">
                      <label htmlFor="createdAt">Created At</label>
                      <br />
                      <p className={`w-100 accordion-body p-0`} id="city">
                        {formatDate(ticket?.createdAt)}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 col-4">
                    <label htmlFor="city">
                      Description <span className="asterisk-imp">*</span>
                    </label>
                    <br />
                    <p className="w-100 accordion-body p-0" id="city">
                      {ticket?.description}
                    </p>
                  </div>
                </div>
                <div className="col-4 text-end">
                  {ticket?.uri.split(".").pop() === "mp4" ? (
                    <video
                      src={ticket.uri}
                      controls
                      style={{
                        width: "100%",
                        maxHeight: "200px",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <>
                      <img
                        src={ticket?.uri}
                        onClick={() => setOpenImg(true)}
                        alt="No img"
                        style={{
                          cursor: "pointer",
                          maxWidth: "100%",
                          maxHeight: "150px",
                          objectFit: "cover",
                        }}
                      />
                      {openImg && (
                        <Lightbox
                          open={openImg}
                          close={() => setOpenImg(false)}
                          slides={[{ src: ticket?.uri }]}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
              {ticket?.status !== 1 && (
                <div className="row">
                  <div className="col-md-8  col-lg-8">
                    <label htmlFor="state">
                      Enter Comment <span className="asterisk-imp">*</span>
                    </label>
                    <br />
                    <div className="d-flex align-items-center">
                      <input
                        value={comment}
                        onChange={({ target }) => setComment(target.value)}
                        type="text"
                        className="me-5"
                        id="state"
                        placeholder="Add New Comment"
                      />
                      <AiOutlineUpload size={30} onClick={handleOpenFile} />
                      <input
                        type="file"
                        className="d-none"
                        ref={fileInputRef}
                        onChange={(e) => handleSetFile(e)}
                      />
                      {file && (
                        <div className="mt-2">
                          {file.type === "video/mp4" ? (
                            <video
                              src={URL.createObjectURL(file)}
                              style={{ height: "6rem", width: "6rem" }}
                            />
                          ) : (
                            <img
                              src={URL.createObjectURL(file)} // Create a temporary URL for the uploaded file
                              // className="w-25 h-25"
                              style={{ height: "6rem", width: "6rem" }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 admin-content-submit-button">
                    <button
                      type="submit"
                      className="button-blue-gradient"
                      disabled={commentLoad}
                    >
                      Add
                    </button>
                  </div>
                </div>
              )}
            </form>
          </section>
        </div>
        <section
          style={{
            height: "600px",
            overflowY: "auto",
            overflowx: "hidden",
            width: "100%",
            // border:"1px solid #c7c4e9",
          }}
          className="coments-container"
        >
          {comments?.map((el, idx) => (
            <div className="w-100">
              <div
                key={idx}
                className={`p-3 ${
                  el.userName !== "Medorn Team" &&
                  "ms-auto commentBox-dark-bg bg-dark_input"
                } commentBox`}
              >
                <div className={`d-flex justify-content-between`}>
                  <span className="web-app__heading text-nowrap">
                    {el.userName}
                  </span>
                  <div className="d-flex align-items-start fs-5 justify-content-end w-100">
                    {new Date(el.createdAt).toLocaleDateString("hi-In") +
                      " " +
                      new Date(el.createdAt).toLocaleTimeString()}
                  </div>
                </div>
                <div className="d-flex gap-2 align-items-start justify-content-between">
                  <p>{el.text}</p>
                  <div className="">
                    {el?.uri && (
                      <AiFillEye onClick={() => setShowAttachment(el.uri)} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {showAttachment && (
            <div>
              {showAttachment.split(".").pop() === "mp4" ? (
                <Lightbox
                  open={!!showAttachment}
                  close={() => {
                    setShowAttachment(null);
                  }}
                  plugins={[Video]}
                  slides={[
                    {
                      type: "video", 
                      width: 1280,
                      height: 720,
                      sources: [{src:showAttachment}], 
                      autoplay: true, 
                      controls: true, 
                    },
                  ]}
                />
              ):(
                <Lightbox
                  open={!!showAttachment}
                  close={() => {
                    setShowAttachment(null);
                  }}
                  slides={[{ src: showAttachment }]}
                />

              )}
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default TicketDetails;
