import { useState, useCallback, useMemo, useEffect, lazy, Suspense } from "react";
import { dcrStyles } from "../../../../public/stylesheets/selectStyles";
import Select from "react-select";
import { monthOptions, yearOptions } from "../../../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import { getAchievedTarget } from "../../../../../services/reports";
import serialise from "../../../../../utils/serialiseResponse";
import Table from "../../../../../components/Table";
import moment from "moment-timezone";
const DatePickers = lazy(() => import("../../../../../components/DatePicker"))

const UtilAchievedTarget = () => {
    const navigate = useNavigate();

    const [month, setMonth] = useState(moment().month());
    const [year, setYear] = useState(moment().year());
    const [reports, setReports] = useState([]);

    const columns = useMemo(
        () => [
            // {
            //     Header: "Sr No.",
            //     accessor: "sr",
            //     minWidth: 50,
            //     maxWidth: 50,
            //     disableFilters: true,
            // },
            {
                Header: "Headquarter",
                accessor: "hq",
                disableSortBy: true,
                showTotal: true,
                placeholderTotal: "Total",
            },
            {
                Header: "Alloted Target",
                accessor: "target",
                disableFilters: true,
                showTotal: true,
                totalAccessor: "target"
            },
            {
                Header: "Primary Sales",
                accessor: "totalPrimarySales",
                disableFilters: true,
                disableSortBy: true,
                showTotal: true,
                totalAccessor: "totalPrimarySales"
            },
            {
                Header: "Primary Sales %",
                accessor: "primarySalePercentage",
                disableFilters: true,
                disableSortBy: true,
                totalAccessor: "primarySalePercentage"
            },
            {
                Header: "Secondary Sales",
                accessor: "totalSecondarySales",
                disableFilters: true,
                disableSortBy: true,
                showTotal: true,
                totalAccessor: "totalSecondarySales"
            },
            {
                Header: "Secondry Sales %",
                accessor: "secondarySalesPercentage",
                disableFilters: true,
                disableSortBy: true,
                totalAccessor: "secondarySalesPercentage"
            },

        ],
        [month, year]
    );

    const handleSubmit = useCallback(
        async (e) => {
            try {
                e.preventDefault();
                const filter = {
                    month: month,
                    year: Number(year),
                };
                const data = await getAchievedTarget(filter);
                console.log('data came ', data.data);
                setReports(data.data);
            } catch (error) {
                console.log(error);
            }
        }, [year, month])

    const tableData = useMemo(() => serialise(reports), [reports]);
    

    return (
        <main className="main-content admin-content m-5">
            <h2 className="web-app__heading" style={{ display: "flex", alignItems: "center" }}>
                <MdArrowBackIosNew style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <span style={{ display: "inline-block", margin: "0 1rem" }}>
                    Achieved Target
                </span>
            </h2>
            <div className="area-creation-content w-100">
                <form onSubmit={handleSubmit}>
                    <div className="util-list-filters d-flex justify-content-between">
                        <div className="d-flex">
                            <div className="util-tp-filter me-5">
                                <p className="mb-3">
                                    Select Month <span className="asterisk-imp">*</span>
                                </p>
                                <Suspense>
                                    <DatePickers
                                        placeholder="Select Month"
                                        setMonth={setMonth}
                                        setYear={setYear}
                                    />
                                </Suspense>
                            </div>
                        </div>
                        <div className="util-tp-filter">
                            <button type="submit" className="button-blue-gradient mt-5">
                                See Reports
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {tableData.length > 0 ? (
                <div className="filter-table">
                    <Table columns={columns} data={tableData} fileSrc="Achieved Target" />
                </div>
            ) : (
                <h3 className="mt-2 no-results-message">No results found</h3>
            )}
        </main>
    );
};

export default UtilAchievedTarget;
