import { useState, useCallback, useMemo, useEffect, Suspense, lazy } from "react";
import { monthOptions, yearOptions } from "../../../utils/helper";

import {
  viewSampleGiftData,
  viewUsersForUtilities,
} from "../../../services/utilities";
import Select from "react-select";
import {
  generateSelectUser,
  serialiseSSG,
} from "../../../utils/serialiseResponse";
import Table from "../../../components/Table";
import { selectUserStyles } from "../../public/stylesheets/selectStyles";
import { MdOutlineGroup } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import moment from "moment-timezone";
const DatePickers = lazy(() => import("../../../components/DatePicker"))

const dataTypeOptions = [
  { value: 0, label: "Doctor" },
  { value: 1, label: "Chemist" },
  { value: 2, label: "Stockist" },
];

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    border: "2px solid #36ABF9",
    borderRadius: "5px",
    color: "#9e9ad0",
    fontSize: "1.5rem",
    fontWeight: "500",

    width: "20rem",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",
    fontFamily: "inherit",
    fontSize: "1.5rem",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
};

const columnData = [
  {
    title: "Gift",
    field: "",
    render: (rowData) => rowData?.gift?.focusGift?.gift,
  },
  {
    title: "Distributed To",
    field: "",
    render: (rowData) => rowData?.docID?.name,
  },
  {
    title: "Quantity",
    field: "",
    render: (rowData) => rowData?.gift?.giftQuantity,
  },
  {
    title: "Amount",
    field: "",
    render: (rowData) => rowData?.gift?.giftAmount,
  },
];

const Gifts = () => {
  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [reports, setReports] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const loggedIn = useSelector(({ user }) => user);

  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Gift",
        accessor: "gift.gift",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Alotted Quantity",
        accessor: "issued",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Distributed Quantity",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.issued - props?.row?.original?.balance
                ? props?.row?.original?.issued - props?.row?.original?.balance
                : 0}
            </span>
          );
        },
      },
      {
        Header: "Available Quantity",
        accessor: "balance",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "View",
        accessor: "4",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
        Cell: (props) => {
          return (
            <Link
              to={`/gift-management/${props?.row?.original?.gift?._id}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    []
  );
  const columns2 = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "user.firstName",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Alotted Quantity",
        accessor: "totalIssued",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Distributed Quantity",
        accessor: "totalDistributed",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Available Quantity",
        accessor: "totalBalance",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "View",
        accessor: "4",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
        Cell: (props) => {
          return (
            <div
              onClick={(e) => { createSelectedUserObject(props.row.original.user) 
              }}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </div>
          );
        },
      },
    ],
    [month, year]
  );

  const createSelectedUserObject = async (userData) => {
    if (!userData) {
      return;
    }
    const selectedUserObject = {
      label: userData.firstName,
      value: userData._id,
      designation: userData.des,
    };
    setSelectedUser(selectedUserObject);
    const data = {
      month: month,
      year: Number(year),
      selectedUser: userData._id,
    };
    const res = await viewSampleGiftData("gift", data);
    setReports([...res.data]);
  };

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees([...res.data]);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchGiftsData = useCallback(
    async () => {
      const data = {
        month: month,
        year: Number(year),
        selectedUser: selectedUser?.value,
      };
      const res = await viewSampleGiftData("gift", data);
      setReports([...res.data]);
    }, [month, year, selectedUser]
  )

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (selectedUser || (loggedIn?.user?.des === 121)) {
        fetchGiftsData()
        // !selectedUser && createSelectedUserObject(loggedIn?.user?._id)
      } else {
        const data = {
          month: month,
          year: Number(year),
        };

        const res = await viewSampleGiftData("allGifts", data);
        setReports([...res.data]);
      }
    },
    [month, year,selectedUser]
  );
  const tableData = useMemo(() => serialiseSSG(reports), [reports]);
  return (
    <main className="main-content">
      <div className="expense">
        <div className="row">
          <h3 className="web-app__heading">Gifts Distributed</h3>

          <form onSubmit={handleSubmit}>
            <div className="util-tp-query d-flex justify-content-between">
              <div className="d-flex">
                <div className="util-tp-filter me-5">
                  <p className="mb-3">
                    Select Month <span className="asterisk-imp">*</span>
                  </p>
                  <Suspense>
                    <DatePickers
                      placeholder="Select Month"
                      setMonth={setMonth}
                      setYear={setYear}
                    />
                  </Suspense>
                </div>
              </div>

              <div className="d-flex align-items-center">
                {loggedIn?.user?.des < 121 ||
                  loggedIn?.user?.designation?.priority > 1 ||
                  loggedIn?.user?.isAdmin ? (
                  <div className="util-tp-filter me-5">
                    <p className="util-select-user__label">Select User</p>
                    <div className="util-select-user">
                      <MdOutlineGroup className="util-select-user__icon" />
                      <Select
                        styles={selectUserStyles}
                        options={employeeSelect}
                        placeholder="Select User"
                        className="mt-3"
                        value={selectedUser}
                        onChange={(e) => {
                          setSelectedUser({ ...e })
                          setReports([])
                        }}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="util-tp-filter mt-4">
                  <button type="submit" className="button-blue-gradient mt-3">
                    See Reports
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="filter-table">
          {reports.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({reports.length}) Entries
            </h2>
          )}
          <Table columns={ selectedUser || loggedIn?.user?.des === 121 ? columns : columns2} data={tableData} />
        </div>
      </div>
    </main>
  );
};

export default Gifts;
