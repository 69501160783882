
import { useState, useEffect, useMemo, useCallback, Suspense, lazy } from "react";
import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import serialise, {
  //   generateSelectData,
  generateSelectUser,
} from "../../../../utils/serialiseResponse.js";
import { viewMonthlyPunchingReports, viewUsersForUtilities } from "../../../../services/utilities.js";
import {
  formatDate,
  formatTime,
  items,
  monthOptions,
  yearOptions,
} from "../../../../utils/helper.js";
import Select from "react-select";
import {
  adminStyles,
  dcrStyles,
  selectUserStyles,
} from "../../../public/stylesheets/selectStyles.js";
import { useSelector } from "react-redux";
import Table from "../../../../components/Table/index.jsx";
import { FaEye } from "react-icons/fa";
import moment from "moment-timezone";
import customToast from "../../../../components/CustomToast/index.js";
import { FaMapLocation } from "react-icons/fa6";
import { RiCloseCircleLine } from "react-icons/ri";
import WorkInMap from "../../../../components/MapWorkIn/index.jsx";
import { AiOutlineStop } from "react-icons/ai";
const CascadingMenuDropDown = lazy(() => import('../../CascadingMenuDropDown'));
const DatePickers = lazy(() => import("../../../../components/DatePicker"))

const UtilitiesMonthlyPunchingReport = () => {

  const showArr = [
    { value: "checkInCheckoutReport", label: "CheckIn/Checkout Report"},
    { value: "monthlyReport", label: "Monthly Report" },
    { value: "dailyReport", label: "Daily Report" },
  ];

  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  let [punchingReports, setPunchingReports] = useState([]);
  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  // const [detailedReport, setDetailedReport] = useState(false)
  const [date, setDate] = useState()
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const [reportType, setReportType] = useState(showArr[0]);
  const [showMap, setShowMap] = useState(false)
  const [mapData, setMapData] = useState({})

  const columns = useMemo(
    () => [

      {
        Header: "Date",
        accessor: "date",
        disableSortBy: true,
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
      {
        Header: "Check-In",
        accessor: "checkInCount",
        disableFilters: true,
        disableSortBy: true,
        totalAccessor: "doctors"
      },
      {
        Header: "Check-Out",
        accessor: "checkOutCount",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "View",
        accessor: "",
        Cell: (props) => {
          props.row.original.selectedUser = selectedUser && selectedUser
          return (
            <Link
              to="details"
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
        minWidth: 150,
        maxWidth: 150,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [selectedUser, loggedIn]
  );

  const monthColumns = useMemo(
    () => [

      {
        Header: "Date",
        accessor: "date",
        disableSortBy: true,
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
      {
        Header: "Check-In",
        accessor: "checkInTime",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          const checkInTimes = row.original?.checkInTime;
        
          return (
            <div>
              {checkInTimes && checkInTimes.length > 0 ? (
                checkInTimes.map((item, index) => (
                  <span key={index}>
                    {item !== "N/A" ? formatTime(item).toUpperCase() : item}
                    {index !== checkInTimes.length - 1 ? (
                      <>
                        {/* , */}
                        <br />
                      </>
                    )
                    : ""}
                  </span>
                ))
              ) : (
                <span>N/A</span>
              )}
            </div>
          );
        }
      },
      {
        Header: "Check-Out",
        accessor: "checkOutTime",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          const checkOutTimes = row.original?.checkOutTime;
        
          return (
            <div>
              {checkOutTimes && checkOutTimes.length > 0 ? (
                checkOutTimes.map((item, index) => (
                  <span key={index}>
                    {item !== "N/A" ? formatTime(item).toUpperCase() : item}
                    {index !== checkOutTimes.length - 1 ? (
                      <>
                        {/* , */}
                        <br />
                      </>
                    ) 
                    : ""}
                  </span>
                ))
              ) : (
                <span>N/A</span>
              )}
            </div>
          );
        }
      },
    ],
    [selectedUser, loggedIn]
  );

  // const dateColumns = useMemo(
  //   () => [

  //     {
  //       Header: "Employee Name",
  //       accessor: "user.name",
  //       disableSortBy: true,
  //     },
  //     {
  //       Header: "Check-In",
  //       accessor: "checkInCount",
  //       disableFilters: true,
  //       disableSortBy: true,
  //       totalAccessor: "doctors"
  //     },
  //     {
  //       Header: "Check-Out",
  //       accessor: "checkOutCount",
  //       disableFilters: true,
  //       disableSortBy: true,
  //     },
  //   ],
  //   [selectedUser, loggedIn]
  // );

  const dateColumns = useMemo(
    () => [
      {
        Header: "Employee Name",
        accessor: "user.name",
        disableSortBy: true,
      },
      {
        Header: "Check-In",
        accessor: "checkInTime",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          const checkInTimes = row.original?.checkInTime;
        
          return (
            <div>
              {checkInTimes && checkInTimes.length > 0 ? (
                checkInTimes.map((item, index) => (
                  <span key={index}>
                    {item !== "N/A" ? formatTime(item).toUpperCase() : item}
                    {index !== checkInTimes.length - 1 ? (
                      <>
                        {/* , */}
                        <br />
                      </>
                    )
                    : ""}
                  </span>
                ))
              ) : (
                <span>N/A</span>
              )}
            </div>
          );
        }
      },
      {
        Header: "Check-Out",
        accessor: "checkOutTime",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          const checkOutTimes = row.original?.checkOutTime;
        
          return (
            <div>
              {checkOutTimes && checkOutTimes.length > 0 ? (
                checkOutTimes.map((item, index) => (
                  <span key={index}>
                    {item !== "N/A" ? formatTime(item).toUpperCase() : item}
                    {index !== checkOutTimes.length - 1 ?(
                      <>
                        {/* , */}
                        <br />
                      </>
                    )
                    : ""}
                  </span>
                ))
              ) : (
                <span>N/A</span>
              )}
            </div>
          );
        }
      },
      {
        Header: "Remark",
        accessor: "remark",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          const remarks = row.original?.WorkingInCoordinate;
        
          return (
            <div>
              {remarks && remarks.length > 0 ? (
                remarks.map((item, index) => (
                  <span key={index}>
                    {item?.remark}
                    {(index !== remarks.length - 1) ?(
                      <>
                        ,
                        <br />
                      </>
                    )
                    : ""}
                  </span>
                ))
              ) : (
                <span>N/A</span>
              )}
            </div>
          );
        }
      },
      {
        Header: "Location",
        accessor: "location",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          
          const WorkingInCoordinates = row.original?.WorkingInCoordinate;
          const WorkingOutCoordinates = row.original?.WorkingOutCoordinate;
        
          return (
            <div>
              {WorkingInCoordinates && WorkingInCoordinates.length > 0 ? (
                WorkingInCoordinates?.map((item, index) => {
                  const WorkingInCoordinate = item
                  const WorkingOutCoordinate = WorkingOutCoordinates[index]
                  const remark = item?.remark
                  return <span key={index}>
                    <FaMapLocation 
                      className="icon-color-tertiary cursor-pointer"
                      onClick={
                        () => {
                          setShowMap(true)
                          setMapData({ WorkingInCoordinate, WorkingOutCoordinate, remark })
                        }
                      }
                    />
                    {index !== WorkingInCoordinates.length - 1 ?(
                      <>
                        <br />
                      </>
                    )
                    : ""}
                  </span>
                })
              ) : (
                <AiOutlineStop className="icon-opacity" />
              )}
            </div>
          );
        }
      }
    ],
    [selectedUser, loggedIn]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      // if (!year) {
      //   customToast.error("Please select month and year");
      //   return;
      // }

      // if (!selectedUser && loggedIn?.user?.des < 121) {
      //   customToast.error("Please select user");
      //   return;
      // }

      const filters = {
        selectedUser: selectedUser?.value,
        year: Number(year),
        month: Number(month),
        date: date,
        type: reportType?.value 
      };
      const punchingReportsResponse = await viewMonthlyPunchingReports(filters)

      setPunchingReports(punchingReportsResponse.data);

    },
    [reportType, date, month, year, selectedUser]
  );

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees([...res.data]);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  const tableData = useMemo(() => serialise(punchingReports), [punchingReports]);

  const Checkbox = ({ label, value, onChange }) => (
    <div className="d-flex align-items-center">
      <div
        className="form__control px-5"
        style={{ width: "fit-content", color: "#c7c4e9" }}
      >
        <span className="">{label}</span>
        <label className="toggle-label mx-3">
          <input
            type="checkbox"
            name="reportType"
            checked={value}
            onChange={onChange}
          />
          <span />
        </label>

      </div>
    </div>
  );

  return (
    <div className="expense">
      <div className="row">
        <div className="d-flex justify-content-between">
          <h2
            className="web-app__heading"
            style={{ display: "flex", alignItems: "center" }}
          >
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              CheckIn / CheckOut Reports
            </span>
          </h2>
          {/* {
            <div className="d-flex w-70 justify-content-between">
              <div className="border-end border-3 border-success">
                <Checkbox
                  label="Detailed Report"
                  value={detailedReport}
                  onChange={() => setDetailedReport(!detailedReport)}
                />
              </div>
            </div>
          } */}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="util-tp-query d-flex ">

          <div className="util-tp-filter">
                  <p className="mb-3">Select Report Type</p>
                  <Select
                    options={showArr}
                    // className="mt-3"
                    styles={dcrStyles}
                    value={reportType}
                    onChange={(e) => {
                      setReportType(e)
                      setPunchingReports([])
                    }}
                  />
                </div>

            {/* <div className="d-flex"> */}

              {
                (reportType?.value === "checkInCheckoutReport" || reportType?.value === "monthlyReport") && (
                  <>
                   {loggedIn?.user?.des < 121 ||
                      loggedIn?.user?.designation?.priority > 1 ||
                      loggedIn?.user?.isAdmin ? (
                      <div className="util-tp-filter">
                        <p className="mb-3">Select User</p>
                       
                          <Select
                            styles={dcrStyles}
                            options={employeeSelect}
                            placeholder="Select User"
                            value={selectedUser}
                            onChange={(e) => setSelectedUser({ ...e })}
                          />
                      </div>
                    ) : null}
                    <div className="util-tp-filter">
                      <p className="mb-3">Select Month</p>
                      <Suspense>
                        <DatePickers
                          placeholder="Select Month"
                          setMonth={setMonth}
                          setYear={setYear}
                        />
                      </Suspense>
                    </div>
                   
                  </>
                )
              }

              {
                reportType?.value === "dailyReport" && (
                  <div className="admin-content">
                    <div className="util-tp-filter">
                      <p className="mb-3">Select Date </p>
                      <input
                        type="date"
                        className="date-input"
                        id="date-input"
                        placeholder="Select Date"
                        // style={{border: "2px solid", borderRadius: "5px", borderColor: "var(--color-success)"}}
                        value={date}
                        onChange={({ target }) => setDate(target.value)}
                      />
                    </div>
                  </div>
                )
              }

              <div className="util-tp-filter">
                <button type="submit" className="button-blue-gradient mt-5">
                  Submit
                </button>
              </div>
            {/* </div> */}
          </div>
        </form>

        <div className="filter-table">
          {punchingReports?.length > 0 && (
            <h2 className="web-app__heading mb-4">
              Showing ({punchingReports?.length}) Entries
            </h2>
          )}
          {tableData?.length > 0 ? (
            <Table defaultPageSize={50}  
            columns={reportType?.value === "monthlyReport" ? monthColumns : (reportType?.value === "dailyReport" ? dateColumns : columns )} 
            data={tableData}
            selectedUser={selectedUser ? selectedUser : loggedIn?.user}
            fileSrc="CheckIn/CheckOut Report" 
            />
          ) : (
            <h3 className="mt-2 no-results-message">No results found</h3>
          )}
        </div>
        {
          showMap ? (
            <section className="edit-popup">
              <div className="edit-content">
                <div className="edit-popup__heading">
                  <h2 className="web-app__heading mt-4">Remark : {mapData?.remark}</h2>
                  <RiCloseCircleLine
                    className="edit-popup__close"
                    onClick={() => {
                      setShowMap(false)
                    }}
                  />
                </div>

                <div className="mt-4">
                  <WorkInMap latitude={mapData?.WorkingInCoordinate?.latitude} longitude={mapData?.WorkingInCoordinate?.longitude} latitude2={mapData?.WorkingOutCoordinate?.latitude} longitude2={mapData?.WorkingOutCoordinate?.longitude} />
                </div>
              </div>
            </section>
          ) : null
        }
      </div>
    </div>
  );
};

export default UtilitiesMonthlyPunchingReport;
