import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import customToast from "../../../components/CustomToast";
import {
  clearProductRanges,
  setProductRanges,
} from "../../../reducers/products/rangeReducer";
import {
  clearStockists,
  setStockists,
} from "../../../reducers/targets/stockist";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../reducers/locations/hqReducer";
import serialise, { generateSelectData } from "../../../utils/serialiseResponse";
import Table from "../../../components/Table";
import { adminStyles } from "../../public/stylesheets/selectStyles";
import { monthOptions, yearOptions } from "../../../utils/helper";
import { RiEdit2Fill } from "react-icons/ri";
import { MdDelete, MdCancel, MdArrowBackIosNew } from "react-icons/md";
import { AiFillSave, AiFillFolderOpen } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";
import { addSecondarySales, getSecondarySalesPrevMonth, getSecondarySalesLatestMonth } from "../../../services/sales";
import { fileToBase64 } from "../../../utils/helper";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from "moment-timezone";
import { FaCircleExclamation } from "react-icons/fa6";
import { showSidenav, showTrimNav } from "../../../reducers/appReducer";
import { clearDivisions, setDivisions } from "../../../reducers/users/divisionReducer";

const SedondarySales = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null); // for upload file input
  const stockistData = useSelector(({ stockist }) => stockist);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const productRange = useSelector(({ productRange }) => productRange);
  const secondary = useSelector((state) => state.settings["secondary"]);
  const loggedIn = useSelector(({ user }) => user);
  const divisionData = useSelector(({ division }) => division);

  const [division, setDivision] = useState(null);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [stk, setStk] = useState("");
  const [headquarter, setHeadquarter] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [finalArray, setFinalArray] = useState([]);//this is the array that will get submitted
  const [editRow, setEditRow] = useState("");
  const [editing, setEditing] = useState(false);
  const [isReturnSale, setIsReturnSale] = useState(false);
  const [uploadData, setUploadData] = useState("");//this will be used to upload the image file

  const [loading, setLoading] = useState(false);

  const currentMonth = moment
    .tz(new Date(), "Asia/Kolkata")
    .month();
  const filtereMonthOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    if (year.label == currentYear && secondary !== 'true') {

      return monthOptions.filter((el, idx) => el.value < currentMonth);
    }
    else return monthOptions;
  }, [currentMonth, secondary, year.label]);



  //These are the variables that do not require re-rendering
  const upOpeningBalanceQty = useRef(0);
  const upCustomPriceRef = useRef(0);
  const upFreeStocksRef = useRef(0);
  const upReceivedQuantity = useRef(0);
  const upSalesQuantity = useRef(0);
  const upPriceTypeRef = useRef("pts");

  const stockistselectData = useMemo(
    () => {
      return stockistData?.data?.filter((e) => headquarter?._id === e?.city?._id);
    },
    [headquarter]
  )

  const targetSelectProductData = useMemo(() => {
    if (loggedIn?.user?.des === 101) {
      return productRange?.data?.filter((e) => division?.value === e?.division?._id);
    }
    return productRange?.data;
  }, [productRange, division, loggedIn]);

  //   below are select options 
  const targetSelectProduct = useMemo(
    () => generateSelectData(targetSelectProductData, "name"),
    [targetSelectProductData]
  );
  const stockistSelect = useMemo(
    () => generateSelectData(stockistselectData.filter(item => item.isApproved === 1), "businessName"),
    [stockistselectData]
  );
  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );
  const divisionSelect = useMemo(
    () => generateSelectData(divisionData, "name"),
    [divisionData]
  );

  useEffect(() => {
    dispatch(showTrimNav());

    return () => dispatch(showSidenav());
  }, [dispatch]);

  const changeHandler = (e) => {
    const validFiles = Array.from(e.target.files);
    setUploadData([]);
    Promise.all(validFiles.map(fileToBase64))
      .then((base64Images) => {
        setUploadData(base64Images);
      })
      .catch((error) => {
        customToast.error('Error adding images');
      });
  };


  const resetForm = () => {
    setHeadquarter("");
    setStk("");
    setFinalArray([]);
    setEditRow("");
    setEditing(false);
    setUploadData("");
    setMonth("");
    setYear("");
  }

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        let preMonth = Number(month?.value);
        let preYear = Number(year?.label);

        const { data: latestData } = await getSecondarySalesLatestMonth({ month: preMonth, year: preYear, headquarter: headquarter._id, stockist: stk._id });
        if (
          latestData &&
          preYear === latestData?.year &&
          preMonth < latestData?.month
        ) {
          setLoading(false);
          setMonth("");
          return customToast.error("It is not allowed to add sales for previous months after adding sales for current month.")
        }
        const { data } = await getSecondarySalesPrevMonth({ month: preMonth, year: preYear, headquarter: headquarter._id, stockist: stk._id });
        // console.log(data);
        const prods = serialise(data);
        setFinalArray(prods);
        setLoading(false);
      } catch (err) {

        if (err?.response?.data?.data) customToast.error(err?.response?.data?.data);
        else customToast.error("Sorry Something Went Wrong");
        setLoading(false);
        // if(secondary === 'true')customToast.error("Admins must approve the pending sales for adding new sales")
        // else customToast.error("Secondary Sales are already submitted for the selected month. To make any changes please consider editing the existing sale.")
        setMonth("");
      }
    }
    if(month && year && headquarter && stk)fetch();
  },[month,year,headquarter,stk, secondary]);

  useEffect(() => {
    dispatch(setStockists());
    dispatch(setProductRanges());
    dispatch(setHeadquarters());
    dispatch(setDivisions());
    return () => {
      dispatch(clearStockists());
      dispatch(clearProductRanges());
      dispatch(clearHeadquarters());
      dispatch(clearDivisions());
    };
  }, [dispatch]);

  const deleteHandler = (product) => {
    setEditRow("");
    setEditing(false);
    let sr = 0;
    const newfinalArray = finalArray.filter((ele, idx) => {
      if (ele?.product?._id !== product?._id) {
        sr += 1;
        ele.sr = sr;
        return true;
      } else return false;
    })
    setFinalArray(newfinalArray);
  }

  const handleEditRow = (original) => {
    if (editing) {
      customToast.error("Please save the changes you just made");
      return;
    }
    setEditing(true);
    setEditRow(original?.sr);

    upOpeningBalanceQty.current = original?.openingBalanceQty;
    upCustomPriceRef.current = original?.price;
    upFreeStocksRef.current = original?.freeStocks;
    upReceivedQuantity.current = original?.receivedQuantity
    upSalesQuantity.current = original?.salesQuantity;
    upPriceTypeRef.current = original?.priceType;
  }

  const submitEditedRow = (sr) => {

    const editedObj = finalArray[sr - 1];

    editedObj.openingBalanceQty = Number(upOpeningBalanceQty.current);
    editedObj.receivedQuantity = Number(upReceivedQuantity.current);
    editedObj.salesQuantity = Number(upSalesQuantity.current);
    editedObj.freeStocks = Number(upFreeStocksRef.current);
    editedObj.price = Number(upCustomPriceRef.current);
    editedObj.priceType = upPriceTypeRef.current;
    editedObj.totalQuantity = Number(upOpeningBalanceQty.current) + Number(upReceivedQuantity.current);
    editedObj.totalValue = Number(editedObj.totalQuantity) * Number(editedObj.price);
    editedObj.salesValue = Number(editedObj.salesQuantity) * Number(editedObj.price);
    editedObj.closingQuantity = Number(editedObj.totalQuantity) - Number(editedObj.salesQuantity) - Number(editedObj.freeStocks);
    editedObj.closingValue = Number(editedObj.closingQuantity) * Number(editedObj.price);
    setEditing(false);
    setEditRow("");

  }
  const cancelHandler = (sr) => {
    setEditing(false);
    setEditRow("");
  }

  const secondarySalesSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (editing) {
        customToast.error("Please save the changes you just made");
        return;
      }
      if (!stk || !month || !year || !headquarter) {
        customToast.error("Please select all the fields marked with *");
        return;
      }
      if (finalArray.length === 0) {
        customToast.error("Please add atleast one product");
        return;
      }
      const payload = {
        month: month.value,
        year: year.label,
        headquarter: headquarter?._id,
        stockist: stk?._id,
        table: finalArray,
        date: new Date(),
        images: uploadData,
        divisionId: division ? division.value : loggedIn?.user?.division,
        isReturnSale
      };
      try {
        const { data } = await addSecondarySales(payload);
        customToast.success("Secondary sales added successfully");
      } catch (err) {
        const { data } = err.response;
        customToast.error(data.message);
      }
      resetForm();
    },
    [month, year, headquarter, stk, division, finalArray, editing, isReturnSale]
  );

  const handleProductAdd = () => {
    if (!stk || !month || !year || !headquarter) {
      return customToast.error("Please select all the mandatory fields");
    }
    if (loading) {
      return customToast.info("Please wait a second fetching previous months sales for you");
    }
    if (!selectedProduct) {
      customToast.error("Please select a product");
      return;
    }
    if (editing) {
      customToast.error("Please save the changes before adding new product");
      setSelectedProduct("");
      return;
    }
    const temp = finalArray.filter((ele) => ele.product?._id === selectedProduct?._id);
    if (temp.length > 0) {
      customToast.error(`Product already present in row "${temp[0].sr}"`);
      setSelectedProduct("");
      return;
    };
    const nextsr = finalArray.length + 1;
    const newObj = {
      sr: nextsr,
      product: selectedProduct,
      openingBalanceQty: 0,
      receivedQuantity: 0,
      salesQuantity: 0,
      price: selectedProduct?.pts || 0,
      priceType: "pts",
      totalQuantity: 0,
      totalValue: 0,
      salesValue: 0,
      closingQuantity: 0,
      closingValue: 0,
      freeStocks: 0
    }
    setSelectedProduct("");
    setFinalArray([...finalArray, newObj]);
  };
  const priceAccToHeader = (original, selection) => {

    upPriceTypeRef.current = selection;
    const priceAccToHead = original?.product[selection] || 0;
    upCustomPriceRef.current = priceAccToHead;
    return priceAccToHead;
  }
  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "product.name",
        disableSortBy: false,
        disableFilters: false,
        minWidth: 150,
        maxWidth: 150,
        wordWrap: "break-word",
        showTotal: true,
        placeholderTotal: "Total"
      },
      {
        Header: "Pack",
        accessor: "product.packaging",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
        wordWrap: "break-word",
      },
      {
        Header: "Price",
        accessor: "product.pts",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 150,
        maxWidth: 150,
        showTotal: true,
        totalAccessor: "price",
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          const [pr, setPr] = useState(props?.row?.original?.price);
          // useEffect(()=> upCustomPriceRef.current = pr, [pr]);
          return (editable) ? (
            <div className="d-flex gap-2 align-items-center justify-center">
              <div className="d-flex gap-1 justify-center flex-column">
                <button type='button'
                  onClick={e => setPr(priceAccToHeader(props?.row?.original, "ptr"))}
                  className={`primary-sales-button ${upPriceTypeRef.current === 'ptr' && "primary-sales-button-bg"}`}
                >PTR</button>
                <button type='button'
                  onClick={e => setPr(priceAccToHeader(props?.row?.original, "pts"))}
                  className={`primary-sales-button ${upPriceTypeRef.current === 'pts' && "primary-sales-button-bg"}`}
                >PTS</button>
                <button type='button'
                  onClick={e => setPr(priceAccToHeader(props?.row?.original, "mrp"))}
                  className={`primary-sales-button ${upPriceTypeRef.current === 'mrp' && "primary-sales-button-bg"}`}
                >MRP</button>
                <button type='button'
                  onClick={e => setPr(priceAccToHeader(props?.row?.original, "cus"))}
                  className={`primary-sales-button ${upPriceTypeRef.current === 'cus' && "primary-sales-button-bg"}`}
                >Cus..</button>
              </div>
              <input
                defaultValue={props?.row?.original?.price}
                onChange={(e) => {
                  upCustomPriceRef.current = e.target.value;
                  upPriceTypeRef.current = 'cus'
                  setPr(e.target.value)
                }}
                value={pr}
                type="number"
                className="sales-table__input h-25"
              />
            </div>
          ) : (
            <span>{props?.row?.original?.price}</span>
          );
        },
      },
      {
        Header: (
          <OverlayTrigger
            className='cursor-pointer'
            key="top"
            placement="top"
            overlay={
              <Tooltip id={`tooltip-$top`} style={{ fontSize: "1.3rem" }}>
                Previous months Closing Quantity is this month's Opening Balance
              </Tooltip>
            }
          >
            <span>Opening Balance Qty <FaCircleExclamation className="tp__activity-types-icon-5" /></span>

          </OverlayTrigger>
        ),
        accessor: "openingBalanceQty",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        showTotal: true,
        totalAccessor: 'openingBalanceQty',
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.openingBalanceQty}
              onChange={(e) => {
                upOpeningBalanceQty.current = e.target.value;
              }}
              type="number" className="sales-table__input"
            />
          ) :
            (
              <span>{props?.row?.original?.openingBalanceQty}</span>
            )
        },
      },
      {
        Header: (
          <OverlayTrigger
            className='cursor-pointer'
            key="top"
            placement="top"
            overlay={
              <Tooltip id={`tooltip-$top`} style={{ fontSize: "1.3rem" }}>
                Quantity of Selected Month's Primary Sales is this Months Received Quantity
              </Tooltip>
            }
          >
            <span>Received Qty <FaCircleExclamation className="tp__activity-types-icon-5" /></span>

          </OverlayTrigger>
        ),
        accessor: "receivedQuantity",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        showTotal: true,
        totalAccessor: 'receivedQuantity',
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.receivedQuantity}
              onChange={(e) => {
                upReceivedQuantity.current = e.target.value;
              }}
              type="number" className="sales-table__input"
            />
          ) :
            (
              <span>{props?.row?.original?.receivedQuantity}</span>
            )
        },
      },
      {
        Header: "Total Quantity",
        accessor: "totalQuantity",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
        showTotal: true,
        totalAccessor: 'totalQuantity',
      },
      {
        Header: "Total Value",
        accessor: "totalValue",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
        showTotal: true,
        totalAccessor: 'totalValue',
        Cell: (props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.totalValue).toFixed(2)}`}
          </div>
        }
      },
      {
        Header: "Sales Qty",
        accessor: "salesQuantity",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        showTotal: true,
        totalAccessor: "salesQuantity",
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.salesQuantity}
              onChange={(e) => {
                upSalesQuantity.current = e.target.value;
              }}
              type="number" className="sales-table__input"
            />
          ) :
            (
              <span>{props?.row?.original?.salesQuantity}</span>
            )
        },
      },
      {
        Header: "Free Stocks",
        accessor: "freeStocks",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        showTotal: true,
        totalAccessor: "freeStocks",
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.freeStocks}
              onChange={(e) => {
                upFreeStocksRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.freeStocks}</span>
          );
        },
      },

      {
        Header: "Sales Value",
        accessor: "salesValue",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
        showTotal: true,
        totalAccessor: "salesValue",
        Cell: (props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
      {
        Header: "Closing Quantity",
        accessor: "closingQuantity",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
        showTotal: true,
        totalAccessor: "closingQuantity",
      },
      {
        Header: "Closing Value",
        accessor: "closingValue",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
        showTotal: true,
        totalAccessor: "closingValue",
        Cell: (props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.closingValue).toFixed(2)}`}
          </div>
        }
      },
      {
        Header: "Actions",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
        Cell: (props) => {
          return editing && Number(props?.row?.original?.sr) === editRow ? (
            <div className="d-flex gap-1 justify-content-center" >
              <AiFillSave
                onClick={(e) => {
                  submitEditedRow(props?.row?.original?.sr);
                }}
                type="button"
                className="icon-color-green"
              />
              <MdDelete
                onClick={() => {
                  deleteHandler(props?.row?.original?.product);
                }}
                type="button"
                className="button-delete__icon"
              />
              <MdCancel
                type="button"
                className="icon-color-yellow"
                onClick={() => {
                  cancelHandler(props?.row?.original?.sr);
                }}
              />
            </div>
          ) :
            (
              <span
                className="react-table-view-link"
                onClick={() => {
                  handleEditRow(props?.row?.original);
                }}
              >
                <RiEdit2Fill className="icon-color-green" />
              </span>
            )
        }
      },
    ],
    [finalArray, editing]
  );

  return (
    <main className="main-content admin-content">
      <div className="expense">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="web-app__heading cursor-pointer" onClick={() => navigate(-1)}><MdArrowBackIosNew /> Secondary Sales</h3>
          <div className="justify-content-between d-flex align-items-center gap-5">
            {/* <div className="d-flex align-items-center justify-items-center gap-2 ">
              <span className="tp__activity-types-icon-8">Return Sale</span>
              <label className="toggle-label mb-0">
                <input
                  type="checkbox"
                  checked={isReturnSale}
                  onClick={() => { setIsReturnSale((isReturn) => !isReturn) }}
                />
                <span />
              </label>
            </div> */}
            <button className="button-blue-gradient2" onClick={() => navigate("./upload-ss")}>
              Upload Secondary Sales
            </button>
          </div>
        </div>
        <form onSubmit={secondarySalesSubmit}>
          <div className="primarysales-filter mb-5">
            <div className="util-tp-filter">
              <p className="mb-2">
                Select Year <span className="asterisk-imp">*</span>
              </p>
              <Select
                styles={adminStyles}
                placeholder="Select Year"
                className="mt-3"
                options={yearOptions}
                value={year}
                onChange={(e) => setYear({ ...e })}
              />
            </div>
            <div className="util-tp-filter">
              <p className="mb-2">
                Select Month <span className="asterisk-imp">*</span>
              </p>
              <Select
                styles={adminStyles}
                placeholder="Select Month"
                className="mt-3"
                options={filtereMonthOptions}
                value={month}
                onChange={(e) => setMonth({ ...e })}
              />
            </div>
            <div className="util-tp-filter">
              <p className="mb-2">
                Select Headquarter <span className="asterisk-imp">*</span>
              </p>
              <Select
                name="headquarter"
                id="headquarter"
                value={headquarter}
                options={headquarterSelect}
                onChange={(e) =>
                  setHeadquarter({ ...e })
                }
                styles={adminStyles}
                placeholder="Select Headquarter"
              />
            </div>

            <div className="util-tp-filter">
              <p className="mb-2">
                Select Stockist <span className="asterisk-imp">*</span>
              </p>
              <Select
                styles={adminStyles}
                options={stockistSelect}
                placeholder="Select Stockist"
                value={stk}
                onChange={(e) => setStk({ ...e })}
              />
            </div>
            {
              loggedIn?.user?.des === 101 &&
              <div className="util-tp-filter">
                <p className="mb-2">
                  Select Division <span className="asterisk-imp">*</span>
                </p>
                <Select
                  name="division"
                  id="division"
                  value={division}
                  options={divisionSelect}
                  onChange={({ value, label }) => setDivision({ value, label })}
                  styles={adminStyles}
                  placeholder="Select Division"
                />
              </div>
            }
            
            
            <div className="util-tp-filter">
              <p className="mb-2">
                Select Product
              </p>
              <Select
                styles={adminStyles}
                options={targetSelectProduct}
                placeholder="Select Product"
                value={selectedProduct}
                onChange={(e) => setSelectedProduct({ ...e })}
              />
            </div>
            <div className="util-tp-filter ">
              <div className="mb-2">
                <button type="button" onClick={handleProductAdd} className="button-blue-gradient  mt-5">
                  Add Product
                </button>
              </div>
            </div>
            <div className="util-tp-filter ms-auto">
              <div className="mb-2 ">
                <button type="button"
                  className="button-submit-green mt-5 "
                  onClick={() => navigate(`./all`)}
                >
                  <span className="button-submit-green__icon-container">
                    <AiFillFolderOpen className="button-submit-green__icon" />
                  </span>
                  <span className="button-submit-green__text ">
                    All Secondary Sales
                  </span>
                </button>
              </div>
            </div>
            <div className="util-tp-filter">
              <p className="mb-2">
                Upload File
              </p>
              <input
                ref={inputRef}
                type="file"
                placeholder="you can upload image, excel or pdf"
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="filter-table" style={{ minHeight: "150px", overflow: "auto", maxHeight: "100vh" }}>
            <Table columns={columns} data={finalArray} />
          </div>
          <button
            type="submit"
            className="button-submit"
          >
            Submit Secondary Sales Data
          </button>
        </form>
      </div>
    </main>
  );
};
export default SedondarySales;