  import { useEffect, useState, useMemo } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import PageTitle from "../../../../../components/PageTitle";
  import Table from "../../../../../components/Table";
  import serialise from "../../../../../utils/serialiseResponse";
  import { yearOptions} from "../../../../../utils/helper";
  import { adminStyles, dcrStyles } from "../../../../public/stylesheets/selectStyles";
  import Select from "react-select";
  import { clearTarget, yearlyTargets } from "../../../../../reducers/reports/targetReducer";

  const YearlyTargets = () => {
    const dispatch = useDispatch();
    const [year, setYear] = useState(yearOptions[0]);
    const yearlytargets = useSelector(({targetReducer}) => targetReducer);

    useEffect(()=>{
      dispatch(yearlyTargets({year : year?.label}));

      return () =>{
        dispatch(clearTarget());
      }
    },[dispatch,year]);
    
    const tableData = useMemo(() => serialise(yearlytargets?.data),[yearlytargets]);

    const columns = useMemo(
      () => [
        {
          Header: "User",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 40,
          maxWidth: 130,
          showTotal: true,
          placeholderTotal: "Total",
          Cell : (props) => {
            return <div style={{ display: 'flex', alignItems: 'center' }}> 
              {props?.row?.original?.firstName} {props?.row?.original?.lastName} 
              </div>;
          },
        },
        {
          Header: "Apr",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 40,
          maxWidth: 90,
          wordWrap:"break-word",
          showTotal: true,
          totalAccessor : "Apr",
          Cell: (props)=>{
            if(props?.row?.original?.Apr) return props?.row?.original?.Apr;
            else return null;
          },
        },
        {
          Header: "May",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 40,
          maxWidth: 90,
          showTotal: true,
          totalAccessor : "May",
          Cell: (props)=>{
            if(props?.row?.original?.May) return props?.row?.original?.May;
            else return null;
          },
        },
        {
          Header: "Jun",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 40,
          maxWidth: 90,
          showTotal: true,
          totalAccessor : "Jun",
          Cell: (props)=>{
            if(props?.row?.original?.Jun) return props?.row?.original?.Jun;
            else return null;
          },
        },
        {
          Header: "Jul",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 40,
          maxWidth: 90,
          showTotal: true,
          totalAccessor : "Jul",
          Cell: (props)=>{
            if(props?.row?.original?.Jul) return props?.row?.original?.Jul;
            else return null;
          },
        },
        {
          Header: "Aug",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 40,
          maxWidth: 90,
          showTotal: true,
          totalAccessor : "Aug",
          Cell: (props)=>{
            if(props?.row?.original?.Aug) return props?.row?.original?.Aug;
            else return null;
          },
        },
        {
          Header: "Sep",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 40,
          maxWidth: 90,
          showTotal: true,
          totalAccessor : "Sept",
          Cell: (props)=>{
            if(props?.row?.original?.Sept) return props?.row?.original?.Sept;
            else return null;
          },
        },
        {
          Header: "Oct",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 40,
          maxWidth: 90,
          showTotal: true,
          totalAccessor : "Oct",
          Cell: (props)=>{
            if(props?.row?.original?.Oct) return props?.row?.original?.Oct;
            else return null;
          },
        },
        {
          Header: "Nov",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 40,
          maxWidth: 90,
          showTotal: true,
          totalAccessor : "Nov",
          Cell: (props)=>{
            if(props?.row?.original?.Nov) return props?.row?.original?.Nov;
            else return null;
          },
        },
        {
          Header: "Dec",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 40,
          maxWidth: 90,
          showTotal: true,
          totalAccessor : "Dec",
          Cell: (props)=>{
            if(props?.row?.original?.Dec) return props?.row?.original?.Dec;
            else return null;
          },
        },
        {
          Header: "Jan",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 40,
          maxWidth: 90,
          showTotal: true,
          totalAccessor: "Jan",
          Cell: (props)=>{
            if(props?.row?.original?.Jan) return props?.row?.original?.Jan;
              else return null;
            }
        },
        {
          Header: "Feb",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 40,
          maxWidth: 90,
          showTotal: true,
          totalAccessor: "Feb",
          Cell: (props)=>{
            if(props?.row?.original?.Feb) return props?.row?.original?.Feb;
            else return null;
          },
        },
        {
          Header: "Mar",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 40,
          maxWidth: 90,
          showTotal: true,
          totalAccessor : "Mar",
          Cell: (props)=>{
            if(props?.row?.original?.Mar) return props?.row?.original?.Mar;
            else return null;
          },
        },
        {
          Header:"Total",
          accessor:"total",
          minWidth: 40,
          maxWidth: 100,
          disableFilters: true,
          showTotal: true,
          totalAccessor : "total",
          wordWrap:"break-word",
        }
      ],
      []
    );
    
    
    return (
      <div className="main-content admin-content">
        <div className="area-creation-content">
          <PageTitle title="Yearly Targets" />

          <section className="area-creation-content__info">
              <div className="primarysales-filter"
              style={{marginTop:"0", padding:"0", marginBottom:"2rem"}}>
                  <div className="util-tp-filter">
                <p className="mb-2">
                  Select Year <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Month"
                  className="mt-3"
                  options={yearOptions}
                  value={year}
                  onChange={(e) => setYear({ ...e })}
                />
                  </div>
              </div>
          <h2 className="web-app__heading mb-4 ms-2">
          Showing ({tableData  ? tableData.length : 0}) Entries
              </h2>
            <div className="filter-table">
              <Table columns={columns} data={tableData ? tableData : []} />
            </div>
          </section>
        </div>
      </div>
    );
  };

  export default YearlyTargets;
