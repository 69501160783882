import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/dcs/`;
const viewAllURL = `${apiURL}/approval/dcs/all/`;
const approvalURL = `${apiURL}/client/approval/dcs/`;
const approveURL = `${apiURL}/client/approval/dcs/approve`;
const rejectURL = `${apiURL}/client/approval/dcs/reject`;
const addDWPL = `${apiURL}/client/dcs/DWPL`;
const addDWPLV1 = `${apiURL}/client/v1/dcs/DWPL`;
const crm = `${apiURL}/client/v1/crm`
const baseURLV1 = `${apiURL}/client/v1/dcs/`;
export const viewTargetsForApproval = async (type, filters) => {
  const { data } = await axios.get(approvalURL + type, {
    params: filters,
    headers: setAuth(),
  });

  return data;
};

export const viewTargets = async (type, filters) => {
  const { data } = await axios.get(`${baseURL}${type}/individual-list`, {
    params: filters,
    headers: setAuth(),
  });
  return data;
};

export const viewTargetsV1 = async (type, filters) => {
  const { data } = await axios.get(`${baseURLV1}${type}/individual-list`, {
    params: filters,
    headers: setAuth(),
  });
  return data;
};
export const viewDeltedTargets = async (type) => {
  const { data } = await axios.get(`${baseURLV1}${type}/view-deleted-${type}` , {
    // params: filters,
    headers: setAuth(),
  });
  return data;
};

export const restoreDeltedTargets = async (type, id) => {
  const { data } = await axios.put(`${baseURL}${type}/update-deleted-${type}/${id}`, {id: id} , {
    headers: setAuth(),
  });
  return data;
};

export const viewAllTargets = async (type, filters) => {
  const { data } = await axios.get(baseURL + type, {
    params: filters,
    headers: setAuth(),
  });

  return data;
};

export const viewAllTargetsV1 = async (type, filters) => {
  const { data } = await axios.get(baseURLV1 + type, {
    params: filters,
    headers: setAuth(),
  });

  return data;
};

export const newTarget = async (type, details) => {
 
  const { data } = await axios.post(baseURL + type, details, {
    headers: setAuth(),
  });
  
  return data;
};
export const newTargetV1 = async (type, details) => {
 
  const { data } = await axios.post(baseURLV1 + type, details, {
    headers: setAuth(),
  });
  
  return data;
};

export const uploadTarget = async (type, details, userId) => {
  const { data } = await axios.post(baseURL + "upload/" + type, details, {
    params: userId,
    headers: setAuth(),
  });

  return data;
};
export const uploadUserTargets = async (type, details, userId) => {
  const { data } = await axios.put(baseURL + "upload/" + type, details, {
    params: userId,
    headers: setAuth(),
  });

  return data;
};

export const newTargetForApproval = async (type, details) => {
  // const { data } = await axios.post(baseURL + type, details, {
  const {data} = await axios.post(baseURLV1 + type, details, {
    headers: setAuth(),
  });

  return data;
};

export const editTarget = async (type, id, updatedTarget) => {
  const { data } = await axios.put(baseURL + `${type}/edit/${id}`, updatedTarget, {
    headers: setAuth(),
  });

  return data;
};

export const deleteTarget = async (type, id, details) => {
  await axios.post(baseURL + `${type}/delete/${id}`,details,
    {
      headers: setAuth(),
    }
  );
};

export const approveTarget = async (type, id, details) => {
  const { data } = await axios.post(`${approveURL}/${type}/${id}`, details, {
    headers: setAuth(),
  });

  return data;
};

export const rejectTarget = async (type, id, details) => {
  const { data } = await axios.post(`${rejectURL}/${type}/${id}`, details, {
    headers: setAuth(),
  });

  return data;
};

export const AddDWPL = async (details) => {
  const { data } = await axios.post(`${addDWPLV1}`, details, {
    headers: setAuth(),
  });

  return data;
};

export const viewDWPL = async () => {
  const { data } = await axios.get(`${addDWPLV1}/view`, {
    headers: setAuth(),
  });
  return data;
};

export const updateDWPL = async (details) => {
  const { data } = await axios.post(`${addDWPLV1}/update`, details, {
    headers: setAuth(),
  });
  return data;
};

export const deleteDWPL = async (details) => {
  const { data } = await axios.post(
    `${addDWPLV1}/delete`,
    { dwplId: details },
    {
      headers: setAuth(),
    }
  );
  return data;
};

export const addSpecialization = async (details) => {
  const { data } = await axios.put(`${addDWPLV1}/specialization`, details, {
    headers: setAuth(),
  });

  return data;
};
export const viewSpecialization = async() => {
  const { data } = await axios.get(`${addDWPLV1}/specialization/view`, {
    headers: setAuth(),
  });
  return data;
}
export const viewSWPL = async (details) => {
  const { data } = await axios.get(`${addDWPLV1}/view`, {
    params:{onlySPL : true},
    headers: setAuth(),
  });
  return data;
};

export const viewSpecificDCS = async(type, payload) => {
  const {data} = await axios.get(baseURL + `${type}/specific`,{
    headers: setAuth(),
    params: payload
  });
  return data;
}

export const LinkDP = async (details) => {
  const { data } = await axios.post(`${crm}/doctor-productLink`, details, {
    headers: setAuth(),
  });

  return data;
};

export const ViewLinkedDP = async (details) => {
  const { data } = await axios.get(`${crm}/doctor-productView`, {
    headers: setAuth(),
  });

  return data;
};

export const updateLinkedDP = async (details) => {
  const { data } = await axios.post(`${crm}/doctor-productEdit/${details.docProdId}`, details, {
    headers: setAuth(),
  });
  return data;
};

export const deleteLinkedDP = async (details) => {
  const { data } = await axios.post(`${crm}/doctor-productDelete/${details.docProdId}`,details,{
    headers: setAuth(),
  });
  return data;
};

export const LinkCD = async (details) => {
  const { data } = await axios.post(`${crm}/chemist-doctorLink`, details, {
    headers: setAuth(),
  });

  return data;
};


export const ViewLinkedCD = async (details) => {
  const { data } = await axios.get(`${crm}/chemist-doctorView`, {
    headers: setAuth(),
  });

  return data;
};

export const updateLinkedCD = async (details) => {
  const { data } = await axios.post(`${crm}/chemist-doctorEdit/${details.chemDocId}`, details, {
    headers: setAuth(),
  });
  return data;
};

export const deleteLinkedCD = async (details) => {
  const { data } = await axios.post(`${crm}/chemist-doctorDelete/${details.chemDocId}` ,details,{
    headers: setAuth(),
  });
  return data;
};


export const LinkSC = async (details) => {
  const { data } = await axios.post(`${crm}/stockist-chemistLink`, details, {
    headers: setAuth(),
  });

  return data;
};

export const ViewLinkedSC = async (details) => {
  const { data } = await axios.get(`${crm}/stockist-chemistView`, {
    headers: setAuth(),
  });

  return data;
};

export const updateLinkedSC = async (details) => {
  const { data } = await axios.post(`${crm}/stockist-chemistEdit/${details.stocChemId}`, details, {
    headers: setAuth(),
  });
  return data;
};

export const deleteLinkedSC = async (details) => {
  const { data } = await axios.post(`${crm}/stockist-chemistDelete/${details.stocChemId}`,details, {
    headers: setAuth(),
  });
  return data;
};

export const addProductSaleReport = async (details) => {
  const { data } = await axios.post(`${crm}/addProductSaleReport/`,details, {
    headers: setAuth(),
  });
  return data;
};

export const viewProductSaleReport = async (details) => {
  const { data } = await axios.post(`${crm}/viewProductSaleReport/${details.docId}`,details, {
    headers: setAuth(),
  });
  return data;
};

export const AddPayment = async (details) => {
  const { data } = await axios.post(`${crm}/addPayment/${details.docId}`,details, {
    headers: setAuth(),
  });
  return data;
};

export const viewPayment = async (details) => {
  const { data } = await axios.post(`${crm}/viewPayment/${details.docId}`,details, {
    headers: setAuth(),
  });
  return data;
};

export const viewAllPayment = async (details) => {
  const { data } = await axios.post(`${crm}/all/viewPayment`,details, {
    headers: setAuth(),
  });
  return data;
};

export const viewDoctorSales = async (details) => {
  const { data } = await axios.post(`${crm}/viewDoctorSales`,details, {
    headers: setAuth(),
  });
  return data;
};