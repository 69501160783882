import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";
import { addDays } from "date-fns";

import { selectUserStyles } from "../../../public/stylesheets/selectStyles";
import { MdArrowBackIosNew, MdOutlineGroup, MdOutlineRefresh } from "react-icons/md";
import { FaEye } from "react-icons/fa";

import Table from "../../../../components/Table";
import DateRange from "../../../../components/DateRange";

import { formatDate, monthOptions } from "../../../../utils/helper";
import serialise, { generateSelectData, generateSelectUser } from "../../../../utils/serialiseResponse";

import { viewUsersForUtilities } from "../../../../services/utilities";
import { viewCallPlanningReport } from "../../../../services/callPlanning";
import customToast from "../../../../components/CustomToast";

const UtilitiesCallPlanning = () => {
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);
  const [cpData, setCPData] = useState([])


  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [dateRange, setDateRange] = useState({startDate: new Date(),endDate: addDays(new Date(), 7),});
  const [startMonth, setStartMonth] = useState(dateRange.startDate.getMonth());
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === selectedUser.label) return true;
      })
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  useEffect(() => {
    setStartMonth(dateRange.startDate.getMonth())
  }, [dateRange])

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        minWidth: 130,
        maxWidth: 130,
        Cell: (props) => {
          return <span>{formatDate(props?.row?.original?.date)}</span>;
        },
      },
      {
        Header: "Day",
        accessor: "day",
        minWidth: 130,
        maxWidth: 130,
        Cell: (props) => {
            let day = new Date(props?.row?.original?.date).toLocaleString('en-us', { weekday: 'long' });
            return <span>{day}</span>;
        },
      },
      {
        Header: "Name",
        accessor: "submitter.firstName",
        Cell: (props) => {
          
         return <span>
             {props?.row?.original?.submitter?.firstName + " " + props?.row?.original?.submitter?.lastName}
          </span>
      },
      },
      {
        Header: "Doctors",
        accessor: "doctors",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          return props?.row?.original?.doctors?.length ?? "-"
        }
      },
      {
        Header: "Chemists",
        accessor: "chemists",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          return props?.row?.original?.chemists?.length ?? "-"
        }
      },
      {
        Header: "Stockists",
        accessor: "stockists",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          return props?.row?.original?.stockists?.length ?? "-"
        }
      },
      {
        Header:"Edited",
        accessor:"",
        minWidth: 100,
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => props?.row?.original?.edited ? <span className="text-warning">Edited</span> : <span className="text-info">New</span>  
      },
      {
        Header: "Action",
        Cell: (props) => {
            return (
                <Link
                    to={props?.row?.original?._id}
                    state={props?.row?.original}
                    className="util-table-view-details-link"
                >
                    <FaEye className="icon-color-tertiary" />
                </Link>
            )
        }
      }
    ],
    []
  );

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      customToast.error("Error fetching users");
    }
  }, []);

 useEffect(() => {
    const fetchCallPlanningReport = async () => {
      try {
        const res = await viewCallPlanningReport({
          startDate: new Date(dateRange?.startDate).getTime(),
          endDate: new Date(dateRange?.endDate).getTime(),
          selectedUser: selectedUser?.value,
        });
        setCPData(res.data);
      } catch (error) {
        customToast.error("Error fetching call planning reports");
      }
    };

    fetchCallPlanningReport();
  }, [selectedUser, dateRange]);

  const tableData = useMemo(() => serialise(cpData), [cpData]);
  
  function compareDates(a, b) {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  }
  // Sort the tableData array using the compareDates function
  tableData?.sort(compareDates);

  return (
    <div className="expense">
      <div className="row">
      <div className="d-flex justify-content-between">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Call Planning Reports
          </span>
        </h2>
        </div>

        <div className="util-tp-query d-flex justify-content-between">
          <div className="d-flex">
            {loggedIn?.user?.des < 121 ||
            loggedIn?.user?.designation?.priority > 1 ||
            loggedIn?.user?.isAdmin ? (
              <div className="util-tp-filter me-5">
                <p className="util-select-user__label">Select User</p>
                <div className="util-select-user">
                  <MdOutlineGroup className="util-select-user__icon" />
                  <Select
                    styles={selectUserStyles}
                    options={employeeSelect}
                    placeholder="Select User"
                    className="mt-3"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser({ ...e })}
                  />
                <MdOutlineRefresh size={25} className="icon-color-tertiary mt-4 ms-2" style={{cursor:"pointer"}} onClick={() => setSelectedUser(null)}/>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="date-range-container my-3">
          <DateRange setDateRange={setDateRange} />
        </div>
        <div className="filter-table">
          { tableData?.length > 0 && (
            <div className="util-tp-query d-flex">
              <div className="d-flex utility-cards__heading">
                <h2 className="web-app__heading mt-4 mx-5">
                  Showing ({tableData.length}) Entries
                </h2>
              </div>
            </div>
          )}
          { tableData?.length > 0 ? (
            <Table defaultPageSize={50} columns={columns} data={tableData} empl={selectedUser ? employeeDataForExcel : loggedIn?.user} fileSrc="Call Planning"
            month={monthOptions.at(startMonth)} 
            />
          ) : <h3 className="no-results-message">No results found</h3>}
        </div>
      </div>
    </div>
  );
};

export default UtilitiesCallPlanning;