import { GoogleMap, Marker, Polyline, useJsApiLoader } from "@react-google-maps/api";
import startMarker from "../../views/public/img/startMarker.png"
import endMarker from "../../views/public/img/endMarker.png"


const WorkInMap = ({ longitude, latitude, latitude2, longitude2, type }) => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_API_KEY
    });

    const containerStyle = {
        width: '100%',
        height: '300px',
        position: 'relative'
    };

    return isLoaded ? (
        <div style={{ width: '100%', height: '300px' }}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={{ lat: latitude, lng: longitude }}
                zoom={16}
            >
                <Marker
                    position={{ lat: latitude, lng: longitude }}
                    icon={{
                        scaledSize: { width: 50, height: 60 },
                        url: startMarker
                    }}
                />

                {latitude && longitude && latitude2 && longitude2 && (
                    <div>
                        <Marker
                            position={{ lat: latitude2, lng: longitude2 }}
                            icon={{
                                scaledSize: { width: 50, height: 60 },
                                url: endMarker
                            }}

                        />
                        {/* <Polyline
                            path={[{ lat: latitude, lng: longitude }, { lat: latitude2, lng: longitude2 }]}
                            options={{ strokeColor: "red", strokeOpacity: 1.0, strokeWeight: 2 }} /> */}
                    </div>
                )}

            </GoogleMap>
        </div>
    ) : null;
}

export default WorkInMap