import { MdArrowBackIosNew, MdShareLocation, MdTimelapse } from "react-icons/md";
import { useNavigate, useLocation,useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  RiCapsuleFill,
  RiGiftFill,
  RiNurseFill,
  RiUser3Fill,
  RiCloseCircleLine
} from "react-icons/ri";
import Lightbox from "yet-another-react-lightbox";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import {
  MdDeleteOutline,
  MdCheckCircleOutline,
  MdDelete,
} from "react-icons/md";
import { formatDate, formatTime } from "../../../../../utils/helper";
import { monthOptions } from "../../../../../utils/helper";
import { useEffect, useState } from "react";
import { viewTPForDate } from "../../../../../services/tourProgram";
import moment from "moment-timezone";
import Accordion from "react-bootstrap/Accordion";
import { deleteCallReport } from "../../../../../services/dcr";
import customToast from "../../../../../components/CustomToast";
import { setCities } from "../../../../../reducers/locations/cityReducer";
import { GoogleMap, Marker, Polyline, useJsApiLoader } from "@react-google-maps/api";
import { viewGeoLocationData } from "../../../../../services/geoFencing";
import DocMarker from "../../../../public/img/DocMarker.png";
import ChemMarker from "../../../../public/img/ChemMarker.png";
import StkMarker from "../../../../public/img/StockMarker.png";
import userLogo from "../../../../public/img/user-logo.png";
import userMarker from "../../../../public/img/user_marker.png";
import { FaStar } from "react-icons/fa";
import { viewPunchingReportDetails } from "../../../../../services/utilities";
import { LuTimerReset } from "react-icons/lu";
import { FaMapLocation } from "react-icons/fa6";
import WorkInMap from "../../../../../components/MapWorkIn";

const CRDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const loggedIn = useSelector(({ user }) => user);
  const cities = useSelector(({ city }) => city);

  const [tpData, setTpData] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const images = [];
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [geoLocReports, setGeoLocReports] = useState([]);
  const [workingHours, setWorkingHours] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [mapData, setMapData] = useState(null)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_KEY
  });

  const containerStyle = {
    width: '100%',
    height: '300px',
    position: 'relative'
  };

  useEffect(() => {
    const fetchData = async() => {
      try {
        let payload = {
          month: location?.state?.month,
          year: location?.state?.year,
          userId: location?.state?.submitter?._id,
          date: location?.state?.date
        };
  
        let res = await viewGeoLocationData(payload);
        setGeoLocReports(res?.data);
         let data = await viewPunchingReportDetails({date: location?.state?.date, selectedUser: location?.state?.submitter?._id})
                setWorkingHours(data?.data)
      } catch (error) {
        console.log(error)
      }
    };
    fetchData();
  },[]);
  
  const ViewMap = ({longitude, latitude, latitude2, longitude2, type}) => {

    return isLoaded ? (
    <div style={{ width: '100%', height: '300px' }}> 
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{lat: latitude, lng: longitude}}
      zoom={16}
    >
      <Marker 
        position={{lat: latitude,  lng: longitude}}
        icon={{
          url: userMarker,
          scaledSize: { width: 40, height: 60 }
        }}
      />

      {latitude && longitude && latitude2 && longitude2 && (
        <div>
          <Marker
          position={{lat: latitude2, lng:longitude2}}
          icon={{
            url: type === "doc" ? DocMarker : type === 'stk' ? StkMarker : ChemMarker,
            scaledSize: { width: 30, height: 40 }
          }}
          />
          <Polyline
          path={[{lat:latitude, lng:longitude},{lat:latitude2, lng:longitude2}]}
          options={{ strokeColor: "red", strokeOpacity: 1.0, strokeWeight: 2 }}/>
        </div>
      )}

    </GoogleMap>
  </div>
) : null;
  }

  useEffect(()=>{
    dispatch(setCities());
  },[dispatch]);
  
  location?.state?.doctors?.length > 0 && location?.state?.doctors?.map((item) => (
    item.files?.length > 0 && item.files?.map((pic) => (
      images.push({ name: item.target.name, pic: pic ,designation:"doctor" })
    ))
  ))
  location?.state?.chemists?.length > 0 && location?.state?.chemists?.map((item) => (
    item.files?.length > 0 && item.files?.map((pic) => (
      images.push({ name: item.target.businessName, pic: pic ,designation:"chemist"})

    ))
  ))
  location?.state?.stockists?.length > 0 && location?.state?.stockists?.map((item) => (
    item.files?.length > 0 && item.files?.map((pic) => (
      images.push({ name: item.target.businessName, pic: pic,designation:"stockist" })

    ))
  ))

  useEffect(() => {
    if (!location?.state?.deviated) {
      let formattedDate = moment
        .tz(location?.state?.date, "Asia/Kolkata")
        .format()
        .toString()
        .substring(0, 10);
      const asyncFetch = async () => {
        await viewTPForDate({ date: formattedDate,selectedUser:location.state?.selectedUser}).then((res) => {
          setTpData(res.data);
        });
      };
      asyncFetch();
    }
  }, [location]);
  
  const EditPopup = () => {

    const handleDelete = (e) => {
      e.preventDefault();
      deleteCallReport(location?.state?._id).then((data)=>{
        customToast.success("Deleted successfully")
        navigate(-1);
        setShowEdit(false);
      }).catch((err)=>{
        customToast.error("We encountered an error")
      })
  
      
    };
    
    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Call Report</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleDelete}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Caution! This call report will be permanently deleted.
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-delete mt-4">
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Yes</span>
              </button>

              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setShowEdit(false)}
              >
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">No</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

 const cityName = (target)=>{
  const citi = cities?.data?.find((ele) => ele._id === target?.workingArea) ;
  return citi?.name ;
 }
   
  return (
    <div className="utility-inner-tp">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" , justifyContent:"space-between" }}
        >
          <div>
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              Call Details
            </span>
          </div>
          
          {/* <button>delete</button> */}
          {loggedIn?.user?.isAdmin?<span
              className="react-table-view-link"
              onClick={() => {
                setShowEdit(true);
              }}
            >
              <MdDelete className="icon-color-error" />
          </span>:null}
        </h2>

        <div className="util-inner-details">
          <div className="util-inner-info-box-container">
            <div className="util-inner-info-box">
              <h3>Date</h3>
              <p>{formatDate(location?.state?.date)}</p>
            </div>

            <div className="util-inner-info-box">
              <h3>Month</h3>
              <p>
                {
                  monthOptions[new Date(`${location?.state?.date}`).getMonth()]
                    ?.label
                }
              </p>
            </div>

            <div className="util-inner-info-box">
              <h3>Activity Type</h3>
              <p>{location?.state?.activity?.charAt(0).toUpperCase() + location?.state?.activity?.slice(1)}</p>
            </div>

            <div className="util-inner-info-box">
              <h3>Area Type</h3>
              <p>
                {location?.state?.deviated
                  ?tpData?.areaType : location?.state?.areaType}
              </p>
            </div>

            <div className="util-inner-info-box">
              <h3>Approved By</h3>
              <p>
                {
                  location?.state?.authorizedBy ? location?.state?.authorizedBy : "NA"
                }
              </p>
            </div>
            <div>
            {location?.state?.activity !== "working" ? (
              <div className="util-inner-info-box">
                <h3>Remarks</h3>
                <p>{location?.state?.remarks}</p>
            </div>
            ) : null}
          </div>
          </div>
        </div>
        

        <div className="util-inner-location-details">
          {location?.state?.deviated
            ? location?.state?.workAreas?.map((el, index) => (
                <div className="util-inner-location-box" key={index}>
                  <MdShareLocation className="util-inner-location-box-icon" />
                  <p>{el?.location?.label}</p>
                </div>
              ))
            : tpData?.workAreas?.map((el, index) => (
                <div className="util-inner-location-box" key={index}>
                  <MdShareLocation className="util-inner-location-box-icon" />
                  <p>{el?.location?.label}</p>
                </div>
              ))}
        </div>

        <div style={{display:"flex"}}>
          <div >

            <div className="doctor-call-div">
              <h2 className="web-app__heading mt-5">
                {workingHours?.length > 0 ? "Working Hours" : null}
              </h2>

              <Accordion className="mt-4" alwaysOpen>
                <Accordion.Header>
                  {" "}
                  <span className="d-flex align-items-center">
                    {<LuTimerReset className="me-3 fs-2" />} Working Hours
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  {workingHours && workingHours?.map((elem, i) => (
                    <div key={i} className="acc-body-card d-flex mt-4">
                      <MdTimelapse className="acc-body-card-icon acc-i-green" />
                      {
                        elem?.workInTime && (
                          <>
                            <div className="acc-body-card-content d-flex align-items-center justify-content-between px-4">
                              <div>
                                {formatTime(elem?.workInTime)} - {elem?.workOutTime ? formatTime(elem?.workOutTime) : "N/A"}
                              </div>
                              <div>
                                {
                                  (elem?.WorkingInCoordinate) ? (
                                    <FaMapLocation
                                      className="icon-color-tertiary cursor-pointer"
                                      onClick={
                                        () => {
                                          setShowMap(true)
                                          setMapData(elem)
                                        }
                                      }
                                    />
                                  ) : null
                                }
                              </div>
                            </div>
                          </>
                        )
                      }
                      {
                        elem?.checkInTime && (
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {formatTime(elem?.checkInTime)} - {elem?.checkOutTime ? formatTime(elem?.checkOutTime) : "N/A"}
                          </div>
                        )
                      }
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion>
            </div>

          <div className="doctor-call-div">
            <h2 className="web-app__heading mt-5">
              {location?.state?.doctors.length > 0 ? "Doctor Calls" : null}
            </h2>

            <Accordion className="mt-4" alwaysOpen>
              {location?.state?.doctors?.length > 0 && location?.state?.doctors?.map((e, i) => (
                <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                  <Accordion.Header>
                    {" "}
                    <span className="d-flex align-items-start flex-column">
                      <span>{<RiNurseFill className="me-3 fs-2" />} {e?.target?.name}</span>
                      <div style={{fontSize:"1.5rem"}} className="password-icon-show">{cityName(e?.target)}</div>

                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <h5 className="web-app__heading-blue">
                      {e?.products?.length > 0 ? "POB DETAILS" : null}
                    </h5>
                    {e?.products?.length > 0  && e?.products.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.productName} ---{" "}
                          {elem.quantity ? elem.quantity : 0} --- ₹
                          {elem.amount ? elem.amount : 0} | Sample:{" "}
                          {elem.sampleQuantity ? elem.sampleQuantity : 0}
                        </div>
                      </div>
                    ))}

                    <h5 className="web-app__heading-blue mt-5">
                      {e?.gifts?.length > 0 ? "Gift Details" : null}
                    </h5>
                    {e?.gifts?.length > 0 && e?.gifts?.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.giftName} ---
                          {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                          {elem.giftAmount ? elem.giftAmount : 0}
                        </div>
                      </div>
                    ))}

                    <h5 className="web-app__heading-blue mt-5">
                      {e?.workedWith?.length > 0 ? "Worked With" : null}
                    </h5>
                    {e?.workedWith?.length > 0 && e?.workedWith.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiUser3Fill className="acc-body-card-icon acc-i-light-yellow" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.firstName} {elem?.lastName}
                          {/* ({elem?.designation?.name}) */}
                        </div>
                      </div>
                    ))}

                    {e.remarks ? (
                      <>
                        <h5 className="web-app__heading-blue mt-5">Remarks</h5>
                        <h3 className="acc-body-remarks">{e?.remarks}</h3>
                      </>
                    ) : null}
                    
                      
                    <h5 className="web-app__heading-blue mt-5">Report Time</h5>
                    <h3 className="acc-body-remarks">{formatTime(e?.cdt)}</h3>

                    {geoLocReports && geoLocReports?.length > 0 && (() => {
                      const element = geoLocReports?.find((item) => item?.target?.value === e?.target?._id);
                      return element ? 
                      <div>
                        <h5 className="web-app__heading-blue mt-5">Geo-tagging</h5>
                        <ViewMap latitude={element?.geolocation?.latitude} longitude={element?.geolocation?.longitude} latitude2={e?.target?.geolocation?.latitude} longitude2={e?.target?.geolocation?.longitude} type={e?.type}/>
                      </div>
                      :
                      null
                    })
                    ()
                    }
                    {e?.rating ? (
                      <>
                        <h5 className="web-app__heading-blue mt-5">Rating</h5>
                        <h3 className="acc-body-remarks">
                          {Array.from({ length: e.rating }, (_, i) => (
                            <FaStar key={i} className="icon-rating" />
                          ))}
                        </h3>
                      </>
                    ) : null}  
                    
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div className="chemist-call-div">
            <h2 className="web-app__heading report-heading mt-5">
              {location?.state?.chemists?.length > 0 ? "Chemist Calls" : null}
            </h2>
            <Accordion className="mt-4" alwaysOpen>
              {location?.state?.chemists?.length > 0 && location?.state?.chemists?.map((e, i) => (
                
                <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                  <Accordion.Header>
                    {" "}
                    <span className="d-flex align-items-start flex-column">
                      <span>{<RiNurseFill className="me-3 fs-2" />} {e?.target?.businessName}</span>
                      <div style={{fontSize:"1.5rem"}} className="password-icon-show">{cityName(e?.target)}</div>
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <h5 className="web-app__heading-blue">
                      {e?.products?.length > 0 ? "POB DETAILS" : null}
                    </h5>
                    {e?.products?.length > 0  && e?.products?.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.productName} ---{" "}
                          {elem.quantity ? elem.quantity : 0} --- ₹
                          {elem.amount ? elem.amount : 0} | Sample:{" "}
                          {elem.sampleQuantity ? elem.sampleQuantity : 0}
                        </div>
                      </div>
                    ))}

                    <h5 className="web-app__heading-blue mt-5">
                      {e?.gifts?.length > 0 ? "Gift Details" : null}
                    </h5>
                    {e?.gifts?.length > 0 && e?.gifts.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.giftName} ---
                          {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                          {elem.giftAmount ? elem.giftAmount : 0}
                        </div>
                      </div>
                    ))}

                    <h5 className="web-app__heading-blue mt-5">
                      {e?.workedWith?.length > 0 ? "Worked With" : null}
                    </h5>
                    {e?.workedWith?.length > 0  && e?.workedWith?.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiUser3Fill className="acc-body-card-icon acc-i-light-yellow" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.firstName} {elem?.lastName}
                          {/* ({elem?.designation?.name}) */}
                        </div>
                      </div>
                    ))}

                    {e.remarks ? (
                      <>
                        <h5 className="web-app__heading-blue mt-5">Remarks</h5>
                        <h3 className="acc-body-remarks">{e?.remarks}</h3>
                      </>
                    ) : null}

                    <h5 className="web-app__heading-blue mt-5">Report Time</h5>
                    <h3 className="acc-body-remarks">{formatTime(e?.cdt)}</h3>

                    {geoLocReports && geoLocReports?.length > 0 && (() => {
                      const element = geoLocReports?.find((item) => item?.target?.value === e?.target?._id);
                      return element ? 
                      <div>
                        <h5 className="web-app__heading-blue mt-5">Geo-tagging</h5>
                        <ViewMap latitude={element?.geolocation?.latitude} longitude={element?.geolocation?.longitude} latitude2={e?.target?.geolocation?.latitude} longitude2={e?.target?.geolocation?.longitude} type={e?.type}/>
                      </div>
                      :
                      null
                    })
                    ()
                    }
                    {e?.rating ? (
                      <>
                        <h5 className="web-app__heading-blue mt-5">Rating</h5>
                        <h3 className="acc-body-remarks">
                          {Array.from({ length: e.rating }, (_, i) => (
                            <FaStar key={i} className="icon-rating" />
                          ))}
                        </h3>
                      </>
                    ) : null}

                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div className="stockist-call-div">
            <h2 className="web-app__heading mt-5">
              {location?.state?.stockists?.length > 0 ? "Stockist Calls" : null}
            </h2>
            <Accordion className="mt-4" alwaysOpen>
              {location?.state?.stockists?.length > 0 && location?.state?.stockists?.map((e, i) => (
                <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                  <Accordion.Header>
                    {" "}
                    <span className="d-flex align-items-start flex-column">
                      <span>{<RiNurseFill className="me-3 fs-2" />} {e?.target?.businessName}</span>
                      <div style={{fontSize:"1.5rem"}} className="password-icon-show">{cityName(e?.target)}</div>

                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <h5 className="web-app__heading-blue">
                      {e?.products?.length > 0 ? "POB DETAILS" : null}
                    </h5>
                    {e?.products?.length > 0 && e?.products?.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.productName} ---{" "}
                          {elem.quantity ? elem.quantity : 0} --- ₹
                          {elem.amount ? elem.amount : 0} | Sample:{" "}
                          {elem.sampleQuantity ? elem.sampleQuantity : 0}
                        </div>
                      </div>
                    ))}

                    <h5 className="web-app__heading-blue mt-5">
                      {e?.gifts?.length > 0 ? "Gift Details" : null}
                    </h5>
                    {e?.gifts?.length > 0 && e?.gifts?.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.giftName} ---
                          {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                          {elem.giftAmount ? elem.giftAmount : 0}
                        </div>
                      </div>
                    ))}

                    <h5 className="web-app__heading-blue mt-5">
                      {e?.workedWith?.length > 0 ? "Worked With" : null}
                    </h5>
                    {e?.workedWith?.length > 0 && e?.workedWith?.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiUser3Fill className="acc-body-card-icon acc-i-light-yellow" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.firstName} {elem?.lastName}
                          {/* ({elem?.designation?.name}) */}
                        </div>
                      </div>
                    ))}

                    {e.remarks ? (
                      <>
                        <h5 className="web-app__heading-blue mt-5">Remarks</h5>
                        <h3 className="acc-body-remarks">{e?.remarks}</h3>
                      </>
                    ) : null}

                    <h5 className="web-app__heading-blue mt-5">Report Time</h5>
                    <h3 className="acc-body-remarks">{formatTime(e?.cdt)}</h3>

                    {geoLocReports && geoLocReports?.length > 0 && (() => {
                      const element = geoLocReports?.find((item) => item?.target?.value === e?.target?._id);
                      return element ? 
                      <div>
                        <h5 className="web-app__heading-blue mt-5">Geo-tagging</h5>
                        <ViewMap latitude={element?.geolocation?.latitude} longitude={element?.geolocation?.longitude} latitude2={e?.target?.geolocation?.latitude} longitude2={e?.target?.geolocation?.longitude} type={e?.type}/>
                      </div>
                      :
                      null
                    })
                    ()
                    }
                    {e?.rating ? (
                      <>
                        <h5 className="web-app__heading-blue mt-5">Rating</h5>
                        <h3 className="acc-body-remarks">
                          {Array.from({ length: e.rating }, (_, i) => (
                            <FaStar key={i} className="icon-rating" />
                          ))}
                        </h3>
                      </>
                    ) : null}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          </div>
          <div className="gallery">


            <ResponsiveMasonry
              columnsCountBreakPoints={{ 300: 2, 900: 3 }}
              className="gallery-img-container"
            >
              <Masonry>
                {(images?.length > 0) ? (
                  images?.map((e, index) => (
                    (e.name.trim() !== "" && e.pic.trim() !== "") &&
                    <div className="call-report-card"
                      key={index}
                      onClick={(e) => {
                        setIndex(index);
                        setOpen(true);
                      }}
                    >

                      <img src={e?.pic} alt="" className="call-report-pic" loading="lazy"/>
                      <div className="img-call-wrapper  d-flex justify-content-between align-items-center " style={{width:"11rem"}}>
                        <h5 className="img-name  text-white  bg-dark">{e?.name}</h5>

                        {/* {loggedIn?.user?.isAdmin ? (
                          <MdDeleteOutline
                            onClick={(event) => {
                              event.stopPropagation();
                              setItemDelete({
                                prodId: e._id,
                                link: e.uri,
                                ID: e.ID,
                              });

                                                          // setShowEdit(true);
                            // setShowEdit(true);
// // handleDelete({ prodId: e._id, link: e.img,ID:e.ID })
                            //   setShowEdit(true);
                            // handleDelete({ prodId: e._id, link: e.img,ID:e.ID })
                            }}
                            style={{
                              cursor: "pointer",
                              marginTop: "-1rem",
                              fontSize: "1.4vmax",
                            }}
                            className="button-delete__icon bg-dark"
                          />
                        ) : null} */}
                      </div>
                    </div>
                  ))) : (
                  <label className="web-app__heading">No Images here </label>
                )
                }
              </Masonry>
            </ResponsiveMasonry>
            {/* // <img src={pic} alt="" className="call-report-pic" /> */}
            <Lightbox
              index={index}
              open={open}
              close={() => setOpen(false)}
              slides={images.map(e => (e?.pic?.trim() !== "" && { src: e?.pic }))}
            />

          </div>
        </div>
      {showEdit ? <EditPopup /> : null}
      
        {
          showMap ? (
            <section className="edit-popup">
              <div className="edit-content">
                <div className="edit-popup__heading">
                  <h2 className="web-app__heading mt-4">Remark : {mapData?.remark}</h2>
                  <RiCloseCircleLine
                    className="edit-popup__close"
                    onClick={() => {
                      setShowMap(false)
                    }}
                  />
                </div>

                <div className="mt-4">
                  <WorkInMap latitude={mapData?.WorkingInCoordinate?.latitude} longitude={mapData?.WorkingInCoordinate?.longitude} latitude2={mapData?.WorkingOutCoordinate?.latitude} longitude2={mapData?.WorkingOutCoordinate?.longitude} />
                </div>
              </div>
            </section>
          ) : null
        }
      </div>
    </div>
  );
};

export default CRDetails;
