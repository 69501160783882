import { useState, useMemo, useEffect } from "react";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import { addAllowance } from "../../../../services/allowance";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import Select from "react-select";
import serialise, {
  generateSelectData, getDesignation,
} from "../../../../utils/serialiseResponse";
import { useDispatch, useSelector } from "react-redux";
import { DESIGNATIONS } from "../../../../constants/enums";
import Table from "../../../../components/Table";
import {
  clearAllowance,
  deleteAllowance,
  viewAllowances,
} from "../../../../reducers/expenses/allowance";
import {
  clearStates,
  setStates,
} from "../../../../reducers/locations/stateReducer";
import { MdDeleteOutline } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";

const AdminAllowance = () => {
  const dispatch = useDispatch();
  const states = useSelector(({ state }) => state);
  const allowanceData = useSelector(({ allowance }) => allowance);

  const [state, setState] = useState(0);
  const [fromDistance, setFromDistance] = useState(null);
  const [toDistance, setToDistance] = useState(null);
  const [travelAllowance, setTravelAllowance] = useState(null);
  const [designation, setDesignation] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [modalData, setModalData] = useState();

  useEffect(() => {
    dispatch(setStates());
    dispatch(viewAllowances());

    return () => {
      dispatch(clearStates());
      dispatch(clearAllowance());
    };
  }, [dispatch]);

  const designationSelect = useMemo(
    () => generateSelectData(DESIGNATIONS, "name"),
    [DESIGNATIONS]
  );
  const stateSelect = useMemo(
    () => generateSelectData(states.data, "name"),
    [states]
  );

  const resetForm = () => {
    setState(null);
    setFromDistance(0);
    setToDistance(0);
    setTravelAllowance(0);
    setDesignation(null);
  };

  const DeletePopup = ({ data }) => {
    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Allowance</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowDelete(false)}
            />
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowDelete(false);
              dispatch(deleteAllowance("exStation", data?._id));
            }}
          >
            <p className="mt-5">Do you really want to delete this allowance?</p>
            <p>
              {data?.state}: {data?.designation} — {data?.fromDistance} -{" "}
              {data?.toDistance}: ₹{data?.travelAllowance}
            </p>
            <div className="edit-form-button-container">
              <button type="submit" className="button-delete mt-4">
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>

              <button
                type="button"
                onClick={() => setShowDelete(false)}
                className="button-submit-green mt-4"
              >
                <span className="button-submit-green__icon-container">
                  <RiCloseCircleLine className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">No</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 60,
      //   maxWidth: 60,
      // },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Designation",
        accessor: ``,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return <span>{getDesignation(props?.row?.original?.designation)}</span>;
        },
      },
      {
        Header: "From Distance",
        accessor: "fromDistance",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "To Distance",
        accessor: "toDistance",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Travel Allowance",
        accessor: "travelAllowance",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Delete",
        accessor: "",
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link"
              onClick={() => {
                setModalData(props.row.original);
                setShowDelete(true);
              }}
            >
              <MdDeleteOutline className="button-delete__icon" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!designation)return customToast.error("Please select the designation");
    else if (!toDistance)return customToast.error("Please fill the To distance");
    else if (!travelAllowance)
      return customToast.error("Please fill Travel Allowance");

    const data = {
      state: state?.value,
      fromDistance: Number(fromDistance),
      toDistance: Number(toDistance),
      travelAllowance,
      designation: designation?.value,
    };

    try {
      await addAllowance(data);
      dispatch(viewAllowances());
      customToast.success("Successfully submitted Allowances");
      resetForm();
    } catch (e) {
      customToast.error(
        "Allowance already exists for the distance range!"
      );
      resetForm();
    }
  };

  const tableData = useMemo(() => serialise(allowanceData?.data?.data),[allowanceData]);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle 
        title="Set Allowances" 
        anchor={true} 
        anchorUrl="https://www.youtube.com/watch?v=C5eVZ0rkDxg"
        anchorContent="How to manage expense ?"
        />

        <section className="area-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="admin-form">
              <div>
                <label htmlFor="state">
                  Select State <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="state"
                  id="state"
                  value={state}
                  options={stateSelect}
                  onChange={({ value, label }) => setState({ value, label })}
                  styles={adminStyles}
                  placeholder="Select State"
                />
              </div>
              <div>
                <label htmlFor="desig">
                  Select Designation <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  styles={adminStyles}
                  options={designationSelect}
                  placeholder="Select Designation"
                  id="desig"
                  value={designation}
                  onChange={(e) => setDesignation({ ...e })}
                />
              </div>
              <div>
                <label htmlFor="da">
                  From Distance <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="number"
                  className="me-5"
                  id="da"
                  min={0}
                  placeholder="Enter Distance"
                  value={fromDistance}
                  onChange={(e) => setFromDistance(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="da">
                  To Distance <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="number"
                  className="me-5"
                  id="da"
                  placeholder="Enter Distance"
                  value={toDistance}
                  min={0}
                  onChange={(e) => setToDistance(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="ta">
                  Travel Allowance (Per KM){" "}
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="number"
                  step="0.01"
                  className="me-5"
                  id="ta"
                  min={0}
                  placeholder="Enter TA"
                  value={travelAllowance}
                  onChange={(e) => setTravelAllowance(e.target.value)}
                />
              </div>
            </div>

            <div className="mt-5">
              <button type="submit" className="button-blue-gradient">
                Set Allowance
              </button>
            </div>
          </form>

          <div className="mt-5">
            <h2 className="web-app__heading mt-5">Present Allowances</h2>
            <div className="filter-table">
              <Table
                columns={columns}
                data={tableData === undefined ? [] : tableData}
              />
            </div>
            {showDelete ? <DeletePopup data={modalData} /> : null}
          </div>
        </section>
      </div>
    </main>
  );
};

export default AdminAllowance;
