import { useState, useCallback, useMemo, useEffect, lazy, Suspense } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
import { addIndex, generateSelectData, generateSelectUser } from "../../../../utils/serialiseResponse";
import { monthOptions, yearOptions } from "../../../../utils/helper";
import { dcrStyles, selectUserStyles } from "../../../public/stylesheets/selectStyles";
import { viewMonthlyReports, viewUsersForUtilities } from "../../../../services/utilities";
import Table from "../../../../components/Table";
import customToast from "../../../../components/CustomToast";
import { viewconsolidateReports } from "../../../../services/reports";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaCircleExclamation } from "react-icons/fa6";
import moment from "moment";
const DatePickers = lazy(() => import("../../../../components/DatePicker"))


const UtilitiesMonthlyReports = ({ isAdmin }) => {
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);

  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [reports, setReports] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [employees, setEmployees] = useState([]);
  const [toggle ,  setToggle] = useState(false);
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();
  const [loading, setLoading] = useState(false);
  
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );

  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth: 50,
      //   maxWidth: 50,
      //   disableFilters: true,
      // },
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Day",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          let day = new Date(props?.row?.original?.date).toLocaleString("en-us", { weekday: "long" });
          return <span>{day}</span>
        },
      },
      {
        Header: "Working Type",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          let text = "" ;
          let color = "" ;
          if(props?.row?.original?.leave){
            text = "Leave" ;
            color = "tp__activity-types-icon-5"
          }else if(props?.row?.original?.holiday){
            text = props?.row?.original?.holiday?.holidayTitle || "Holiday"
            color = "tp__activity-types-icon-2"
          }else if(props?.row?.original?.extraDcr){
            text = props?.row?.original?.extraDcr?.activity || "Extra Activity"
            color = "tp__activity-types-icon-6"
          }else if(props?.row?.original?.areaType){
            text = "Working"
          } else {
            text = " - " ;
          }
          return <span className={color}>{text}</span>
        },
      },
      {
        Header: "Area Type",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return <span>{props?.row?.original?.areaType || <span> - </span> }</span>
        },
      },
      {
        Header: "Doctor Calls",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const temp = props?.row?.original
          return (
            <h2>{temp?.doctors?.length || <span className="text-danger opacity-50">0</span> } </h2> 
          )
        },
      },
      {
        Header: "Stockist Calls",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const temp = props?.row?.original
          return (
            <h2>{temp?.stockists?.length || <span className="text-danger opacity-50">0</span>}</h2> 
          )
        },
      },
      {
        Header: "Chemist Calls",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const temp = props?.row?.original
          return (
            <h2>{temp?.chemists?.length || <span className="text-danger opacity-50">0</span>}</h2> 
          )
        },
      },
      {
        Header: "Work Area",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return <span>{props?.row?.original?.workAreas?.map((el)=>el?.location?.label) || <span> - </span> }</span>
        },
      },
    ],
    [month, year, selectedUser]
  );

  const columns2 = useMemo(
    () => [
      {
        Header: "Users",
        accessor: "user",
        minWidth: 70,
        maxWidth: 150,
        disableFilters: true,
        disableSortBy: true,
        wordWrap: "break-word"
        
      },
      {
        Header: "Total Working Days",
        accessor: "WorkingDays",
        minWidth: 70,
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "No. of Days worked",
        accessor: "No_of_worked_days",
        minWidth: 70,
        maxWidth: 90,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Total Stk",
        accessor: "Total_stockist",
        minWidth: 70,
        maxWidth: 90,
        disableFilters: true,
        disableSortBy: true,
        wordWrap: "break-word"
      },
      {
        Header: "Total Chem",
        accessor: "Total_chemist",
        minWidth: 70,
        maxWidth: 90,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Total Doc",
        accessor: "Total_doctor",
        minWidth: 70,
        maxWidth: 90,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: (
          <OverlayTrigger
            className='cursor-pointer'
            key="top"
            placement="top"
            overlay={
              <Tooltip id={`tooltip-$top`} style={{fontSize:"1.3rem"}}>
                This value depicts the total no. of missed doctors based on their category which is CORE, SUPER-CORE, ULTRA-CORE & NON-CORE.
              </Tooltip>
            }
          >
            <span>Missed Doctors <FaCircleExclamation className="tp__activity-types-icon-5" /></span>
            
          </OverlayTrigger>
        ),
        accessor: "Missed_Doctor",
        minWidth: 70,
        maxWidth: 90,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Doc Calls",
        accessor: "",
        minWidth: 70,
        maxWidth: 90,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const temp = props?.row?.original;
          const totalCalls = temp?.Total_Calls[0]?.totalDoctorCalls || 0;
      
          return <span>{totalCalls}</span>;
          },
      },
      {
        Header: "Avg. Doc Calls",
        accessor:"",
        minWidth: 70,
        maxWidth: 90,
        disableFilters: true,
        disableSortBy: true,
        Cell:(props) => {
          let data = props?.row?.original;
          let avgVal = (data?.Total_Calls[0]?.totalDoctorCalls || 0) / (data?.No_of_worked_days);

          return <span>{avgVal ? avgVal.toFixed(2):0}</span>
        }
      },
      {
        Header: (
          <OverlayTrigger
            className='cursor-pointer'
            key="top"
            placement="top"
            overlay={
              <Tooltip id={`tooltip-$top`} style={{fontSize:"1.3rem"}}>
                This values shows the total no. of doctors visited irrespective of their category.
              </Tooltip>
            }
          >
            <span>Total Doc. Visited <FaCircleExclamation className="tp__activity-types-icon-5" /></span>
            
          </OverlayTrigger>
        ),
        accessor:"Total_Doc_Meet",
        disableFilters :true,
        disableSortBy : true,
      },
      {
        Header: (
          <OverlayTrigger
            className='cursor-pointer'
            key="top"
            placement="top"
            overlay={
              <Tooltip id={`tooltip-$top`} style={{fontSize:"1.3rem"}}>
                This values shows the total (%) of doctors visited irrespective of their category.
              </Tooltip>
            }
          >
            <span>Doc. Cover. % <FaCircleExclamation className="tp__activity-types-icon-5" /></span>
            
          </OverlayTrigger>
        ),
        accessor:"Total_Doc_Meet/Total_doctor",
        minWidth: 70,
        maxWidth: 90,
        disableFilters: true,
        disableSortBy: true,
        Cell:(props) => {
          let data = props?.row?.original;
          let avgVal = (data?.Total_Doc_Meet || 0) * 100 / (data?.Total_doctor);

          return <span>{`${avgVal && avgVal !== Infinity ? avgVal.toFixed(): 0} %`}</span>
        }
      },
      {
        Header: "Stk Calls",
        accessor: "",
        minWidth: 70,
        maxWidth: 90,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const temp = props?.row?.original;
          const totalCalls = temp?.Total_Calls[0]?.totalStockistCalls || 0;
      
          return <span>{totalCalls}</span>;
          },
      },
      {
        Header: "Avg. Stk Calls",
        accessor:"",
        minWidth: 70,
        maxWidth: 90,
        disableFilters: true,
        disableSortBy: true,
        Cell:(props) => {
          let data = props?.row?.original;
          let avgVal = (data?.Total_Calls[0]?.totalStockistCalls || 0) / (data?.No_of_worked_days);

          return <span>{avgVal ? avgVal.toFixed(2): 0}</span>
        }
      },
      {
        Header:"Stk. Cover %",
        accessor:"",
        minWidth: 70,
        maxWidth: 90,
        disableFilters: true,
        disableSortBy: true,
        Cell:(props) => {
          let data = props?.row?.original;
          let avgVal = (data?.Total_Stk_Meet || 0) * 100 / (data?.Total_stockist);

          return <span>{`${avgVal && avgVal !== Infinity ? avgVal.toFixed(): 0} %`}</span>
        }
      },
      {
        Header: "Chem Calls",
        accessor: "",
        minWidth: 70,
        maxWidth: 90,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const temp = props?.row?.original;
          const totalCalls = temp?.Total_Calls[0]?.totalChemistCalls || 0;
      
          return <span>{totalCalls}</span>;
          },
      },
      {
        Header: "Avg. Chem Calls",
        accessor:"",
        minWidth: 70,
        maxWidth: 90,
        disableFilters: true,
        disableSortBy: true,
        Cell:(props) => {
          let data = props?.row?.original;
          let avgVal = (data?.Total_Calls[0]?.totalChemistCalls || 0) / (data?.No_of_worked_days);

          return <span>{avgVal ? avgVal.toFixed(2): 0}</span>
        }
      },
      {
        Header:"Chem. Cover %",
        accessor:"",
        minWidth: 70,
        maxWidth: 90,
        disableFilters: true,
        disableSortBy: true,
        Cell:(props) => {
          let data = props?.row?.original;
          let avgVal = (data?.Total_Chem_Meet || 0) * 100 / (data?.Total_chemist);

          return <span>{`${avgVal && avgVal !== Infinity ? avgVal.toFixed(): 0} %`}</span>
        }
      },
      {
        Header: "Total Calls",
        accessor: "",
        minWidth: 70,
        maxWidth: 90,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const temp = props?.row?.original;
          const totalCalls = temp?.Total_Calls[0]?.totalCalls || 0;
      
          return <span>{totalCalls}</span>;
          },
      },
      {
        Header: "Other Activities",
        accessor: "Activity",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 90,
      },
    ],
    [month, year]
  );

  const handleSubmit = useCallback(
    async (e) => {
      setLoading(true);
      e.preventDefault(); 

      if (!year) {
        customToast.error("Please select month and year");
        return;
      }

      if(loggedIn.user.isAdmin === true && (!toggle && !selectedUser) ){
        customToast.error("Please select user");
        return;
      }

      const filter = {
        month: Number(month),
        year: Number(year),
        userId: selectedUser?.value
      };
      const type = toggle ? 'consolidate' : 'call'  ;
      let monthlyData ;

      if(type === 'consolidate')
      {
         monthlyData = await viewconsolidateReports(filter);
      }
      else{
         monthlyData = await viewMonthlyReports(type,filter);
      }
      setReports(monthlyData.data);
      setLoading(false);
    }, [year, month, selectedUser,toggle])


  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === selectedUser.label) return true;
      })
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  const tableData = useMemo(() => addIndex(reports), [reports]);
  
  return (
    <main className="p-4" >
      <div className="d-flex justify-content-between">
          <h2
            className="web-app__heading"
            style={{ display: "flex", alignItems: "center" }}
          >
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              {toggle ? "Consolidate Reports" : "Monthly Call Reports"}
            </span>
          </h2>
          { isAdmin &&
            <div>
              <div className="d-flex align-items-center">
                <div className="form__control pe-3" style={{ width: "fit-content", color:"#C7C4E9" }}>
                  <label className="toggle-label me-3">
                    <input
                      type="checkbox"
                      checked={toggle}
                      onClick={(e) => {setToggle(!toggle);
                        setReports([]);
                      }
                      }
                    />
                    <span />
                  </label>
                  <span className="">View Consolidate Report</span>
                </div>
              </div>
            </div>
          }
      </div>

      <div className="area-creation-content">
        <form onSubmit={handleSubmit}>
          <div className="util-list-filters d-flex justify-content-between">
            <div className="d-flex">
              <div className="util-tp-filter me-5">
                <p className="mb-3">
                  Select Month <span className="asterisk-imp">*</span>
                </p>
                <Suspense>
                  <DatePickers
                    placeholder="Select Month"
                    setMonth={setMonth}
                    setYear={setYear}
                  />
                </Suspense>
              </div>
            </div>
            <div className="d-flex">
              {!toggle && loggedIn?.user?.des < 121 ? (
                <div className="util-tp-filter me-5">
                  <p className="util-select-user__label">Select User</p>
                  <div className="util-select-user">
                    <MdOutlineGroup className="util-select-user__icon" />
                    <Select
                      styles={selectUserStyles}
                      options={employeeSelect}
                      placeholder="Select User"
                      className="mt-3"
                      value={selectedUser}
                      onChange={(e) => {
                        setSelectedUser({ ...e });
                        setReports([])
                      }}
                    />
                  </div>
                </div>
              ) : null}
              <div className="util-tp-filter">
                <button type="submit" className="button-blue-gradient mt-5">
                  See Reports
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="filter-table">
        {!loading && tableData.length > 0 ? (
          <Table columns={toggle ? columns2 : columns} data={tableData} defaultPageSize={31} fileSrc="Monthly Call Report" month={month} empl={selectedUser ? employeeDataForExcel : loggedIn?.user} />
        ) :
        (loading) ?
        (
          <h3 className="mt-2">Loading ......</h3>
        ) : (
          <h3 className="mt-2 no-results-message">No HQ's results found</h3>
        )}
      </div>
    </main>
  );
};

export default UtilitiesMonthlyReports;
