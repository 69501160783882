import React, {
    useState,
    useMemo,
    useEffect,
    useCallback,
    useRef,
    lazy,
    Suspense,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import customToast from "../../../../components/CustomToast";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import { MdDelete, MdCancel } from "react-icons/md";
import { AiFillSave } from "react-icons/ai";
import Table from "../../../../components/Table";
import { adminStyles, dcrStyles, } from "../../../public/stylesheets/selectStyles";
import { RiEdit2Fill } from "react-icons/ri";
import { clearPrimarySalesError } from "../../../../reducers/Sales/primarySales";
import { addProductSaleReport, ViewLinkedCD, ViewLinkedDP, ViewLinkedSC } from "../../../../services/targets";
import PageTitle from "../../../../components/PageTitle";
import { items } from "../../../../utils/helper";
import moment from "moment-timezone";
// import CascadingMenu from "../../CascadingMenu";
const CascadingMenu = lazy(() => import('../../CascadingMenu'));
const DatePickers = lazy(() => import("../../../../components/DatePicker"))

const ChemistSales = () => {
    const dispatch = useDispatch();
    const { error } = useSelector(({ primarySales }) => primarySales);

    const [finalArray, setFinalArray] = useState([]); //this is the array that will get submitted
    const [editRow, setEditRow] = useState("");
    const [editing, setEditing] = useState(false);
    const upQuantityRef = useRef(0);
    const upCustomPriceRef = useRef(0);
    const upFreeStocksRef = useRef(0);
    const upDiscountRef = useRef(0);
    const upPriceTypeRef = useRef("pts");

    const [docs, setDocs] = useState([]);
    const [chemists, setChemists] = useState([]);
    const [stockists, setStockists] = useState([]);
    const [selectedChemists, setSelectedChemists] = useState("");
    const [selectedStockist, setSelectedStockist] = useState("");
    const [selectedDoctor, setSelectedDoctor] = useState("");
    const [selectedProduct, setSelectedProduct] = useState("");
    const [prod, setProd] = useState([]);
    const [chemDocList, setChemDocList] = useState([]);
    const [docProdList, setDocProdList] = useState([]);
    const [month, setMonth] = useState(moment().month());
    const [year, setYear] = useState(moment().year());

    const handleMenuClick = ({ keyPath }) => {
        // console.log(keyPath)
        const [monthYearString] = keyPath;
        const [month, year] = monthYearString.split('-');
        setMonth(`${month}`);
        setYear(`${year}`);
      };

    useEffect(() => {
        const chemistData = stockists
            .filter((item) => item._id === selectedStockist?._id)
            .reduce((acc, item) => acc.concat(item.chemistArr), [])
        setChemists(chemistData)

        const doctorData = chemDocList
            .filter((item) => item.chemistId === selectedChemists?._id)
            .reduce((acc, item) => acc.concat(item.docArr), [])
        setDocs(doctorData)
        // console.log(doctorData)

        const productData = docProdList
            .filter((item) => item.docId === selectedDoctor?._id)
            .reduce((acc, item) => acc.concat(item.prodArr), [])
        setProd(productData)

    }, [selectedStockist, selectedChemists, selectedDoctor, selectedProduct]);

    useEffect(() => {
        ViewLinkedSC().then((response) => {
            setStockists(response.data)
        });
        ViewLinkedCD().then((response) => {
            setChemDocList(response.data);
        });
        ViewLinkedDP().then((response) => {
            setDocProdList(response.data);
        });
    }, []);

    const doctorSelect = useMemo(() => generateSelectData(docs, "name"), [docs]);
    const chemistSelect = useMemo(() => generateSelectData(chemists, "name"), [chemists]);
    const stockistSelect = useMemo(() => generateSelectData(stockists, "name"), [stockists]);
    const productsSelect = useMemo(() => generateSelectData(prod, "name"), [prod]);

    const resetForm = () => {
        setMonth("")
        setYear("")
        setSelectedProduct("")
        setSelectedChemists("")
        setSelectedDoctor("")
        setSelectedStockist("")
        setFinalArray([]);
        setEditing(false);
        setEditRow("");
    };

    const deleteHandler = (product) => {
        setEditRow("");
        setEditing(false);
        let sr = 0;
        const newfinalArray = finalArray.filter((ele, idx) => {
            if (ele?.product?._id !== product?._id) {
                sr += 1;
                ele.sr = sr;
                return true;
            } else return false;
        });
        setFinalArray(newfinalArray);
    };
    const handleEditRow = (original) => {
        if (editing) {
            customToast.error("Please save the changes you just made");
            return;
        }
        setEditing(true);
        setEditRow(original?.sr);
        upQuantityRef.current = Number(original?.quantity);
        upFreeStocksRef.current = Number(original?.freeStocks);
        upDiscountRef.current = Number(original?.discount);
        upCustomPriceRef.current = Number(original?.price);
        upPriceTypeRef.current = original?.priceType;
    };
    const submitEditedRow = (sr) => {
        const editedObj = finalArray[sr - 1];
        // console.log(editedObj)
        editedObj.quantity = Number(upQuantityRef.current);
        editedObj.price = Number(upCustomPriceRef.current);
        editedObj.priceType = upPriceTypeRef.current;

        const total = Number(
            Number(editedObj.quantity) * Number(editedObj.price)
        );
        const dis = Number(editedObj.discount);
        editedObj.finalPrice = Number(total.toFixed(2));
        editedObj.revenueShare = editedObj.product.isFixed ?
        Number((editedObj.quantity * editedObj.product.fixedAmount).toFixed(2)):
        Number((total * (editedObj.product.percentage / 100)).toFixed(2))

        setEditing(false);
        setEditRow("");
    };
    const cancelHandler = (sr) => {
        setEditing(false);
        setEditRow("");
    };

    useEffect(() => {
        if (error) {
            dispatch(clearPrimarySalesError());
            return customToast.error(error);
        }
    }, [error]);

    const chemistSalesSubmit = useCallback(
        (e) => {
            e.preventDefault();
            if (editing) {
                customToast.error("Please save the changes you just made");
                return;
            }
            if (!year || !selectedStockist || !selectedChemists || !selectedDoctor)
                return customToast.error("Please fill the required fields");
            // secondaryQty = primaryQty + openingBalance - (salesReturn + closingQty);
            const payload = {
                month,
                year,
                stockist: selectedStockist?.stockistId,
                chemist: selectedChemists,
                doctor: selectedDoctor,
                products: finalArray,
            };
            // console.log(payload)
            try {
                addProductSaleReport(payload)
                resetForm();
                return customToast.success("Sale added successfully")
            }
            catch (err) {
                return customToast.error("Sorry ! We encountered an error")
            }
        },
        [month, year, selectedStockist, selectedChemists, selectedDoctor, finalArray, dispatch, editing]
    );
    const priceAccToHeader = (original, selection) => {

        upPriceTypeRef.current = selection;
        const priceAccToHead = original?.product[selection] || 0;
        upCustomPriceRef.current = priceAccToHead;
        return priceAccToHead;
    }
   
    const handleProductAdd = () => {
        if (!selectedProduct) {
            setSelectedProduct("");
            customToast.error("Please select a product");
            return;
        }
        if (editing) {
            customToast.error("Please save the changes before adding new product");
            setSelectedProduct("");
            return;
        }
        const temp = finalArray.filter(
            (ele) => ele.product?._id === selectedProduct?._id
        );
        if (temp.length > 0) {
            customToast.error(`Product already present in row "${temp[0].sr}"`);
            setSelectedProduct("");
            return;
        }
        const nextsr = finalArray.length + 1;
        const newObj = {
            sr: nextsr,
            product: selectedProduct,
            price: selectedProduct?.pts || 0,
            quantity: 0,
            priceType: "pts",
            finalPrice: 0,
            revenueShare: 0,
        };
        setSelectedProduct("");
        setFinalArray([...finalArray, newObj]);
    };

    const columns = useMemo(
        () => [
            // {
            //   Header: "Sr. No",
            //   accessor: "sr",
            //   minWidth: 50,
            //   disableFilters: true,
            //   maxWidth: 50,
            // },
            {
                Header: "Products",
                accessor: "prodArr",
                disableFilters: true,
                disableSortBy: true,
                minWidth: 160,
                maxWidth: 160,
                showTotal: true,
                placeholderTotal: "Total",
                Cell: (props) => {
                    //   console.log(props?.row?.original);
                    return (
                    props?.row?.original?.product?.isFixed ?
                        <span key={props?.row?.original?.product._id}>
                            {props?.row?.original?.product.name} [{props?.row?.original?.product.fixedAmount}] <br />
                        </span>
                    :    <span key={props?.row?.original?.product._id}>
                        {props?.row?.original?.product.name} [{props?.row?.original?.product.percentage}%] <br />
                </span> 
                    );
                },
            },
            {
                Header: "Price",
                accessor: "product.mrp",
                disableFilters: true,
                disableSortBy: true,
                showTotal: true,
                totalAccessor: "price",
                Cell: (props) => {
                    // console.log(props?.row?.original)
                    const editable = Number(props?.row?.original?.sr) === Number(editRow);
                    const [pr, setPr] = useState(props?.row?.original?.price);
                    // useEffect(()=>{upCustomPriceRef.current = pr} , [pr]);
                    return (editable) ? (
                        <div className="d-flex gap-2 align-items-center justify-center">
                            <div className="d-flex gap-1 justify-center flex-column">
                                <button type='button'
                                    onClick={e => setPr(priceAccToHeader(props?.row?.original, "ptr"))}
                                    className={`primary-sales-button ${upPriceTypeRef.current === 'ptr' && "primary-sales-button-bg"}`}
                                >PTR</button>
                                <button type='button'
                                    onClick={e => setPr(priceAccToHeader(props?.row?.original, "pts"))}
                                    className={`primary-sales-button ${upPriceTypeRef.current === 'pts' && "primary-sales-button-bg"}`}
                                >PTS</button>
                                <button type='button'
                                    onClick={e => setPr(priceAccToHeader(props?.row?.original, "mrp"))}
                                    className={`primary-sales-button ${upPriceTypeRef.current === 'mrp' && "primary-sales-button-bg"}`}
                                >MRP</button>
                                <button type='button'
                                    onClick={e => setPr(priceAccToHeader(props?.row?.original, "cus"))}
                                    className={`primary-sales-button ${upPriceTypeRef.current === 'cus' && "primary-sales-button-bg"}`}
                                >Cus..</button>
                            </div>
                            <input
                                defaultValue={props?.row?.original?.price}
                                onChange={(e) => {
                                    upCustomPriceRef.current = e.target.value;
                                    upPriceTypeRef.current = 'cus'
                                    setPr(e.target.value)
                                }}
                                value={pr}
                                type="number"
                                className="sales-table__input h-25"
                            />
                        </div>
                    ) : (
                        <span>{props?.row?.original?.price}</span>
                    );
                },
            },
            {
                Header: "Quantity",
                accessor: "quantity",
                disableFilters: true,
                disableSortBy: true,
                minWidth: 100,
                maxWidth: 100,
                showTotal: true,
                totalAccessor: "quantity",
                Cell: (props) => {
                    const editable = Number(props?.row?.original?.sr) === Number(editRow);
                    return editable ? (
                        <input
                            defaultValue={props?.row?.original?.quantity}
                            onChange={(e) => {
                                upQuantityRef.current = e.target.value;
                            }}
                            type="number"
                            className="sales-table__input h-25"
                        />
                    ) : (
                        <span>{props?.row?.original?.quantity}</span>
                    );
                },
            },
            {
                Header: "Final Price",
                accessor: "finalPrice",
                disableFilters: true,
                disableSortBy: true,
                minWidth: 80,
                maxWidth: 90,
                showTotal: true,
                totalAccessor: "finalPrice"
            },
            {
                Header: "Revenue Share",
                accessor: "revenueShare",
                disableFilters: true,
                disableSortBy: true,
                minWidth: 80,
                maxWidth: 90,
                showTotal: true,
                totalAccessor: "revenueShare",
            },
            {
                Header: "Actions",
                accessor: "action",
                disableFilters: true,
                disableSortBy: true,
                minWidth: 80,
                maxWidth: 90,
                Cell: (props) => {
                    return editing && Number(props?.row?.original?.sr) === editRow ? (
                        <React.Fragment>
                            <AiFillSave
                                onClick={(e) => {
                                    submitEditedRow(props?.row?.original?.sr);
                                }}
                                type="button"
                                className="icon-color-green"
                            />
                            <MdDelete
                                onClick={() => {
                                    deleteHandler(props?.row?.original?.product);
                                }}
                                type="button"
                                className="button-delete__icon"
                            />
                            <MdCancel
                                type="button"
                                className="icon-color-yellow"
                                onClick={() => {
                                    cancelHandler(props?.row?.original?.sr);
                                }}
                            />
                        </React.Fragment>
                    ) : (
                        <span
                            className="react-table-view-link"
                            onClick={() => {
                                handleEditRow(props?.row?.original);
                            }}
                        >
                            <RiEdit2Fill className="icon-color-green" />
                        </span>
                    );
                },
            },
        ],
        [finalArray, editing]
    );

    return (
        <main className="main-content admin-content">
            <div className="area-creation-content">
                <PageTitle title="Chemist Sales" isCRM={true} />

                <section className="area-creation-content__info">
                    <form onSubmit={chemistSalesSubmit}>
                        <div className="row">

                            <div className="col-sm-12 col-md-4 col-lg-3">
                                <label htmlFor="stockist">Select Month</label>
                                <br />
                                <Suspense>
                                    <DatePickers
                                        placeholder="Select Month"
                                        setMonth={setMonth}
                                        setYear={setYear}
                                    />
                                </Suspense>
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-3">
                                <label htmlFor="stockist">Select Stockist</label>
                                <br />
                                <Select
                                    name="stockist"
                                    id="stockist"
                                    value={selectedStockist}
                                    options={stockistSelect}
                                    onChange={(e) => setSelectedStockist({ ...e })}
                                    styles={adminStyles}
                                    placeholder="Select stockist"
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-3">
                                <label htmlFor="chemists">Select chemist</label>
                                <br />
                                <Select
                                    //   isMulti
                                    name="chemists"
                                    id="chemists"
                                    value={selectedChemists}
                                    options={chemistSelect}
                                    onChange={(e) => setSelectedChemists({ ...e })}
                                    styles={adminStyles}
                                    placeholder="Select Chemist"
                                    className=""
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-3">
                                <label htmlFor="chemists">Select doctor</label>
                                <br />
                                <Select
                                    //   isMulti
                                    name="doctor"
                                    id="doctor"
                                    value={selectedDoctor}
                                    options={doctorSelect}
                                    onChange={(e) => setSelectedDoctor({ ...e })}
                                    styles={adminStyles}
                                    placeholder="Select Doctor"
                                    className=""
                                />
                            </div>

                        </div>
                        <div className="row  align-items-end">
                            <div className="col-sm-12 col-md-4 col-lg-3 mt-2">
                                <label htmlFor="chemists">Select product</label>
                                <br />
                                <Select
                                    //   isMulti
                                    name="product"
                                    id="product"
                                    value={selectedProduct}
                                    options={productsSelect}
                                    onChange={(e) => setSelectedProduct({ ...e })}
                                    styles={adminStyles}
                                    placeholder="Select Product"
                                    className=""
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-3">
                                <button
                                    type="button"
                                    onClick={handleProductAdd}
                                    className="button-blue-gradient"
                                >
                                    Add Product
                                </button>
                            </div>
                        </div>
                        <div className="filter-table" >
                            {finalArray?.length > 0 && (
                                <h2 className="web-app__heading mb-4 ms-2">
                                    Showing ({finalArray?.length}) Entries
                                </h2>
                            )}
                            <Table columns={columns} data={finalArray} />
                        </div>
                        <button type="submit" className="button-submit">
                            Submit Report
                        </button>
                    </form>
                </section>
            </div>
        </main>
    );
};

export default ChemistSales;
