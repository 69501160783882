import { getSettingPreference, toggleSetting, updateViewAllHqsToggle } from "../services/settings";
import { fetchCompanyName } from "./companyName";

const initialState = {
  "hq-target": null,
  "min-stock": null,
  "lock-day-preference": null,
  announcement: null,
  appUpdateRequest: null,
  "minCall-reports-lock": null,
  "missed-doc-filter": null,
  companyName: null,
  "tp-deadline": null,
  secondary: null,
  tourlimit: null,
  sundayWorking: null,
  expenseApproval: null,
  viewAllHqs: null,
  restrictUserLock: null,
  "detailed-analysis-report-preference": null,
  "custom-cost-preference": null,
};
//if min-stock is not true then do not dispatch inventoryAlerts
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_HQ":
      return { ...state, "hq-target": action.payload.data };
    case "TOGGLE_STOCK":
      return { ...state, "min-stock": action.payload.data };
    case "TOGGLE_LOCK_DAY_PREFERENCE":
      return { ...state, "lock-day-preference": action.payload.data };
    case "TOGGLE_ANNOUNCEMENT":
      return { ...state, announcement: action.payload.data };
    case "TOGGLE_COMPANY_NAME":
      return { ...state, companyName: action.payload.data };
    case "TOGGLE_APP_UPDATE_REQUEST":
      return { ...state, appUpdateRequest: action.payload.data };
    case "TOGGLE_MIN_CALL_REPORT":
      return { ...state, "minCall-reports-lock": action.payload.data };
    case "TOGGLE_MISSED_DOC_FILTER":
      return { ...state, "missed-doc-filter": action.payload };
    case "TOGGLE_TP_DEADLINE":
      return { ...state, "tp-deadline": action.payload };
    case "TOGGLE_SECONDARY":
      return { ...state, secondary: action.payload };
    case "TOGGLE_TOUR_LIMIT":
      return { ...state, tourlimit: action.payload };
    case "TOGGLE_SUNDAY_WORKING":
      return { ...state, sundayWorking: action.payload };
    case "TOGGLE_EXPENSE_APPROVAL":
      return { ...state, expenseApproval: action.payload };
    case "TOGGLE_VIEWALL_HQS":
      return { ...state, "viewAll-hqs": action.payload };
    case "TOGGLE_RESTRICT_USER_LOCK":
      return { ...state, restrictUserLock: action.payload };
    case "TOGGLE_DETAILED_ANALYSIS_REPORT_PREFERENCE":
      return {
        ...state,
        "detailed-analysis-report-preference": action.payload,
      };
    case "TOGGLE_CUSTOM_COST_PREFERENCE":
      return { ...state, "custom-cost-preference": action.payload};  
    case "TOGGLE_CALL_RATING":
      return { ...state, "callRating": action.payload} ;  
    case "GET_SETTING":
      return action.payload;
    default:
      return state;
  }
};

export const getPreference = (type, value) => {
  return async (dispatch) => {
    try{
      const data = await getSettingPreference(type, value);
      dispatch({
        type: "GET_SETTING",
        payload: data.data,
      });
    }catch(err){
      
    }
  };
};

export const toggleHqPreference = (type, value) => {
  return async (dispatch) => {
    const {data }= await toggleSetting(type, value);
    dispatch({
      type: "TOGGLE_HQ",
      payload: {data: data},
    });
    // dispatch(getPreference("hq-target"));
  };
};

export const toggleStockPreference = (type, value) => {
  return async (dispatch) => {
    const {data} = await toggleSetting(type, value);
    dispatch({
      type: "TOGGLE_STOCK",
      payload: {data: data},
    });

  };
};
export const toggleLockDayPreference = (type, value) => {
  return async (dispatch) => {
    const {data} = await toggleSetting(type, value);
    dispatch({
      type: "TOGGLE_LOCK_DAY_PREFERENCE",
      payload: {data:data}
    })
    // dispatch(getPreference("hq-target"));
  }
}
export const toggleAnnouncements = (type, value) => {
  return async (dispatch) => {
      try {
          const {data} = await toggleSetting(type, value);
          dispatch({
              type: 'TOGGLE_ANNOUNCEMENT',
              payload: {data : data.announcement},
          });
      } catch (error) {
          console.error('Failed to toggle announcement', error);
      }
  };
}
export const toggleCompanyName = (type, value) => {
  return async (dispatch) => {
      try {
          const {data} = await toggleSetting(type, value);
          dispatch({
              type: 'TOGGLE_COMPANY_NAME',
              payload: {data : data.companyName},
          });
          dispatch(fetchCompanyName())
      } catch (error) {
          console.error('Failed to toggle company name', error);
      }
  };
}

export const toggleAppUpdateRequest = (type, value) => {
  return async (dispatch) => {
    const {data} = await toggleSetting(type, value);
    dispatch({
      type: "TOGGLE_APP_UPDATE_REQUEST",
      payload: {data: data},
    });
  };
};

export const toggleMinCallReportLock = (type,value) =>{
  return async (dispatch) =>{
    const {data} = await toggleSetting(type,value);
    dispatch({
      type:"TOGGLE_MIN_CALL_REPORT",
      payload: {data: data}
    })
  }
}

export const toggleMissedDocFilter = (type,value) =>{
  return async (dispatch) =>{
    const {data} = await toggleSetting(type,value);
    dispatch({
      type:"TOGGLE_MISSED_DOC_FILTER",
      payload:data
    })
  }
}

export const toggleTpDeadline= (type,value) =>{
  return async (dispatch) =>{
    const {data} = await toggleSetting(type,value);
    dispatch({
      type:"TOGGLE_TP_DEADLINE",
      payload:data
    })
  }
}
export const toggleSecondary= (type,value) =>{
  return async (dispatch) =>{
    const {data} = await toggleSetting(type,value);
    dispatch({
      type:"TOGGLE_SECONDARY",
      payload:data
    })
  }
}
export const toggleSundayWorking = (type,value) =>{
  return async (dispatch) =>{
    const {data} = await toggleSetting(type,value);
    dispatch({
      type:"TOGGLE_SUNDAY_WORKING",
      payload:data
    })
  }
}
export const togglEexpenseApproval = (type,value) =>{
  return async (dispatch) =>{
    const {data} = await toggleSetting(type,value);
    dispatch({
      type:"TOGGLE_EXPENSE_APPROVAL",
      payload:data
    })
  }
}
export const toggleTourLimit= (type,value) =>{
  return async (dispatch) =>{
    const {data} = await toggleSetting(type,value);
    dispatch({
      type:"TOGGLE_TOUR_LIMIT",
      payload:data
    })
  }
}
export const toggleViewAllHqs = (type,value) => {
  return async (dispatch) => {
    const {data} = await toggleSetting(type,value);

    dispatch({
      type:"TOGGLE_VIEWALL_HQS",
      payload:data
    })
  }
}
export const toggleRestrictUserLock = (type,value) => {
  return async (dispatch) => {
    const {data} = await toggleSetting(type,value);

    dispatch({
      type:"TOGGLE_RESTRICT_USER_LOCK",
      payload:data
    })
  }
}
export const toggleProfitAnalysisReportSetting = (type,value) => {
  return async (dispatch) => {
    const {data} = await toggleSetting(type,value);

    dispatch({
      type:"TOGGLE_DETAILED_ANALYSIS_REPORT_PREFERENCE",
      payload:data
    })
  }
}
export const toggleCustomCostSetting = (type,value) => {
  return async (dispatch) => {
    const {data} = await toggleSetting(type,value);

    dispatch({
      type:"TOGGLE_CUSTOM_COST_PREFERENCE",
      payload:data
    })
  }
}
export const toggleCallRating = (type,value) => {
  return async (dispatch) => {
    const {data} = await toggleSetting(type,value);

    dispatch({
      type:"TOGGLE_CALL_RATING",
      payload:data
    })
  }
}
export default reducer;
