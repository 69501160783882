import { useCallback, useEffect, useMemo, useState, useRef, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import customToast from "../../../../components/CustomToast";
import ProfitCenterProductBox from "../../../../components/ProfitCenterProductBox";
import PageTitle from "../../../../components/PageTitle";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import serialise, { generateSelectData } from "../../../../utils/serialiseResponse";
import {RiCloseCircleLine, RiEdit2Fill} from "react-icons/ri" ;
import { monthOptions, returnMonthFromIndex, yearOptions } from "../../../../utils/helper";
import { MacroFlower } from "../../../../components/CostIcon";
import { AiFillSave } from "react-icons/ai";
import { viewProductsSale, putProductsSale, viewProductsCost } from "../../../../services/profitCenter";
import Table from "../../../../components/Table";
import { MdDelete, MdDeleteOutline } from "react-icons/md";
import moment from "moment-timezone";
const DatePickers = lazy(() => import("../../../../components/DatePicker"))

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    border: "2px solid #36ABF9",
    borderRadius: "5px",
    color: "#9e9ad0",
    fontSize: "1.5rem",
    fontWeight: "500",

    width: "20rem",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",
    fontFamily: "inherit",
    fontSize: "1.5rem",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
};

const ProductsProfit = () => {
  const dispatch = useDispatch();
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [hq, setHq] = useState(null);
  const [expense, setExpense] = useState(0);
  const [editRow, setEditRow] = useState(null);
  const [editing, setEditing] = useState(false);
  const [rowEditing, setRowEditing] = useState(false);
  const [products, setProducts] = useState([]);
  const [priceType, setPriceType] = useState({ value: "pts", label: "PTS" });
  const [productSelected, setProductSelected] = useState(undefined);
  const [productAdded, setProductAdded] = useState([]);
  const [actions, setActions] = useState(false);
  const [billId, setBillId] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [deleteRowProp, setDeleteRowProp] = useState(null);
  const [overRideStatus, setOverRideStatus] = useState(false);
  const [productFetched, setProductFetched] = useState([]);
  const [productWithOutQuantity, setProductWithOutQuantity] = useState(0);
  const [productCost, setProductCost] = useState([]);

  const upQuantityRef = useRef(0) ;

  const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };
  
  const priceOptions = useMemo(()=>[
    { value: "mrp", label: "MRP" },
    { value: "ptr", label: "PTR" },
    { value: "pts", label: "PTS" },
    { value: "cus1", label: "PRICE 1" },
    { value: "cus2", label: "PRICE 2" },
    { value: "cus3", label: "PRICE 3" },
  ],[]);

  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );
  
  const productSelect = useMemo(
    () => generateSelectData(products, "name"),
    [products]
  );

  useEffect(() => {
    dispatch(setHeadquarters());
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch]);

  useEffect(()=>{
    if(!year || !hq )return ;

    viewProductsSale({month:month, year:year, hq:hq?.value, priceType:priceType.value}).then(({data})=>{
      if(data.headquarter){
        const emp = serialise(data.table);
        setActions(true);
        setBillId(data._id);
        setEditing(false);
        setProductAdded(emp);
        setProductFetched(emp);
        // here data comes from custom price database so make sure to add custom cost of products from profit analysis's setting
        viewProductsSale({typ:"prodAll", month:month, year:year, hq:hq?.value, priceType:priceType.value}).then(({data})=>{
          const prodSelect = serialise(data)
          setProducts(prodSelect);
        })
      }else{
        const emp = serialise(data);
        setProducts(emp);
        setEditing(false);
      }
    }).catch((err)=>{
      customToast.error("Sorry Something went wrong!")
    }) ;
  },[month, year, hq, priceType]) ;

  useEffect(()=>{
    let e = 0 ;
    for(const x of productAdded){
      e += x?.total || 0 ;
    }
    setExpense(e);
  },[productAdded]);

  useEffect(() => {
    setProductAdded([]);
    setProductSelected(null);
    setActions(false);
    setProductWithOutQuantity(0);
  }, [month, year, hq, priceType]);

  useEffect(() => {
    viewProductsCost().then(({data})=>{
      setProductCost(data);
    }).catch((err)=>{
      customToast.error("Sorry Something went wrong!")
    }) ;
  }, [])

  const addProductHandler = useCallback((e) => {
    e.preventDefault();
    if(!year || !hq || !productSelected || !priceType)return customToast.error("Please Select Mandatory Fields");
    if (editing)return customToast.error("Sorry can't do this for previous bill");
    let duplicatePresent = false;
    for (let i=0; i<productAdded.length; i++){
      if (productAdded.at(i).name === productSelected.name){
        duplicatePresent = true;
        break;
      }
    }
    if (duplicatePresent){
      return customToast.error("Product already present");
    } else {
      const price = productCost.filter((prod, index) => {
        return prod.prodId === productSelected.prodId;
      })
      const productData = {
        _id: productSelected._id,
        name: productSelected.name,
        prodId: productSelected.prodId,
        price: price[0][priceType.value],
      }
      setProductAdded([...productAdded, productData]);
      setProductWithOutQuantity(cnt => ++cnt);
      setProductSelected(null)
    }
  }, [month, year, hq, priceType, productSelected, productAdded])

  const handleEditRow = useCallback((row)=>{
    if(rowEditing)return customToast.error("Please Save Your Changes");
    upQuantityRef.current = row?.quantity || 0 ;
    
    setEditRow(row?.name);
    setRowEditing(true);

  },[rowEditing]);

  const submitEditedRow = useCallback((prop)=>{
    
    let editedObj = productAdded.find((emp, index) => {
      return emp.name === prop.name;
    })
    editedObj.quantity = Number(upQuantityRef.current);
    editedObj.total = round(Number(editedObj.price) * Number(editedObj.quantity), 2);
    setProductAdded([
      ...productAdded
    ])

    setEditRow(null);
    setRowEditing(false);
    setProductWithOutQuantity(0);

  }, [productAdded]);

  const handleDelete = useCallback(()=>{
    putProductsSale({
      isDeleting:true,
      _id: billId
    }).then((data)=>{
      customToast.success("Bill Deleted Successfully");
    }).catch((err)=>{
      customToast.error("Sorry Something Went Wrong!");
    })
    setProducts([]);
    setHq(null);
  },[billId]);

  const deleteRowFromAddedEmployee = useCallback((prop) => {
    let newAddedEmp = [];
    productAdded.map((emp, index) => {
      if (emp.name !== prop){
        newAddedEmp = [...newAddedEmp, emp];
      }
    });
    setProductAdded(newAddedEmp);
  },[productAdded]);

  const submitBill = useCallback((e)=>{
    e.preventDefault();
    if(rowEditing)return customToast.info("Please Save Your Changes!");
    if (productWithOutQuantity > 0)return customToast.info("Please Add Quantity to Your Products!");
    if (actions){
      putProductsSale({
        month:month,
        year:year,
        hq:hq?.value,
        payload:productAdded, 
        priceType:priceType.value,
        _id: billId,
      }).then((data)=>{
        customToast.success("Bill Saved Successfully");
      }).catch((err)=>{
        customToast.error("Sorry Something Went Wrong!");
      })
    } else {
      putProductsSale({
        month:month,
        year:year,
        hq:hq?.value,
        payload:productAdded, 
        priceType:priceType.value,
      }).then((data)=>{
        customToast.success("Bill Saved Successfully");
      }).catch((err)=>{
        customToast.error("Sorry Something Went Wrong!");
      })
    }
    setProducts([]);
    setOverRideStatus(false);
    setHq(null);
  },[productAdded,hq,month,year,editing,rowEditing,priceType]);

  const columns = useMemo(
    () => [
      {
        Header: "Product",
        accessor: "name",
        disableSortBy: true,
        disableFilters:false,
      },
      { 
        Header: "Price",
        accessor: "price",
        disableSortBy: true,
        disableFilters:true,
        minWidth:90,
        maxWidth:90,
        showTotal:true,
        placeholderTotal:"Total"
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        totalAccessor:"quantity",
        Cell: (props) => {
          const editable = props?.row?.original?.name === editRow;
          return editable ? (
            <input
              defaultValue={props?.row?.original?.quantity}
              onChange={(e) => {
                upQuantityRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.quantity}</span>
          );
        },
      },
      { 
        Header: "Total Amount",
        accessor: "total",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        totalAccessor:"total",
        Cell: (props) => {
          const editable = props?.row?.original?.name === editRow;
          return (
            <span> {props?.row?.original?.total ? "₹ "+props?.row?.original?.total : null}</span>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableSortBy: true,
        disableFilters:true,
        minWidth: 120,
        maxWidth: 120,
        Cell: (props) => {
          const editable = props?.row?.original?.name === editRow;
          return editable ? (
            <div className="d-flex w-100 justify-content-center">
              <span className="react-table-view-link" >
                <AiFillSave type="button" className="icon-color-green mx-3" size={24} onClick={(e) => {
                  e.preventDefault();
                  submitEditedRow(props?.row?.original);
                  setProductWithOutQuantity(cnt => --cnt);
                  let status = productFetched.find((prod) => {
                    return prod?.name === props?.row?.original?.name;
                  })
                  if (status) setOverRideStatus(true);
                }}/>
              </span>
            </div>
          ) : (
            <div className="d-flex w-100 justify-content-center">
              <span className="react-table-view-link" >
                <RiEdit2Fill type="button" className="icon-color-green mx-3" size={25} onClick={(e) => {
                  e.preventDefault();
                  handleEditRow(props?.row?.original);
                }}/>
              </span>
              <span className="react-table-view-link mx-3" >
                <MdDelete type="button" className="button-delete__icon" size={25} onClick={(e) => {
                  e.preventDefault();
                  setDeleteRowProp(props?.row?.original?.name);
                  setShowDelete(true);
                }} />
              </span>
            </div>
          );
        }
      }
    ],
    [editRow, handleEditRow, submitEditedRow]
  );

  const EditPopup = ({ type }) => {
    if (deleteRowProp === "ALL"){
      return (
        <section className="edit-popup" style={{ zIndex: 100 }}>
          <div className="edit-content">
  
            <div className="edit-popup__heading">
              <h2 className="web-app__heading">Delete All</h2>
              <RiCloseCircleLine
                className="edit-popup__close"
                onClick={() => setShowDelete(false)}
              />
            </div>
  
            <div className="mt-4">
              <p>Are you sure you want to delete all the entries for the month of {month.label}? </p>
            </div>
  
            <button
              type="submit"
              className="button-delete mt-4"
              onClick={() => {
                handleDelete();
                setShowDelete(false)
              }}
            >
              <span className="button-delete__icon-container">
                <MdDeleteOutline className="button-delete__icon" />
              </span>
              <span className="button-delete__text">Delete</span>
            </button>
            
          </div>
        </section>
      );
    }
    return (
      <section className="edit-popup" style={{ zIndex: 100 }}>
        <div className="edit-content">

          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowDelete(false)}
            />
          </div>

          <div className="mt-4">
            <p>Are you sure you want to delete the entry of {deleteRowProp} from the month of {month.label}? </p>
          </div>

          <button
            type="submit"
            className="button-delete mt-4"
            onClick={() => {
              deleteRowFromAddedEmployee(deleteRowProp)
              setShowDelete(false)
            }}
          >
            <span className="button-delete__icon-container">
              <MdDeleteOutline className="button-delete__icon" />
            </span>
            <span className="button-delete__text">Delete</span>
          </button>
          
        </div>
      </section>
    );
  };
  
  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">

        {
          showDelete ?
          <EditPopup /> :
          null
        }

        <PageTitle title="Products" previousUrl="/profit-analysis" isDelete={actions ? true : false} toggle={() => {
          setDeleteRowProp("ALL");
          setShowDelete(true);
        }}/>
        <section className="area-creation-content__form">
          <form >
            <div className="d-flex flex-wrap justify-content-between">
              <div className=" d-flex flex-column gap-5 h-100">
                <div className="d-flex flex-wrap gap-1 justify-content-between" style={{
                  width: '65rem'
                }}>
                  <div className="util-tp-filter">
                    <p className="mb-3">
                      Select Month <span className="asterisk-imp">*</span>
                    </p>
                    <Suspense>
                      <DatePickers
                        placeholder="Select Month"
                        setMonth={setMonth}
                        setYear={setYear}
                      />
                    </Suspense>
                  </div>
                  <div className="util-tp-filter">
                    <p>
                      Select Headquarter <span className="asterisk-imp">*</span>
                    </p>
                    <Select
                      styles={selectStyles}
                      placeholder="Select HQ"
                      className="mt-3"
                      options={headquarterSelect}
                      value={hq}
                      onChange={(e) => setHq({ ...e })}
                    />
                  </div>
                </div>
                <div className="d-flex flex-wrap gap-1 justify-content-between align-items-end" style={{
                  width: '65rem'
                }}>
                  <div className="util-tp-filter">
                    <p>
                      Select Price <span className="asterisk-imp">*</span>
                    </p>
                    <Select
                      styles={selectStyles}
                      placeholder="Select Price"
                      className="mt-3"
                      options={priceOptions}
                      value={priceType}
                      // isDisabled
                      onChange={(e) => setPriceType({ ...e })}
                    />
                  </div>
                  <div className="util-tp-filter">
                    <p>
                      Select Product <span className="asterisk-imp">*</span>
                    </p>
                    <Select
                      styles={selectStyles}
                      placeholder="Select Price"
                      className="mt-3"
                      options={productSelect}
                      value={productSelected}
                      onChange={(e) => 
                        setProductSelected({ ...e })
                      }
                    />
                  </div>
                  <div className="util-tp-filter">
                    <button type="submit" className="button-blue-gradient" style={{width: "20rem"}} onClick={addProductHandler}>
                      Add Product
                    </button>
                  </div>
                </div>
              </div>
              <ProfitCenterProductBox row1={`${productAdded.length} Products`} 
                row2={`Period: ${returnMonthFromIndex(month)} ${year} ${new Date(year, Number(month)+1, 0).getDate() ? " | "+new Date(year, Number(month)+1, 0).getDate()+" Days" : ""}`}
                row3={`Rs ${round(Number(expense), 2)}`} row4="TOTAL VALUE"
              />
            </div>
          </form>
        </section>
      </div>
      <div className="filter-table pe-4">
        <Table columns={columns} data={productAdded} />
      <button type="button" className="button-submit" onClick={submitBill}>
        {overRideStatus ? "Over Ride" : "Submit" }
      </button>
      </div>
    </main>
  );
};

export default ProductsProfit;
