import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseUrl = `${apiURL}/client/profit-center/`;
const baseUrlV1 = `${apiURL}/client/v1/profit-center/`;

export const viewEmployees = async (filter) => {
  const {data} = await axios.get(baseUrlV1 + "employee", {
    params:filter,
    headers:setAuth()
  })

  return data ;
}
export const putEmployeesCost = async (payload) => {
  const {data} = await axios.post(baseUrlV1 + "employee", payload,{
    headers:setAuth()
  } )
  return data ;
}
export const viewDoctorsCost = async (filter) => {
  const {data} = await axios.get(baseUrlV1 + "doctors", {
    params:filter,
    headers:setAuth()
  })

  return data ;
}
export const putDoctorsCost = async (payload) => {
  const {data} = await axios.post(baseUrlV1 + "doctors", payload,{
    headers:setAuth()
  })
  return data ;
}
export const viewChemistCost = async (filter) => {
  const {data} = await axios.get(baseUrlV1 + "chemist", {
    params:filter,
    headers:setAuth()
  })

  return data ;
}
export const putChemistCost = async (payload) => {
  const {data} = await axios.post(baseUrlV1 + "chemist", payload,{
    headers:setAuth()
  } )
  return data ;
}
export const viewStockistCost = async (filter) => {
  const {data} = await axios.get(baseUrlV1 + "stockist", {
    params:filter,
    headers:setAuth()
  })

  return data ;
}
export const putMiscellaneousCost = async (payload) => {
  const {data} = await axios.post(baseUrl + "miscellaneous", payload,{
    headers:setAuth()
  } )
  return data ;
}
export const viewMiscellaneousCost = async (filter) => {
  const {data} = await axios.get(baseUrlV1 + "miscellaneous", {
    params:filter,
    headers:setAuth()
  })
  return data ;
}
export const putStockistCost = async (payload) => {
  const {data} = await axios.post(baseUrlV1 + "stockist", payload,{
    headers:setAuth()
  } )
  return data ;
}
export const viewTotalCost = async (filter) => {
  const {data} = await axios.get(baseUrlV1 + "total", {
    params:filter,
    headers:setAuth()
  })

  return data ;
}
export const viewProductsCost = async (filter) => {
  const {data} = await axios.get(baseUrlV1 + "products", {
    params:filter,
    headers:setAuth()
  })

  return data ;
}
export const putProductsCost = async (payload) => {
  const {data} = await axios.post(baseUrlV1 + "products", payload,{
    headers:setAuth()
  } )
  return data ;
}
export const viewProductsSale = async (filter) => {
  const {data} = await axios.get(baseUrlV1 + "products-sale", {
    params:filter,
    headers:setAuth()
  })

  return data ;
}
export const putProductsSale = async (payload) => {
  const {data} = await axios.post(baseUrlV1 + "products-sale", payload,{
    headers:setAuth()
  } )
  return data ;
}
export const viewROI = async (filter) => {
  const {data} = await axios.get(baseUrlV1 + "roi", {
    params:filter,
    headers:setAuth()
  })

  return data ;
}