
import { useLocation, useNavigate } from "react-router-dom";

import { RiCapsuleFill, RiGiftFill, RiNurseFill } from "react-icons/ri";
import { MdArrowBackIosNew } from "react-icons/md";
import { formatDate, monthOptions } from "../../../../../utils/helper";
import { Accordion } from "react-bootstrap";
import { FaHandshake } from "react-icons/fa";
import { FaHandshakeAltSlash } from "react-icons/fa";

const CallPlanningDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="utility-inner-tp">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Call Planning Details
          </span>
        </h2>

        <div className="util-inner-details">
          <div className="util-inner-info-box-container">
            <div className="util-inner-info-box">
              <h3>Date</h3>
              <p>{formatDate(location?.state?.date)}</p>
            </div>

            <div className="util-inner-info-box">
              <h3>Month</h3>
              <p>
                {
                  monthOptions[new Date(`${location?.state?.date}`).getMonth()]
                    ?.label
                }
              </p>
            </div>
            <div className="util-inner-info-box">
              <h3>Employee Name</h3>
              <p>
                {location?.state?.submitter.firstName} {location?.state?.submitter.lastName}
              </p>
            </div>
            <div className="util-inner-info-box">
              <h3>Approved By</h3>
              <p>{location?.state?.authorizedBy ? location?.state?.authorizedBy : "NA"}</p>
            </div>

          </div>
        </div>

        <div className="tp-tags d-flex mt-5 gap-md-3 gap-lg-5">
          <h4>
            <span className="tp-indicator-9 me-2"></span>Met
          </h4>
          <h4>
            <span className="tp-indicator-5 me-2"></span>Yet to meet
          </h4>
        </div>

        <div className="dcs-call-wrapper">
          <div className="w-[22rem]">
            <div className="doctor-call-div">
              <h2 className="web-app__heading mt-5">
                {location?.state?.doctors?.length > 0 ? "Planned Doctor" : null}
              </h2>

              <Accordion className="mt-4" alwaysOpen>
                {location?.state?.doctors?.map((e, i) => (
                  (e?.products.length > 0 || e?.gifts.length > 0) ? (
                    <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    {/* <Accordion.Header>
                      {" "}
                      <span className="d-flex align-items-center">
                        {<RiNurseFill className="me-3 fs-2" />} {e?.doctor?.name}
                      </span>
                      {
                        e?.doctor?.isMet ? (
                          <FaHandshake className="icon-color-green"/>
                        ) : (
                          <FaHandshakeAltSlash className="icon-color-yellow"/>
                        )
                      }
                    </Accordion.Header> */}
                    <Accordion.Header>
                        <span className="d-flex align-items-center justify-content-between w-100">
                          <span className="d-flex align-items-center">
                            <RiNurseFill className="me-3 fs-2" /> {e?.doctor?.name}
                          </span>
                          <span className="me-3">
                            {e?.doctor?.isMet ? (
                              <FaHandshake className="icon-color-green" />
                            ) : (
                              <FaHandshakeAltSlash className="icon-color-yellow" />
                            )}
                          </span>
                        </span>
                      </Accordion.Header>
                    <Accordion.Body>
                      <h5 className="web-app__heading-blue">
                        {e?.products?.length > 0 ? "Samples DETAILS" : null}
                      </h5>
                      {e?.products.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.name}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.gifts?.length > 0 ? "Gift Details" : null}
                      </h5>
                      {e?.gifts.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.gift}
                          </div>
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                  ) : (
                    <Accordion.Header key={e._id} className="mb-4 custom-accordion-header">
                    <span className="d-flex align-items-center justify-content-between w-100">
                          <span className="d-flex align-items-center">
                            <RiNurseFill className="me-3 fs-2" /> {e?.doctor?.name}
                          </span>
                          <span>
                            {e?.doctor?.isMet ? (
                              <FaHandshake className="icon-color-green" />
                            ) : (
                              <FaHandshakeAltSlash className="icon-color-yellow" />
                            )}
                          </span>
                        </span>
                  </Accordion.Header>
                  )
                ))}
              </Accordion>
            </div>
            <div className="chemist-call-div">
              <h2 className="web-app__heading report-heading mt-5">
                {location?.state?.chemists?.length > 0 ? "Planned Chemist" : null}
              </h2>
              <Accordion className="mt-4" alwaysOpen>
                {location?.state.chemists.map((e, i) => (
                  (e?.products.length > 0 || e?.gifts.length > 0) ? (
                    <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    <Accordion.Header>
                    <span className="d-flex align-items-center justify-content-between w-100">
                          <span className="d-flex align-items-center">
                            <RiNurseFill className="me-3 fs-2" /> {e?.chemist?.businessName}
                          </span>
                          <span className="me-3">
                            {e?.chemist?.isMet ? (
                              <FaHandshake className="icon-color-green" />
                            ) : (
                              <FaHandshakeAltSlash className="icon-color-yellow" />
                            )}
                          </span>
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <h5 className="web-app__heading-blue">
                        {e?.products.length > 0 ? "Samples DETAILS" : null}
                      </h5>
                      {e?.products.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.name}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.gifts.length > 0 ? "Gift Details" : null}
                      </h5>
                      {e?.gifts.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.gift}
                          </div>
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                  ) : (
                    <Accordion.Header key={e._id} className="mb-4 custom-accordion-header">
                    <span className="d-flex align-items-center justify-content-between w-100">
                          <span className="d-flex align-items-center">
                            <RiNurseFill className="me-3 fs-2" /> {e?.chemist?.businessName}
                          </span>
                          <span>
                            {e?.chemist?.isMet ? (
                              <FaHandshake className="icon-color-green" />
                            ) : (
                              <FaHandshakeAltSlash className="icon-color-yellow" />
                            )}
                          </span>
                        </span>
                  </Accordion.Header>
                  )
                ))}
              </Accordion>
            </div>
            <div className="stockist-call-div">
              <h2 className="web-app__heading mt-5">
                {location?.state?.stockists?.length > 0 ? "Planned Stockist" : null}
              </h2>
              <Accordion className="mt-4" alwaysOpen>
                {location?.state.stockists.map((e, i) => (
                  (e?.products.length > 0 || e?.gifts.length > 0) ? (
                    <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    <Accordion.Header>
                    <span className="d-flex align-items-center justify-content-between w-100">
                          <span className="d-flex align-items-center">
                            <RiNurseFill className="me-3 fs-2" /> {e?.stockist?.businessName}
                          </span>
                          <span className="me-3">
                            {e?.stockist?.isMet ? (
                              <FaHandshake className="icon-color-green" />
                            ) : (
                              <FaHandshakeAltSlash className="icon-color-yellow" />
                            )}
                          </span>
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <h5 className="web-app__heading-blue">
                        {e?.products.length > 0 ? "Samples DETAILS" : null}
                      </h5>
                      {e?.products.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.name}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.gifts.length > 0 ? "Gift Details" : null}
                      </h5>
                      {e?.gifts.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.gift}
                          </div>
                        </div>
                      ))}

                    </Accordion.Body>
                  </Accordion.Item>
                  ): (
                    <Accordion.Header key={e._id} className="mb-4 custom-accordion-header">
                    <span className="d-flex align-items-center justify-content-between w-100">
                          <span className="d-flex align-items-center">
                            <RiNurseFill className="me-3 fs-2" /> {e?.stockist?.businessName}
                          </span>
                          <span>
                            {e?.stockist?.isMet ? (
                              <FaHandshake className="icon-color-green" />
                            ) : (
                              <FaHandshakeAltSlash className="icon-color-yellow" />
                            )}
                          </span>
                        </span>
                  </Accordion.Header>
                  )
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallPlanningDetails;
