// import { useState, useEffect, useMemo, useCallback } from "react";
// import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
// import { Link, useNavigate } from "react-router-dom";
// // import DateRange from "../../../../components/DateRange";
// // import { addDays } from "date-fns";
// import serialise, {
//   generateSelectUser,
// } from "../../../../utils/serialiseResponse.js";
// import { viewUsersForUtilities } from "../../../../services/utilities.js";
// import {
//   formatDate,
//   monthOptions,
//   yearOptions,
// } from "../../../../utils/helper.js";
// import { viewReportsMonthWise } from "../../../../services/reports.js";
// import Select from "react-select";
// import {
//   dcrStyles,
//   selectUserStyles,
// } from "../../../public/stylesheets/selectStyles.js";
// import { useSelector } from "react-redux";
// import Table from "../../../../components/Table/index.jsx";
// import { FaEye } from "react-icons/fa";

// const UtiltiesStockistReports = () => {
//   const navigate = useNavigate();
//   const loggedIn = useSelector(({ user }) => user);

//   const [employees, setEmployees] = useState([]);
//   const [selectedUser, setSelectedUser] = useState();
//   let [dcrReports, setDcrReports] = useState([]);
//   const [month, setMonth] = useState();
//   const [year, setYear] = useState();

//   const employeeSelect = useMemo(
//     () => generateSelectUser(employees, "fullName"),
//     [employees]
//   );

//   const columns = useMemo(
//     () => [
//       {
//         Header: "Sr. No",
//         accessor: "sr",
//         minWidth: 40,
//         maxWidth: 40,
//       },
//       {
//         Header: "Date",
//         accessor: "date",
//         minWidth: 130,
//         maxWidth: 130,
//         Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
//       },
//       {
//         Header: "Name",
//         accessor: "loggedIn.user.name",
//         Cell: (props) => (
//           <span>
//             {selectedUser?.value ? selectedUser?.label : loggedIn?.user?.name}
//           </span>
//         ),
//       },
//       {
//         Header: "Deviated",
//         accessor: "deviated",
//         disableFilters: true,
//         disableSortBy: true,
//         minWidth: 70,
//         maxWidth: 70,
//         Cell: (props) => (
//           <span>{props?.row?.original?.deviated === true ? "Yes" : "No"}</span>
//         ),
//       },
//       {
//         Header: "Areas",
//         accessor: "workAreas.length",
//         disableFilters: true,
//         disableSortBy: true,
//       },
//       {
//         Header: "View",
//         accessor: "",
//         Cell: (props) => {
//           return (
//             <Link
//               to={`${props?.row?.original?._id}`}
//               state={props?.row?.original}
//               className="util-table-view-details-link"
//             >
//               <FaEye className="icon-color-tertiary" />
//             </Link>
//           );
//         },
//         minWidth: 80,
//         maxWidth: 80,
//         disableFilters: true,
//         disableSortBy: true,
//       },
//     ],
//     [selectedUser, loggedIn]
//   );

//   const handleSubmit = useCallback(
//     async (e) => {
//       e.preventDefault();

//       let data = {
//         selectedUser: selectedUser?.value,
//         month: month?.value,
//         year: Number(year?.label),
//       };

//       console.log(data)

//       await viewReportsMonthWise("extra", data).then((res) =>
//         setDcrReports(res.data),
//       );
//     },
//     [month, year, selectedUser]
//   );

//   useEffect(() => {
//     try {
//       viewUsersForUtilities().then((res) => {
//         setEmployees([...res.data]);
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   }, []);
//   const tableData = useMemo(() => serialise(dcrReports), [dcrReports]);

//   return (
//     <div className="expense">
//       <div className="row">
//         <h2
//           className="web-app__heading"
//           style={{ display: "flex", alignItems: "center" }}
//         >
//           <MdArrowBackIosNew
//             style={{ cursor: "pointer" }}
//             onClick={() => navigate(-1)}
//           />
//           <span style={{ display: "inline-block", margin: "0 1rem" }}>
//             Extra Activity DCR Reports
//           </span>
//         </h2>

//         {/* <div className="date-range-container my-3">
//           <DateRange setDateRange={setDateRange} />
//         </div> */}

//         <form onSubmit={handleSubmit}>
//           <div className="util-tp-query d-flex justify-content-between">
//             <div className="d-flex">
//               <div className="util-tp-filter me-5">
//                 <p>Select Month</p>
//                 <Select
//                   styles={dcrStyles}
//                   placeholder="Select Month"
//                   className="mt-3"
//                   options={monthOptions}
//                   value={month}
//                   onChange={(e) => setMonth({ ...e })}
//                 />
//               </div>

//               <div className="util-tp-filter">
//                 <p>Select Year</p>
//                 <Select
//                   styles={dcrStyles}
//                   placeholder="Select Year"
//                   className="mt-3"
//                   options={yearOptions}
//                   value={year}
//                   onChange={(e) => setYear({ ...e })}
//                 />
//               </div>
//             </div>
//             <div className="d-flex">
//               {loggedIn?.user?.des < 121 ||
//               loggedIn?.user?.designation?.priority > 1 ||
//               loggedIn?.user?.isAdmin ? (
//                 <div className="util-tp-filter me-5">
//                   <p className="util-select-user__label">Select User</p>
//                   <div className="util-select-user">
//                     <MdOutlineGroup className="util-select-user__icon" />
//                     <Select
//                       styles={selectUserStyles}
//                       options={employeeSelect}
//                       placeholder="Select User"
//                       className="mt-3"
//                       value={selectedUser}
//                       onChange={(e) => setSelectedUser({ ...e })}
//                     />
//                   </div>
//                 </div>
//               ) : null}

//               <div className="util-tp-filter">
//                 <button type="submit" className="button-blue-gradient mt-5">
//                   See Reports
//                 </button>
//               </div>
//             </div>
//           </div>
//         </form>

//         <div className="filter-table">
//           {dcrReports.length > 0 && (
//             <h2 className="web-app__heading mb-4">
//               Showing ({dcrReports.length}) Entries
//             </h2>
//           )}
//           {tableData.length > 0 ? (
//             <Table columns={columns} data={tableData} />
//           ) : (
//             <h3 className="mt-2 no-results-message">No results found</h3>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UtiltiesStockistReports;

import { useState, useEffect, useMemo, useCallback, Suspense, lazy } from "react";
import { MdArrowBackIosNew, MdOutlineGroup, MdOutlineRefresh } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
// import DateRange from "../../../../components/DateRange";
// import { addDays } from "date-fns";
import serialise, {
  generateSelectData,
  generateSelectUser,
} from "../../../../utils/serialiseResponse.js";
import { viewUsersForUtilities } from "../../../../services/utilities.js";
import {
  formatDate,
  monthOptions,
  yearOptions,
} from "../../../../utils/helper.js";
import { viewReminderCall } from "../../../../services/reports.js";
import Select from "react-select";
import {
  dcrStyles,
  selectUserStyles,
} from "../../../public/stylesheets/selectStyles.js";
import { useSelector } from "react-redux";
import Table from "../../../../components/Table/index.jsx";
import { FaEye } from "react-icons/fa";
import { viewTPForDate } from "../../../../services/tourProgram.js";
import moment from "moment-timezone";
import { number } from "yup";
import customToast from "../../../../components/CustomToast/index.js";
const DatePickers = lazy(() => import("../../../../components/DatePicker"))

const ReminderCallReports = () => {
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);
  // const loggedInUser = {
  //   label: `${loggedIn?.user?.firstName} ${loggedIn?.user?.lastName}`,
  //   value: loggedIn?.user?._id,
  //   designation: loggedIn?.user?.des,
  // }
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  let [remindercallReports, setremindercallReports] = useState([]);
  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [tpData, setTpData] = useState({});
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState()

  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  // const columns = useMemo(
  //   () => [
  //     // {
  //     //   Header: "Sr. No",
  //     //   accessor: "sr",
  //     //   minWidth: 40,
  //     //   maxWidth: 40,
  //     //   disableFilters: true,
  //     //   disableSortBy: true,
  //     // },
  //     (loggedIn?.user?.isAdmin && !selectedUser) ?  {
  //       Header : "Name",
  //       ID: "Name",
  //       accessor : "submitter.firstName",
  //       Cell: (props) => <span>{props?.row?.original?.submitter.firstName} {props?.row?.original?.submitter.lastName}</span>,
  //     } : null,
  //     {
  //       Header: "Date",
  //       accessor: "date",
  //       minWidth: 130,
  //       maxWidth: 130,
  //       // disableFilters: true,
  //       disableSortBy: true,
  //       Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
  //     },
  //     {
  //       Header: "Doctors",
  //       accessor: "doctor",
  //       // disableFilters: true,
  //       disableSortBy: true,
  //       totalAccessor:"doctors"
  //     },
  //     {
  //       Header: "Remarks",
  //       accessor: "remarks",
  //       disableFilters: true,
  //       disableSortBy: true,
  //     },
  //     {
  //       Header: "Product",
  //       accessor: "products",
  //       Cell: (props) => {
  //         return (
  //           <div>
  //             {props?.row?.original?.products?.map((el) => (
  //               <li key={el.value}>
  //                 {el?.label}
  //               </li>
  //             ))}
  //           </div>
  //         );
  //       },
  //       minWidth: 150,
  //       maxWidth: 150,
  //       disableFilters: true,
  //       disableSortBy: true,
  //     },
  //     {
  //       Header: "Area Type",
  //       accessor: "areaType",
  //       disableFilters: true,
  //       disableSortBy: true,
  //       Cell: (props) => {
  //         return (
  //           <span> {props?.row?.original.areaType}</span>
  //         );
  //       },
  //     },
  //     {
  //       Header: "Work Areas",
  //       accessor: "workAreas",
  //       disableFilters: true,
  //       disableSortBy: true,
  //       Cell: (props) => {
  //         const workAreas = props?.row?.original?.workAreas;
  //         // console.log("workarea-",workAreas);
  //         return (
  //           <div>
  //             {(workAreas?.length === 0 ) ? (
  //               <p>Local</p>
  //             ) : (
  //               <ul>
  //                 {workAreas?.map((workArea, index) => (
  //                   <li key={index}>{workArea?.location?.label}</li>
  //                 ))}
  //               </ul>
  //             )}
  //           </div>
  //         );
  //       },
  //     },
  //   ],
  //   [selectedUser, loggedIn]
  // );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Date",
        accessor: "date",
        minWidth: 130,
        maxWidth: 130,
        // disableSortBy: true,
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
      {
        Header: "Doctors",
        accessor: "doctor",
        disableSortBy: true,
      },
      {
        Header: "Remarks",
        accessor: "remarks",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Product",
        accessor: "products",
        Cell: (props) => {
          return (
            <div>
              {props?.row?.original?.products?.map((el) => (
                <li key={el.value}>{el?.label}</li>
              ))}
            </div>
          );
        },
        minWidth: 150,
        maxWidth: 150,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Area Type",
        accessor: "areaType",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => <span>{props?.row?.original?.areaType}</span>,
      },
      {
        Header: "Work Areas",
        accessor: "workAreas",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const workAreas = props?.row?.original?.workAreas;
          return (
            <div>
              {workAreas?.length === 0 ? (
                <p>Local</p>
              ) : (
                <ul>
                  {workAreas?.map((workArea, index) => (
                    <li key={index}>{workArea?.location?.label}</li>
                  ))}
                </ul>
              )}
            </div>
          );
        },
      },
    ];
  
    // Conditionally add the "Name" column
    if (loggedIn?.user?.isAdmin && !selectedUser) {
      baseColumns.unshift({
        Header: "Name",
        accessor: "submitter.firstName",
        Cell: (props) => (
          <span>
            {props?.row?.original?.submitter.firstName}{" "}
            {props?.row?.original?.submitter.lastName}
          </span>
        ),
      });
    }
  
    return baseColumns;
  }, [selectedUser, loggedIn]);
  

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!year) {
        customToast.error("Please select month and year");
        return;
      }

      // if(!selectedUser && loggedIn?.user?.des < 121){
      //   customToast.error("Please select user");
      //   return;
      // }
   
      var startDate = new Date(Number(year), Number(month),1);
      var endDate = new Date(Number(year), Number(month)+1,0);
      let formattedDate = moment
      .tz(startDate, "Asia/Kolkata")
      .format()
      .toString()
      .substring(0, 10);

      const filters = {
        selectedUser: selectedUser?.value,
        startDate : startDate,
        endDate : endDate,
        finalyear : Number(year),
        finalmonth : Number(month)
      };

      // await viewTPForDate(filters.startDate).then(async(res)=>
      // setTpData(res.data));

      // console.log("tpData - ",tpData.length)

      // await viewReminderCall(filters).then(async (res) =>
      //   setremindercallReports(res.data)
      // );
      const [tpDataResponse, remindercallReportsResponse] = await Promise.all([
        viewTPForDate({date: formattedDate}),
        viewReminderCall(filters),
      ]);

      setTpData(tpDataResponse.data);
      setremindercallReports(remindercallReportsResponse.data);

      // console.log("tpData - ", tpDataResponse.data);

    },
    [month, year, selectedUser]
  );

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === selectedUser.label) return true;
      })
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees([...res.data]);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  const tableData = useMemo(() => serialise(remindercallReports), [remindercallReports]);

  return (
    <div className="expense">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Reminder Call Reports
          </span>
        </h2>

        {/* <div className="date-range-container my-3">
          <DateRange setDateRange={setDateRange} />
        </div> */}

        <form onSubmit={handleSubmit}>
          <div className="util-tp-query d-flex justify-content-between">
            <div className="d-flex">
              <div className="util-tp-filter me-5">
                <p className="mb-2">Select Month</p>
                {/* <Select
                  styles={dcrStyles}
                  placeholder="Select Month"
                  className="mt-3"
                  options={monthOptions}
                  value={month}
                  onChange={(e) => setMonth({ ...e })}
                /> */}
                <Suspense>
                  <DatePickers
                    placeholder="Select Month"
                    setMonth={setMonth}
                    setYear={setYear}
                  />
                </Suspense>
              </div>

              {/* <div className="util-tp-filter">
                <p>Select Year</p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Year"
                  className="mt-3"
                  options={yearOptions}
                  value={year}
                  onChange={(e) => setYear({ ...e })}
                />
              </div> */}
            </div>
            <div className="d-flex">
              {loggedIn?.user?.des < 121 ||
              loggedIn?.user?.designation?.priority > 1 ||
              loggedIn?.user?.isAdmin ? (
                <div className="util-tp-filter me-5">
                  <p className="util-select-user__label">Select User</p>
                  <div className="util-select-user">
                    <MdOutlineGroup className="util-select-user__icon" />
                    <Select
                      styles={selectUserStyles}
                      options={employeeSelect}
                      placeholder="Select User"
                      className="mt-3"
                      value={selectedUser}
                      onChange={(e) => setSelectedUser({ ...e })}
                    />
                     <MdOutlineRefresh size={25} className="icon-color-tertiary mt-4 ms-2" style={{cursor:"pointer"}} onClick={() => setSelectedUser(null)}/>
                  </div>
                </div>
              ) : null}

              <div className="util-tp-filter">
                <button type="submit" className="button-blue-gradient mt-5">
                  See Reminder Calls
                </button>
              </div>
            </div>
          </div>
        </form>

        <div className="filter-table">
          {remindercallReports.length > 0 && (
            <h2 className="web-app__heading mb-4">
              Showing ({remindercallReports.length}) Entries
            </h2>
          )}
          {tableData.length > 0 ? (
            <Table defaultPageSize={50} columns={columns} data={tableData} empl={selectedUser ? employeeDataForExcel : loggedIn?.user} fileSrc="Reminder Call Report" month={month} />
          ) : (
            <h3 className="mt-2 no-results-message">No results found</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReminderCallReports;
