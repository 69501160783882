import React, { useEffect,  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Navigate, useLocation, Outlet } from "react-router-dom";

import { initUser,  } from "../reducers/userReducer";
import {
  Home,
  Dashboard,
  TourProgram,
  CallPlanning,
  CallPlanningForm,
  CPDetails,
  DoctorDCR,
  ChemistDCR,
  ExtraDCR,
  Expenses,
  Utilities,
  UtilitiesIndex,
  UtilitiesTour,
  UtilitiesGeoLocationReports,
  TourDetails,
  Attendance,
  Reminders,
  Sample,
  Login,
  Register,
  Request,
  Reset,
  StockistDCR,
  LeaveRequest,
  Notification,
  ConsolidateReport,
  TodaysActivity,
  TodaysActivityDetails,
  UtilitiesTargetsVsAchievements,
  UtilitiesDoctorWiseSales,
  UtilitiesSales,
  UtilitiesMonthlyReports,
  UtilitiesAnnualReports,
  PobWiseReports,
  ProdWiseSales,
  StkWiseSales,
  MissedDocReports,
  StkWiseSalesDetails,
  UtilLists,
  UtilHQ_City_LocalArea,
  UtilDoctorsList,
  UtilChemistsList,
  UtilStockistsList,
  UtilProductsList,
  UtilGiftList,
  UtilRouteList,
  UtilHolidayList,
  ExtraActivityDCRReports,
  ExtraActivityDCRReportsDetails,
  UtilTarget,
  UtilUserTarget,
  HqUserTarget,
  UtilAllottedSamples,
  UtilAllottedGifts,
  JointCRDetails,
  MonthlyExpenseReport,
  PrimarySales,
  AllPrimarySales,
  EditPrimarySales,
  SecondarySales,
  AllSecondarySales,
  EditSecondarySales,
  UploadSecondarySales,
  AllSalesIndex,
  GrossPrimarySales,
  GrossSecondarySales,
  MissedDocReportsDetail,
  PobWiseReportsDetail,
  ProdWisePrimarySalesDetail,
  ProdWiseSecondarySalesDetail,
  UtilProductsListDetails,
  SSReports,
  StockistSS,
  ProductSS,
  PrimarySalesReports,
  STKWisePrimaySalesReports,
  ProductWisePrimarySalesReports,
  ProdWisePrimarySales,
  ProdWiseSecondarySales,
  MinimumStock,
  ProfitAnalysis,
  CostCenter,
  ProfitCenter,
  ProfitAnalysisSettings,
  EmployeeCost,
  DoctorCost,
  ChemistCost,
  StockistCost,
  TotalCost,
  CustomCost,
  ProductsProfit,
  ROI,
  NavigateToTracker,
  HelpAndSupport,
  TicketDetails,
  UtilitiesMonthlyPunchingReport,
  PunchingDetails,
  UtilitiesDuplicateEntries,
  EntityDetails,
  UtilitiesCallPlanning,
  CallPlanningDetails
} from "../views";
import {
  AdminLayout,
  AdminIndex,
  AdminLocations,
  AdminLocationsState,
  AdminLocationsCity,
  AdminLocationsHQ,
  AdminProducts,
  AdminProductsCategory,
  AdminProductsType,
  AdminProductsRange,
  AdminProductsSupplier,
  AdminProductsInventory,
  EditInventory,
  AllInventory,
  AdminGiftCreate,
  AdminExpenses,
  AdminExpensesAllowance,
  AdminUsers,
  AdminUsersCreate,
  AdminUsersInfo,
  AdminDCS,
  AdminDoctorCreate,
  AdminUsersTargetIndex,
  AdminYearlyTargets,
  AdminYearlyHqTargets,
  AdminMonthlyHqTargets,
  AdminMonthlyUserTargets,
  AdminUsersTargetSet,
  AdminHqTarget,
  AdminStockistCreate,
  AdminChemistCreate,
  AdminUsersDivision,
  AdminUsersDesignation,
  AdminSSG,
  AdminAllotGifts,
  AdminAllotGiftsIndex,
  AdminAllotSampleIndex,
  AdminAllotSample,
  Approvals,
  ApprovalTP,
  ApprovalUserTP,
  ApprovalDoctor,
  ApprovalChemist,
  ApprovalStockist,
  ApprovalExpense,
  ApprovalMonthlyExpense,
  ApprovalLR,
  ApprovalCity,
  AdminLocationsRoute,
  AdminTransferDCSFromLocation,
  AdminHolidays,
  AdminCreateHolidays,
  AdminPreferences,
  MinCallReport,
  MaxTourLimit,
  AdminCreate,
  AdminInfo,
  AdminViewAllowance,
  ApprovalRoute,
  AdminOutStationAllowance,
  AdminTotalRates,
  AdminEditDeleteDCS,
  AdminEditDoctor,
  AdminEditChemist,
  AdminEditStockist,
  AdminProductsRangeEdit,
  AdminUsersEdit,
  AdminUsersEditDetails,
  AdminUploadDCS,
  AdminProdUpload,
  AdminUsersInfoDetail,
  AdminInfoDetail,
  AdminViewOSAllowances,
  ApprovalCallReport,
  ApprovalDoctorDetail,
  ApprovalChemistDetail,
  ApprovalStockistDetail,
  ApprovalCallReportDetail,
  AdminProductsRangeDetails,
  AdminViewDCS,
  AdminViewDCSDetails,
  AdminViewDCSDetailsChemist,
  AdminViewDCSDetailsStockist,
  AdminAllotDCS,
  AdminDeAllotDCS,
  Billing,
  AdminFamily,
  ApprovalSecondarySale,
  AdminSSApprovalDetail,
  ApprovalPrimarySale,
  AdminPSApprovalDetail,
  AdminMinStock,
  AdminAccessControl,
  AdminBacklogReports,
  DeletedSecondary,
  DeletedPrimary,
  DeletedInventory,
  RestoreDeleted,
  LiveTracking,
  UserControls,
  UserDevices,
  UserDevicesDetail,
  AdminUploadTarget,
  AdminUploadPrimarySales,
  AdminActiveControl,
  ApproveGeoFencing,
  DeletionRequest,
  ApprovalCP,
  ApprovalCPDetail
} from "../views/Admin";

import { GlobalStyle } from "./styles";
import "react-toastify/dist/ReactToastify.css";
import Gifts from "../views/Application/GiftManagement";
import Stocks from "../views/Application/StockManagement";
import Protected from "./Protected";
import {
  CreationApprovalChemist,
  CreationApprovalCity,
  CreationApprovalCallReport,
  CreationApprovalDoctor,
  CreationApprovalExpense,
  CreationMenuMonthlyExpenseApproval,
  CreationApprovalLR,
  CreationApprovalRoute,
  CreationApprovalGifts,
  CreationApprovalSamples,
  CreationApprovals,
  CreationApprovalStockist,
  CreationApprovalTP,
  CreationApprovalMonthlyTP,
  CreationChemistCreate,
  CreationDCS,
  CreationDoctorCreate,
  CreationIndex,
  CreationLayout,
  CreationLocations,
  CreationLocationsCity,
  CreationLocationsRoute,
  CreationStockistCreate,
  CreationEditDeleteDCS,
  CreationEditChemist,
  CreationEditDoctor,
  CreationEditStockist,
  CreationViewDCS,
  CreationViewDCSDetailsChemist,
  CreationViewDCSDetailsStockist,
  CreationViewDCSDetails,
  BacklogReports,
  CreationBacklogReports,
  CreationApprovalCP,
  CreationApprovalCPDetail,
  CreationDeletionRequest
} from "../views/Application/CreationMenu";
import Profile from "../views/Application/Profile/index";
import DoctorListDetails from "../views/Application/Utilities/UtilLists/DoctorsList/Details";
import ChemistListDetails from "../views/Application/Utilities/UtilLists/ChemistsList/Details";
import StockistListDetails from "../views/Application/Utilities/UtilLists/StockistsList/Details";
import UtilAchievedTarget from "../views/Application/Utilities/UtilTarget/AchievedTarget";
import CreationApprovalCallReportDetails from "../views/Application/CreationMenu/Approvals/CallReport/Details";
import CreationApprovalDoctorDetails from "../views/Application/CreationMenu/Approvals/Doctors/Details";
import CreationApprovalChemistDetails from "../views/Application/CreationMenu/Approvals/Chemists/Details";
import CreationApprovalStockistDetails from "../views/Application/CreationMenu/Approvals/Stockists/Details";
import CreationDWPL from "../views/Application/CreationMenu/DCS/DWPL";
import AdminDWPL from "../views/Admin/DCS/DWPL";
import SALogin from "../views/Authentication/SALogin";
import SampleDetails from "../views/Application/SampleManagement/Details";
import GiftDetails from "../views/Application/GiftManagement/Details";
import EDetailing from "../views/Application/EDetailing";
import UploadImages from "../views/Admin/DCS/UploadImages";
import UtilitesCR from "../views/Application/Utilities/UtilitiesCallReports";
import CRDetails from "../views/Application/Utilities/UtilitiesCallReports/Details";
import NotifyToast from "./NotifyToast";
import LockPopup from "./LockPopup";
import { getPreference } from "../reducers/settings";
import { socket } from "./Socket";
import { fetchAnnouncements } from "../reducers/announcement";
import { fetchCompanyName } from "../reducers/companyName";
import GeoFencing from "../views/Application/Utilities/UtilLists/GeoFencing";
import CRM from "../views/Application/CRM";
import Business from "../views/Application/CRM/Doctor/Business";
import Payments from "../views/Application/CRM/Doctor/Payments";
import Doctor from "../views/Application/CRM/Doctor";
import CRMsettings from "../views/Application/CRM/crmSettings";
import ChemistSales from "../views/Application/CRM/crmSettings/ChemistSales";
import DoctorProduct from "../views/Application/CRM/crmSettings/doctorProduct";
import ChemistDoctor from "../views/Application/CRM/crmSettings/chemistDoctor";
import StokistChemist from "../views/Application/CRM/crmSettings/stokistChemist";
import Report from "../views/Application/CRM/Report";
import DoctorSales from "../views/Application/CRM/Report/DoctorSales";
import { setDesignations } from "../reducers/users/designationReducer";
import PaymentReport from "../views/Application/CRM/Report/PaymentReport";
import MiscellaneousCost from "../views/Application/ProfitAnalysis/CostCenter/miscellaneous";
import DoctorControls from "../views/Admin/Holidays/DoctorControls";
import ScrollToTop from "./ScrollToTop";
import { fetchAccessControl } from "../reducers/payment";
// import ConsolidateReport from "../views/Application/Utilities/UtilitiesConsolidateReport";

const App = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user }) => user);
  
  useEffect(() => {
    
    socket.on("connect", () => {
      if(loggedIn?.user?.dbName)socket.emit("joinRoom", loggedIn?.user?.dbName);
    });
    if(loggedIn.user){
      dispatch(getPreference("hq-target")); //this fetches all setting not only hq
      dispatch(fetchAnnouncements());
      dispatch(fetchAccessControl());
      dispatch(fetchCompanyName());
      dispatch(setDesignations());
    }
  },[loggedIn, dispatch])


  useEffect(() => {
    dispatch(initUser());
  }, [dispatch]);
  
  if (loggedIn.user === null) return <div>Loading...</div>;

  return (
    <>
      <GlobalStyle />

      <ScrollToTop />

      <Routes>
        <Route
          path="superadmin-login"
          element={<SALogin loggedUser={loggedIn.user} />}
        />
        <Route path="login" element={<Login loggedUser={loggedIn.user} />} />
        <Route
          path="register"
          element={<Register loggedUser={loggedIn.user} />}
        />
        <Route
          path="request-password"
          element={<Request loggedUser={loggedIn.user} />}
        />
        <Route
          path="reset-password"
          element={<Reset loggedUser={loggedIn.user} />}
        />
        <Route
          path="account-locked"
          element={<LockPopup loggedUser={loggedIn.user} />}
        />
        <Route
          path="/"
          element={
            <RequireAuth loggedUser={loggedIn.user}>
              <Home />
            </RequireAuth>
          }
        >
          <Route path="/" element={<Navigate to="/dashboard" />} />

          <Route path="dashboard" element={<Dashboard />} />
          <Route path="notification" element={<Notification />} />
          <Route path="today's-activity" element={<TodaysActivity />} />
          <Route
            path="today's-activity/:id"
            element={<TodaysActivityDetails />}
          />
          <Route path="min-stock" element={<MinimumStock />} />
          <Route path="consolidated-reports" element={<ConsolidateReport />} />
          <Route path="navigate-to-tracker" element={<NavigateToTracker />} />
          <Route path="help-&-support" element={<HelpAndSupport />} />
          <Route path="help-&-support/:id" element={<TicketDetails />} />
          <Route
            path="profile"
            element={<Profile loggedUser={loggedIn?.user} />}
          />
          <Route
            path="admin-panel"
            element={
              <Protected isLoggedIn={loggedIn?.user?.isAdmin}>
                <AdminLayout />
              </Protected>
            }
          >
            <Route index element={<AdminIndex />} />
            <Route path="locations" element={<AdminLocations />}>
              <Route path="create-state" element={<AdminLocationsState />} />
              <Route path="create-hq" element={<AdminLocationsHQ />} />
              <Route path="create-city" element={<AdminLocationsCity />} />
              <Route path="create-route" element={<AdminLocationsRoute />} />
              <Route
                path="transfer-dcs"
                element={<AdminTransferDCSFromLocation />}
              />
            </Route>

            <Route path="products" element={<AdminProducts />}>
              <Route path="category" element={<AdminProductsCategory />} />
              <Route path="type" element={<AdminProductsType />} />
              <Route path="upload-products" element={<AdminProdUpload />} />
              <Route path="add-supplier" element={<AdminProductsSupplier />} />
              <Route path="inventory" element={<Outlet />}>
                <Route index element={<AdminProductsInventory />} />
                <Route path="full" element={<AllInventory />} />
                <Route path="full/:inventoryId" element={<EditInventory />} />
              </Route>
              <Route path="range" element={<Outlet />}>
                <Route index element={<AdminProductsRange />} />
                <Route path="edit" element={<AdminProductsRangeEdit />} />
                <Route path="details" element={<AdminProductsRangeDetails />} />
              </Route>
              <Route path="min-stock" element={<AdminMinStock />} />
            </Route>

            <Route path="holidays" element={<AdminHolidays />}>
              <Route path="create" element={<AdminCreateHolidays />} />
              <Route
                path="preferences"
                element={<AdminPreferences socket={socket} />}
              />
              <Route path="backlog-reports" element={<AdminBacklogReports />} />
              <Route path="user-controls" element={<LiveTracking />} />
              <Route path="user-controls/:userId" element={<UserControls />} />
              <Route
                path="preferences/min-call-report"
                element={<MinCallReport />}
              />
              <Route
                path="preferences/max-tour-limit"
                element={<MaxTourLimit />}
              />
              <Route path="restore-deleted" element={<Outlet />}>
                <Route index element={<RestoreDeleted />} />
                <Route
                  path="secondary-sales/:id"
                  element={<DeletedSecondary />}
                />
                <Route path="primary-sales/:id" element={<DeletedPrimary />} />
                <Route
                  path="inventory/:inventoryId"
                  element={<DeletedInventory />}
                />
              </Route>
              <Route path="doctor-controls" element={<Outlet />}>
                <Route index element={<DoctorControls />} />
              </Route>
            </Route>

            <Route path="expenses" element={<AdminExpenses />}>
              <Route
                path="travel-allowances"
                element={<AdminExpensesAllowance />}
              />
              <Route path="view-ta" element={<AdminViewAllowance />} />
              <Route
                path="out-station-allowances"
                element={<AdminOutStationAllowance />}
              />
              <Route path="rates" element={<AdminTotalRates />} />
              <Route
                path="view-os-allowances"
                element={<AdminViewOSAllowances />}
              />
            </Route>

            <Route path="users" element={<AdminUsers />}>
              <Route path="create-user" element={<AdminUsersCreate />} />
              <Route path="edit-user" element={<Outlet />}>
                <Route index element={<AdminUsersEdit />} />
                <Route path="edit" element={<AdminUsersEditDetails />} />
              </Route>
              <Route path="create-admin" element={<AdminCreate />} />
              <Route path="admin-info" element={<Outlet />}>
                <Route index element={<AdminInfo />} />
                <Route path="details" element={<AdminInfoDetail />} />
              </Route>
              <Route path="user-info" element={<Outlet />}>
                <Route index element={<AdminUsersInfo />} />
                <Route path="active-control" element={<AdminActiveControl />} />
                <Route path="details" element={<AdminUsersInfoDetail />} />
              </Route>
              <Route path="division" element={<AdminUsersDivision />} />
              <Route path="designation" element={<AdminUsersDesignation />} />
              <Route path="upload-target" element={<AdminUploadTarget />} />
              <Route path="set-user-target" element={<Outlet />}>
                <Route index element={<AdminUsersTargetIndex />} />
                <Route path=":userID" element={<AdminUsersTargetSet />} />
                <Route path="yearly-targets" element={<AdminYearlyTargets />} />
                <Route
                  path="monthly-user-target"
                  element={<AdminMonthlyUserTargets />}
                />
              </Route>
              <Route path="set-headquarter-target" element={<Outlet />}>
                <Route index element={<AdminHqTarget />} />
                <Route
                  path="yearly-hq-targets"
                  element={<AdminYearlyHqTargets />}
                />
                <Route
                  path="monthly-hq-targets"
                  element={<AdminMonthlyHqTargets />}
                />
              </Route>
              {/* <Route
                path="set-headquarter-target"
                element={<AdminHqTarget />}
              /> */}
              <Route
                path="access-control"
                element={<AdminAccessControl socket={socket} />}
              />
              <Route path="user-devices" element={<UserDevices />} />
              <Route
                path="user-devices/:userId"
                element={<UserDevicesDetail />}
              />
              <Route path="family" element={<AdminFamily />} />
              <Route path="billing" element={<Billing />} />
            </Route>

            <Route path="dcs" element={<AdminDCS />}>
              <Route
                path="admin-e-detailing-management"
                element={<AdminDWPL />}
              />

              <Route path="create-doctors" element={<AdminDoctorCreate />} />
              <Route path="create-chemists" element={<AdminChemistCreate />} />
              <Route
                path="create-stockists"
                element={<AdminStockistCreate />}
              />

              <Route path="view-dcs" element={<Outlet />}>
                <Route index element={<AdminViewDCS />} />
                <Route path="details" element={<AdminViewDCSDetails />} />
                <Route
                  path="details-chemist"
                  element={<AdminViewDCSDetailsChemist />}
                />
                <Route
                  path="details-stockist"
                  element={<AdminViewDCSDetailsStockist />}
                />
              </Route>
              <Route path="upload-dcs" element={<AdminUploadDCS />} />

              <Route path="edit-delete" element={<Outlet />}>
                <Route index element={<AdminEditDeleteDCS />} />
                <Route path="doctor" element={<AdminEditDoctor />} />
                <Route path="chemist" element={<AdminEditChemist />} />
                <Route path="stockist" element={<AdminEditStockist />} />
              </Route>
              <Route path="upload-images" element={<UploadImages />} />
              <Route path="allot-dcs" element={<AdminAllotDCS />} />
              <Route path="reassign-dcs" element={<AdminDeAllotDCS />} />
            </Route>

            <Route path="ssg" element={<AdminSSG />}>
              {/* <Route path="create-sample" element={<AdminSampleCreate />} /> */}
              <Route path="create-gift" element={<AdminGiftCreate />} />
              {/* <Route path="allot-stocks" element={<AdminAllotStocks />} /> */}
              {/* <Route path="allot-samples" element={<AdminAllotStocks />} /> */}
              {/* <Route path="allot-stocks" element={<Outlet />}>
                <Route index element={<AdminAllotStocksIndex />} />
                <Route path=":userID" element={<AdminAllotStocks />} />
              </Route> */}
              <Route path="allot-samples" element={<Outlet />}>
                <Route index element={<AdminAllotSampleIndex />} />
                <Route path=":userID" element={<AdminAllotSample />} />
              </Route>
              <Route path="allot-gifts" element={<Outlet />}>
                <Route index element={<AdminAllotGiftsIndex />} />
                <Route path=":userID" element={<AdminAllotGifts />} />
              </Route>
            </Route>

            <Route path="approvals" element={<Approvals />}>
              <Route path="approve-call-report" element={<Outlet />}>
                <Route index element={<ApprovalCallReport />} />
                <Route path="details" element={<ApprovalCallReportDetail />} />
              </Route>
              <Route path="approve-tp" element={<Outlet />}>
                <Route index element={<ApprovalTP />} />
                <Route path="view-user-tp" element={<ApprovalUserTP />} />
              </Route>
              <Route path="approve-cp" element={<Outlet />}>
                <Route index element={<ApprovalCP />} />
                <Route path="details" element={<ApprovalCPDetail />} />
              </Route>
              <Route path="approve-doctors" element={<Outlet />}>
                <Route index element={<ApprovalDoctor />} />
                <Route path="details" element={<ApprovalDoctorDetail />} />
              </Route>
              <Route path="approve-chemists" element={<Outlet />}>
                <Route index element={<ApprovalChemist />} />
                <Route path="details" element={<ApprovalChemistDetail />} />
              </Route>
              <Route path="approve-stockists" element={<Outlet />}>
                <Route index element={<ApprovalStockist />} />
                <Route path="details" element={<ApprovalStockistDetail />} />
              </Route>
              <Route path="approve-expense" element={<ApprovalExpense />} />
              <Route
                path="approve-expense/monthly-view"
                element={<ApprovalMonthlyExpense />}
              />
              <Route path="approve-leave-request" element={<ApprovalLR />} />
              <Route path="approve-city" element={<ApprovalCity />} />
              <Route path="approve-route" element={<ApprovalRoute />} />
              <Route
                path="approve-geo-fencing"
                element={<ApproveGeoFencing />}
              />
              <Route path="gifts-approve" element={<CreationApprovalGifts />} />
              <Route
                path="samples-approve"
                element={<CreationApprovalSamples />}
              />
              <Route path="approve-secondary-sale" element={<Outlet />}>
                <Route index element={<ApprovalSecondarySale />} />
                <Route path="details" element={<AdminSSApprovalDetail />} />
              </Route>
              <Route path="approve-primary-sale" element={<Outlet />}>
                <Route index element={<ApprovalPrimarySale />} />
                <Route path="details" element={<AdminPSApprovalDetail />} />
              </Route>
              <Route path="deletion-request" element={<Outlet />}>
                <Route index element={<DeletionRequest />} />
              </Route>
            </Route>
          </Route>

          {/* Creation Menu */}

          <Route
            path="creation-menu"
            element={
              <Protected isLoggedIn={!loggedIn?.user?.isAdmin}>
                <CreationLayout />
              </Protected>
            }
          >
            <Route index element={<CreationIndex />} />

            <Route path="locations" element={<CreationLocations />}>
              <Route path="create-city" element={<CreationLocationsCity />} />
              <Route path="create-route" element={<CreationLocationsRoute />} />
            </Route>

            {loggedIn?.user?.des !== 121 ? (
              <Route path="ssg" element={<AdminSSG />}>
                <Route path="create-gift" element={<AdminGiftCreate />} />
                <Route path="allot-samples" element={<Outlet />}>
                  <Route index element={<AdminAllotSampleIndex />} />
                  <Route path=":userID" element={<AdminAllotSample />} />
                </Route>

                <Route path="allot-gifts" element={<Outlet />}>
                  <Route index element={<AdminAllotGiftsIndex />} />
                  <Route path=":userID" element={<AdminAllotGifts />} />
                </Route>
              </Route>
            ) : (
              <Route path="ssg" element={<AdminSSG />}>
                <Route path="create-gift" element={<AdminGiftCreate />} />
                <Route path="allot-samples/*" element={<AdminAllotSample />} />
                <Route path="allot-gifts/*" element={<AdminAllotGifts />} />
              </Route>
            )}

            {/* <Route path="ssg" element={<AdminSSG />}>
              <Route path="create-gift" element={<AdminGiftCreate />} />
              <Route path="allot-samples" element={<Outlet />}>
                  <Route index element={<AdminAllotSampleIndex />} />
                  <Route path=":userID" element={<AdminAllotSample />} />
              </Route>

              <Route path="allot-gifts" element={<Outlet />}>
                  <Route index element={<AdminAllotGiftsIndex />} />
                  <Route path=":userID" element={<AdminAllotGifts />} />
              </Route>
            </Route> */}

            <Route path="dcs" element={<CreationDCS />}>
              <Route
                path="doctor-wise-products-list"
                element={<CreationDWPL />}
              />
              <Route path="create-doctors" element={<CreationDoctorCreate />} />
              <Route
                path="create-chemists"
                element={<CreationChemistCreate />}
              />
              <Route
                path="create-stockists"
                element={<CreationStockistCreate />}
              />
              <Route path="view-dcs" element={<Outlet />}>
                <Route index element={<CreationViewDCS />} />
                <Route path="details" element={<CreationViewDCSDetails />} />
                <Route
                  path="details-chemist"
                  element={<CreationViewDCSDetailsChemist />}
                />
                <Route
                  path="details-stockist"
                  element={<CreationViewDCSDetailsStockist />}
                />
              </Route>
              <Route path="edit-delete" element={<Outlet />}>
                <Route index element={<CreationEditDeleteDCS />} />
                <Route path="doctor" element={<CreationEditDoctor />} />
                <Route path="chemist" element={<CreationEditChemist />} />
                <Route path="stockist" element={<CreationEditStockist />} />
              </Route>
            </Route>

            {/* -----APPROVALS------- */}
            <Route path="approvals" element={<CreationApprovals />}>
              <Route path="approve-call-report" element={<Outlet />}>
                <Route index element={<CreationApprovalCallReport />} />
                <Route
                  path=":CreationCallReportDetails"
                  element={<CreationApprovalCallReportDetails />}
                />
              </Route>
              <Route path="approve-tp" element={<Outlet />}>
                <Route index element={<CreationApprovalTP />} />
                <Route
                  path="view-user-tp"
                  element={<CreationApprovalMonthlyTP />}
                />
              </Route>
              <Route path="approve-cp" element={<Outlet />}>
                <Route index element={<CreationApprovalCP />} />
                <Route path="details" element={<CreationApprovalCPDetail />} />
              </Route>
              <Route path="approve-doctors" element={<Outlet />}>
                <Route index element={<CreationApprovalDoctor />} />
                <Route
                  path=":CreationApprovalDoctorDetails"
                  element={<CreationApprovalDoctorDetails />}
                />
              </Route>
              <Route path="approve-chemists" element={<Outlet />}>
                <Route index element={<CreationApprovalChemist />} />
                <Route
                  path=":CreationApprovalChemistDetails"
                  element={<CreationApprovalChemistDetails />}
                />
              </Route>
              <Route path="approve-stockists" element={<Outlet />}>
                <Route index element={<CreationApprovalStockist />} />
                <Route
                  path=":CreationApprovalStockistDetails"
                  element={<CreationApprovalStockistDetails />}
                />
              </Route>
              <Route
                path="approve-expense"
                element={<CreationApprovalExpense />}
              />
              <Route
                path="approve-expense/monthly-view"
                element={<CreationMenuMonthlyExpenseApproval />}
              />
              <Route
                path="approve-leave-request"
                element={<CreationApprovalLR />}
              />
              <Route path="approve-city" element={<CreationApprovalCity />} />
              <Route path="approve-route" element={<CreationApprovalRoute />} />
              <Route path="gifts-approve" element={<CreationApprovalGifts />} />
              <Route
                path="samples-approve"
                element={<CreationApprovalSamples />}
              />
              <Route path="deletion-request" element={<Outlet />}>
                <Route index element={<CreationDeletionRequest />} />
              </Route>
            </Route>

            {/* Settings */}

            <Route path="settings" element={<CreationBacklogReports />}>
              <Route path="backlog-reports" element={<BacklogReports />} />
            </Route>
          </Route>
          <Route path="doctor-dcr" element={<DoctorDCR />} />
          <Route path="chemist-dcr" element={<ChemistDCR />} />
          <Route path="stockist-dcr" element={<StockistDCR />} />
          <Route path="extra-dcr" element={<ExtraDCR />} />
          <Route path="tour-program" element={<TourProgram />} />
          <Route path="call-planning" element={<CallPlanning />}>
            <Route index element={<CallPlanningForm />} />
            <Route path=":id" element={<CPDetails />} />
          </Route>
          <Route path="expenses" element={<Expenses />} />
          <Route path="primarysales" element={<Outlet />}>
            <Route index element={<PrimarySales />} />
            <Route path="all" element={<AllPrimarySales />} />
            <Route path="all/:id" element={<EditPrimarySales />} />
            <Route path="upload-ps" element={<AdminUploadPrimarySales />} />
          </Route>
          <Route path="secondarysales" element={<Outlet />}>
            <Route index element={<SecondarySales />} />
            <Route path="all" element={<AllSecondarySales />} />
            <Route path="all/:id" element={<EditSecondarySales />} />
            <Route path="upload-ss" element={<UploadSecondarySales />} />
          </Route>
          <Route path="e-detailing" element={<EDetailing />} />
          <Route index path="sales" element={<AllSalesIndex />} />
          <Route path="sales/primarysales" element={<PrimarySales />}>
            <Route path="all" element={<AllPrimarySales />} />
            <Route path="all/:id" element={<EditPrimarySales />} />
          </Route>
          <Route
            path="sales/gross-primarysales"
            element={<GrossPrimarySales />}
          />
          <Route path="sales/secondarysales" element={<SecondarySales />}>
            <Route path="all" element={<AllSecondarySales />} />
            <Route path="all/:id" element={<EditSecondarySales />} />
          </Route>
          <Route
            path="sales/gross-secondarysales"
            element={<GrossSecondarySales />}
          />

          {/* -----UTILITIES------- */}
          <Route path="utilities" element={<Utilities />}>
            <Route
              index
              element={<UtilitiesIndex isAdmin={loggedIn?.user?.isAdmin} />}
            />
            {/* <Route path="day-plan" element={<Outlet />}>
              <Route index element={<UtilitiesDayPlan />} />
              <Route path=":dayPlanProgramId" element={<DayPlanDetails />} />
            </Route> */}

            <Route path="monthly-reports" element={<Outlet />}>
              <Route
                index
                element={
                  <UtilitiesMonthlyReports isAdmin={loggedIn?.user?.isAdmin} />
                }
              />
            </Route>

            <Route path="annual-reports" element={<Outlet />}>
              <Route
                index
                element={
                  <UtilitiesAnnualReports isAdmin={loggedIn?.user?.isAdmin} />
                }
              />
            </Route>

            <Route
              path="geolocation-reports"
              element={<UtilitiesGeoLocationReports />}
            />

            <Route
              path="monthly-expense-report"
              element={<MonthlyExpenseReport />}
            />

            {/* <Route path="consolidate-reports" element={<Outlet/>}>
              <Route index element={<ConsolidateReport/>}/>
            </Route> */}

            <Route path="tour-program" element={<Outlet />}>
              <Route index element={<UtilitiesTour />} />
              <Route path=":tourProgramId" element={<TourDetails />} />
            </Route>

            <Route path="monthly-reports" element={<Outlet />}></Route>

            <Route path="call-reports" element={<Outlet />}>
              <Route index element={<UtilitesCR />} />
              <Route path=":reportId" element={<CRDetails />} />
              <Route
                path="joint-callReport-details"
                element={<JointCRDetails />}
              />
            </Route>
            <Route path="extra-activity-dcr" element={<Outlet />}>
              <Route index element={<ExtraActivityDCRReports />} />
              <Route
                path=":reportId"
                element={<ExtraActivityDCRReportsDetails />}
              />
            </Route>
            <Route path="sales" element={<Outlet />}>
              <Route index element={<UtilitiesSales />} />
            </Route>
            <Route path="missed-doctor-reports" element={<Outlet />}>
              <Route index element={<MissedDocReports />} />
              <Route path=":docId" element={<MissedDocReportsDetail />} />
            </Route>
            <Route path="stockist-wise-sales" element={<Outlet />}>
              <Route index element={<StkWiseSales />} />
              <Route path=":stkId" element={<StkWiseSalesDetails />} />
            </Route>
            <Route path="secondary-sales-reports" element={<Outlet />}>
              <Route index element={<SSReports />} />
              <Route path="stk" element={<StockistSS />} />
              <Route path=":id" element={<ProductSS />} />
              <Route path="stk/:id" element={<ProductSS />} />
            </Route>
            <Route path="primary-sales-reports" element={<Outlet />}>
              <Route index element={<PrimarySalesReports />} />
              <Route path="stk" element={<STKWisePrimaySalesReports />} />
              <Route path=":id" element={<ProductWisePrimarySalesReports />} />
              <Route
                path="stk/:id"
                element={<ProductWisePrimarySalesReports />}
              />
            </Route>
            <Route path="product-wise-sales" element={<Outlet />}>
              <Route index element={<ProdWiseSales />} />

              <Route path="primary-sales" element={<Outlet />}>
                <Route index element={<ProdWisePrimarySales />} />
                <Route
                  path=":prodId"
                  element={<ProdWisePrimarySalesDetail />}
                />
              </Route>

              <Route path="secondary-sales" element={<Outlet />}>
                <Route index element={<ProdWiseSecondarySales />} />
                <Route
                  path=":prodId"
                  element={<ProdWiseSecondarySalesDetail />}
                />
              </Route>
            </Route>
            <Route path="pob-reports" element={<Outlet />}>
              <Route index element={<PobWiseReports />} />
              <Route path=":docId" element={<PobWiseReportsDetail />} />
            </Route>
            <Route path="targets-achievements" element={<Outlet />}>
              <Route index element={<UtilitiesTargetsVsAchievements />} />
            </Route>
            <Route path="doctor-wise-sales" element={<Outlet />}>
              <Route index element={<UtilitiesDoctorWiseSales />} />
            </Route>
            <Route path="lists" element={<UtilLists />}>
              <Route path="doctors-list" element={<Outlet />}>
                <Route index element={<UtilDoctorsList />} />
                <Route
                  path=":DoctorListDetails"
                  element={<DoctorListDetails />}
                />
              </Route>
              <Route path="chemists-list">
                <Route index element={<UtilChemistsList />} />
                <Route
                  path=":ChemistListDetails"
                  element={<ChemistListDetails />}
                />
              </Route>

              <Route path="stockists-list">
                <Route index element={<UtilStockistsList />} />
                <Route
                  path=":StockistListDetails"
                  element={<StockistListDetails />}
                />
              </Route>
              <Route path="products-list">
                <Route index element={<UtilProductsList />} />
                <Route
                  path=":ProductListDetails"
                  element={<UtilProductsListDetails />}
                />
              </Route>
              <Route path="geo-fencing" element={<GeoFencing />} />
              <Route
                path="hq-city-localArea-list"
                element={<UtilHQ_City_LocalArea />}
              />
              <Route path="gift-list" element={<UtilGiftList />} />
              <Route path="route-list" element={<UtilRouteList />} />
              <Route path="holiday-list" element={<UtilHolidayList />} />
            </Route>

            <Route path="targets" element={<UtilTarget />}>
              <Route path="user-target">
                <Route index element={<UtilUserTarget />} />
                <Route path="hq-user" element={<HqUserTarget />} />
              </Route>
              <Route
                path="allotted-samples"
                element={<UtilAllottedSamples />}
              />
              <Route path="allotted-gifts" element={<UtilAllottedGifts />} />
              <Route path="achieved-target" element={<UtilAchievedTarget />} />
            </Route>

            {/* for getting checkIn checkOut report of users */}
            <Route path="punching-report" element={<Outlet />}>
              <Route index element={<UtilitiesMonthlyPunchingReport />} />
              <Route path=":punchingId" element={<PunchingDetails />} />
            </Route>

            <Route path="call-planning" element={<Outlet />}>
              <Route index element={<UtilitiesCallPlanning />} />
              <Route path=":id" element={<CallPlanningDetails />} />
            </Route>


            {/* for getting duplicate entries of DCS */}
            <Route path="duplicate-entries" element={<Outlet />}>
              <Route index element={<UtilitiesDuplicateEntries />} />
              <Route path=":details" element={<EntityDetails />} />
            </Route>
          </Route>

          <Route path="attendance" element={<Attendance />} />
          <Route path="reminders" element={<Reminders />} />
          <Route path="leave-request" element={<LeaveRequest />} />
          <Route path="stock-management" element={<Stocks />} />
          <Route path="sample-management" element={<Outlet />}>
            <Route index element={<Sample />} />
            <Route path=":SampleDetails" element={<SampleDetails />} />
          </Route>
          <Route path="gift-management" element={<Outlet />}>
            <Route index element={<Gifts />} />
            <Route path=":GiftDetails" element={<GiftDetails />} />
          </Route>

          <Route path="crm" element={<Outlet />}>
            <Route index element={<CRM />} />
            <Route path="doctor" element={<Doctor />}>
              <Route path="business" element={<Business />} />
              <Route path="payments" element={<Payments />} />
            </Route>
            <Route path="report" element={<Report />}>
              <Route path="doctor-sales" element={<DoctorSales />} />
              <Route path="payment-report" element={<PaymentReport />} />
            </Route>
            <Route path="settings" element={<CRMsettings />}>
              <Route path="chemist-sales" element={<ChemistSales />} />
              <Route path="doctor-product" element={<DoctorProduct />} />
              <Route path="chemist-doctor" element={<ChemistDoctor />} />
              <Route path="stokist-chemist" element={<StokistChemist />} />
            </Route>
          </Route>

          <Route path="profit-analysis" element={<Outlet />}>
            <Route index element={<ProfitAnalysis />} />
            <Route path="cost-center" element={<CostCenter />}>
              <Route path="employee" element={<EmployeeCost />} />
              <Route path="doctor" element={<DoctorCost />} />
              <Route path="chemist" element={<ChemistCost />} />
              <Route path="stockist" element={<StockistCost />} />
              <Route path="miscellaneous" element={<MiscellaneousCost />} />
              <Route path="total-expenditure" element={<TotalCost />} />
            </Route>
            <Route path="profit-center" element={<ProfitCenter />}>
              <Route path="products" element={<ProductsProfit />} />
              <Route path="roi" element={<ROI />} />
            </Route>

            <Route path="settings" element={<Outlet />}>
              <Route index element={<ProfitAnalysisSettings />} />
              <Route path="custom-cost" element={<CustomCost />} />
            </Route>

            {/* <Route path="employee" element={<ProfitAnalysisEmployee />}/>
              <Route path="doctor" element={<ProfitAnalysisDoctor />}/>
              <Route path="stockist" element={<ProfitAnalysisStockist />}/>
              <Route path="chemist" element={<ProfitAnalysisChemist />}/> */}

            {/* <Route path="settings" element={<ProfitAnalysisSettings />} >
              <Route path="doctor-product" element={<DoctorProduct />} />
              <Route path="chemist-doctor" element={<ChemistDoctor />} />
              <Route path="stokist-chemist" element={<StokistChemist />} />
              <Route path="final-report" element={<FinalReport />} />
              <Route path="doctor-product" element={<DoctorProduct />} />
              <Route path="chemist-doctor" element={<ChemistDoctor />} />
              <Route path="stokist-chemist" element={<StokistChemist />} />
              <Route path="chemist-sales" element={<ChemistSales />} />
            </Route> */}
          </Route>
        </Route>
      </Routes>
      <NotifyToast socket={socket} />
    </>
  );
};

const RequireAuth = ({ children, loggedUser }) => {
  const location = useLocation();
  
  if (!loggedUser) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  if (loggedUser.isLocked) {
    return <Navigate to="/account-locked" />;
  }

  return children;
};

export default App;
