import { getAccessStatus, getPaymentStatus } from "../services/payment";

const initialState = {
  message: null,
  total: null,
  type: null,
  display: null,
  name: null,
};

const paymentStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PAYMENTSTATUS":
      return {
        message: action.payload.message,
        total: action.payload.total,
        type: action.payload.type,
        display: action.payload.display,
        name: action.payload.name,
      };
      case "GET_ACCESS_CONTROL" : 
      return {
        accessControlmessage: action.payload.message,
      }
    default:
      return state;
  }
};

export const fetchPaymentStatus = () => {
  return async (dispatch) => {
    try {
      const response = await getPaymentStatus();
      dispatch({
        type: "GET_PAYMENTSTATUS",
        payload: response.data,
      });
    } catch (error) {
      console.error("Failed to fetch subscription status", error);
    }
  };
};

export const fetchAccessControl = () => {
    return async (dispatch) => {
        try {
            const response = await getAccessStatus();
            // const response = await getAnnouncementV1();
            dispatch({
                type: 'GET_ACCESS_CONTROL',
                payload: response?.data,
            });
        } catch (error) {
            console.error('Failed to access control', error);
        }
    };
};

export default paymentStatusReducer;
