import { useEffect, useState, useMemo } from "react";
import {
  MdArrowBackIosNew,
  MdClear,
  MdDoneAll,
  MdOutlineGroup,
  MdOutlineRefresh,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectUserStyles } from "../../../public/stylesheets/selectStyles";
import DateRange from "../../../../components/DateRange";
import { addDays } from "date-fns";
import serialise, {
  generateSelectData,
  generateSelectUser,
} from "../../../../utils/serialiseResponse";
import { viewUsersForUtilities } from "../../../../services/utilities";
import { formatDate } from "../../../../utils/helper";
import Select from "react-select";
import Table from "../../../../components/Table";
import { FaEye } from "react-icons/fa";
import {
  viewBacklogReport,
  viewCallReport,
  viewWorkedWithReport,
  viewLastCallReport,
} from "../../../../services/dcr";
import WhiteCircles from "../../../public/img/white-circles.png";
import ExcelExportButton from "../../../../components/ExcelExport";
import customToast from "../../../../components/CustomToast";
import React from "react";
import CustomTable from "../../../../components/CustomTableForCR";

const showArr = [
  { value: "callReport", label: "Call Report" },
  { value: "last CR", label: "Show Last Call Report" },
  { value: "backlog", label: "Backlog Report" },
  { value: "detailed", label: "Detailed Report" },
  { value: "workedWith", label: "Worked With Report" },
  { value: "joint", label: "Joint Call Report" },
];
const UtilitesCR = () => {
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
  });
  const [startMonth, setStartMonth] = useState(dateRange.startDate.getMonth());

  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();
  const [callReports, setCallReports] = useState([]);
  const [isbacklogReport, setIsBacklogReport] = useState(false);
  const [isDetailedReport, setIsDetailedReport] = useState(false);
  const [isWorkedWithReport, setIsWorkedWithReport] = useState(false);
  const [showlastCR, setShowlastCR] = useState(false);
  const [reportType, setReportType] = useState(showArr[0]);
  const [isJointReport, setIsJointReport] = useState(false);
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  const monthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    try {
      if (selectedUser && employeeData?.length > 0) {
        let tempdata = employeeData.filter((empData) => {
          if (empData.label === selectedUser.label) return true;
        });
        setEmployeeDataForExcel(tempdata[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser, employeeData]);

  useEffect(() => {
    setStartMonth(dateRange.startDate.getMonth());
  }, [dateRange]);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 60,
      //   disableFilters: true,
      //   disableSortBy: true,
      //   maxWidth: 60,
      // },
      {
        Header: "Date",
        accessor: "date",
        minWidth: 90,
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: false,
        showTotal: true,
        placeholderTotal: "Total",
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
      {
        Header: "Day",
        accessor: "day",
        minWidth: 90,
        maxWidth: 100,
        Cell: (props) => {
          return (
            <span>
              {new Date(props?.row?.original?.date).toLocaleString("en-us", {
                weekday: "long",
              })}
            </span>
          );
        },
        disableFilters: false,
        disableSortBy: true,
      },
      {
        Header: "Name",
        accessor: "submitter.firstName",
        disableSortBy: true,
        Cell: (props) =>
          props?.row?.original?.submitter?.firstName +
          " " +
          props?.row?.original?.submitter?.lastName,
      },
      {
        Header: "Activity",
        accessor: "activity",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Area-Type",
        accessor: "areaType",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Working Areas",
        accessor: "",
        Cell: (props) => {
          return (
            <span>
              {props.row.original.workAreas
                ?.map((el) => {
                  return el?.location?.label;
                })
                .join(", ") || "N/A"}
            </span>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Doctors",
        accessor: "doctors.length",
        disableFilters: true,
        showTotal: true,
        totalAccessor: "doctors",
      },
      {
        Header: "Chemists",
        accessor: "chemists.length",
        disableFilters: true,
        showTotal: true,
        totalAccessor: "chemists",
      },
      {
        Header: "Stockists",
        accessor: "stockists.length",
        disableFilters: true,
        showTotal: true,
        totalAccessor: "stockists",
      },
      {
        Header: "Backlog",
        accessor: "backlog",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => (
          <span className="fs-2">
            {props?.row?.original?.isBacklog ? (
              <MdDoneAll style={{ color: "#2ecc71" }} />
            ) : (
              <MdClear style={{ color: "#e74c3c" }} />
            )}
          </span>
        ),
        minWidth: 90,
        maxWidth: 90,
      },
      {
        Header: "View",
        accessor: "",
        Cell: (props) => {
          props.row.original.selectedUser = selectedUser?.value
            ? selectedUser?.value
            : props.row.original.submitter?._id;
          console.log(selectedUser?.value);
          console.log(props.row.original);
          return (
            <Link
              to={`${props?.row?.original?._id}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [selectedUser]
  );
  const columnsBacklogReport = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        minWidth: 130,
        maxWidth: 130,
        showTotal: true,
        placeholderTotal: "Total",
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
      {
        Header: "Day",
        accessor: "day",
        minWidth: 130,
        maxWidth: 130,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {new Date(props?.row?.original?.date).toLocaleString("en-us", {
                weekday: "long",
              })}
            </span>
          );
        },
      },
      {
        Header: "Name",
        accessor: "submitter",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Doctors",
        accessor: "doctors.length",
        disableFilters: true,
        showTotal: true,
        totalAccessor: "doctors",
      },
      {
        Header: "Chemists",
        accessor: "chemists.length",
        disableFilters: true,
        showTotal: true,
        totalAccessor: "chemists",
      },
      {
        Header: "Stockists",
        accessor: "stockists.length",
        disableFilters: true,
        showTotal: true,
        totalAccessor: "stockists",
      },
    ],
    [selectedUser]
  );

  const columns3 = useMemo(() => {
    const columns = [
      {
        Header: "Date",
        accessor: "date",
        minWidth: 70,
        maxWidth: 70,
        showTotal: true,
        placeholderTotal: "Total",
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
      {
        Header: "Day",
        accessor: "day",
        minWidth: 60,
        maxWidth: 60,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {new Date(props?.row?.original?.date).toLocaleString("en-us", {
                weekday: "long",
              })}
            </span>
          );
        },
      },
      {
        Header: "Doctors",
        accessor: "",
        Cell: (props) => {
          return (
            <ul>
              {props.row.original.doctors?.map((doctor) => {
                return (
                  <li>
                    {doctor?.target?.name} -{" "}
                    <span className="text-danger">
                      {doctor?.products
                        ?.map(
                          (product) =>
                            `${product?.productName}: ${product.quantity}`
                        )
                        .join(", ")}
                    </span>{" "}
                  </li>
                );
              })}
            </ul>
          );
        },
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "doctors",
      },
      {
        Header: "Chemsits",
        accessor: "",
        Cell: (props) => {
          return (
            <ul>
              {props.row.original.chemists?.map((chemist) => {
                return (
                  <li>
                    {chemist?.target?.businessName} -{" "}
                    <span className="text-danger">
                      {chemist?.products
                        ?.map(
                          (product) =>
                            `${product?.productName}: ${product.quantity}`
                        )
                        .join(", ")}
                    </span>{" "}
                  </li>
                );
              })}
            </ul>
          );
        },
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "chemists",
      },
      {
        Header: "Stockists",
        accessor: "",
        Cell: (props) => {
          return (
            <ul>
              {props.row.original.stockists?.map((stockist) => {
                return (
                  <li>
                    {stockist?.target?.businessName} -{" "}
                    <span className="text-danger">
                      {stockist?.products
                        ?.map(
                          (product) =>
                            `${product?.productName}: ${product.quantity}`
                        )
                        .join(", ")}
                    </span>{" "}
                  </li>
                );
              })}
            </ul>
          );
        },
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "stockists",
      },
    ];
    if (!selectedUser) {
      columns.splice(2, 0, {
        Header: "Submitter",
        accessor: "submitter.firstName",
        minWidth: 50,
        maxWidth: 50,
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "submitter",
      });
    }
    return columns;
  }, [selectedUser]);

  const jointColumns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        minWidth: 90,
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: false,
        showTotal: true,
        placeholderTotal: "Total",
        Cell: (props) => {
          let data = props?.row?.original;
          if (!data?._id) {
            return <span>{formatDate(data?.taggedReport?.date)}</span>;
          }
          return <span>{formatDate(props?.row?.original?.date)}</span>;
        },
      },
      {
        Header: "Day",
        accessor: "day",
        minWidth: 90,
        maxWidth: 100,
        Cell: (props) => {
          let data = props?.row?.original;
          if (!data?._id) {
            return (
              <span>
                {new Date(data?.taggedReport?.date).toLocaleString("en-us", {
                  weekday: "long",
                })}
              </span>
            );
          }
          return (
            <span>
              {new Date(props?.row?.original?.date).toLocaleString("en-us", {
                weekday: "long",
              })}
            </span>
          );
        },
        disableFilters: false,
        disableSortBy: true,
      },
      {
        Header: "Name",
        accessor: "submitter.firstName",
        disableSortBy: true,
        Cell: (props) => <span>{`${selectedUser?.label}`}</span>,
      },
      {
        Header: "Activity",
        accessor: "activity",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          let data = props?.row?.original;
          if (!data?._id) {
            return <span>{data?.taggedReport?.activity}</span>;
          }
          return <span>{data?.activity}</span>;
        },
      },
      {
        Header: "Area-Type",
        accessor: "areaType",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          let data = props?.row?.original;
          if (!data?._id) {
            return <span>{data?.taggedReport?.areaType}</span>;
          }
          return <span>{data?.areaType}</span>;
        },
      },
      {
        Header: "Working Areas",
        accessor: "",
        Cell: (props) => {
          let data = props?.row?.original;
          if (!data?._id) {
            return (
              <span>
                {data?.taggedReport?.workAreas
                  ?.map((el) => {
                    return el?.location?.label;
                  })
                  .join(", ") || "N/A"}
              </span>
            );
          }
          return (
            <span>
              {props.row.original.workAreas
                ?.map((el) => {
                  return el?.location?.label;
                })
                .join(", ") || "N/A"}
            </span>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Doctors",
        accessor: "doctors.length",
        disableFilters: true,
        showTotal: true,
        totalAccessor: "doctors",
        Cell: (props) => {
          let data = props?.row?.original;
          if (!data?._id) {
            return <span>0</span>;
          }
          return <span>{data?.doctors?.length}</span>;
        },
      },
      {
        Header: "Chemists",
        accessor: "chemists.length",
        disableFilters: true,
        showTotal: true,
        totalAccessor: "chemists",
        Cell: (props) => {
          let data = props?.row?.original;
          if (!data?._id) {
            return <span>0</span>;
          }
          return <span>{data?.chemists?.length}</span>;
        },
      },
      {
        Header: "Stockists",
        accessor: "stockists.length",
        disableFilters: true,
        showTotal: true,
        totalAccessor: "stockists",
        Cell: (props) => {
          let data = props?.row?.original;
          if (!data?._id) {
            return <span>0</span>;
          }
          return <span>{data?.stockists?.length}</span>;
        },
      },
      {
        Header: "Joint Calls",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          let data = props?.row?.original;
          let val = 0;
          if (data?.taggedReport) {
            const entities = ["doctors", "stockists", "chemists"];

            entities.forEach((entity) => {
              val += data?.taggedReport?.[entity]?.length;
            });
          }
          return <span>{val}</span>;
        },
        minWidth: 90,
        maxWidth: 90,
      },
      {
        Header: "View",
        accessor: "",
        Cell: (props) => {
          props.row.original.selectedUser = selectedUser?.value
            ? selectedUser?.value
            : props.row.original.submitter?._id;
          return (
            <Link
              to="joint-callReport-details"
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [selectedUser]
  );

  let handleSubmit = async (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    let data = {
      selectedUser: selectedUser?.value,
      startDate: new Date(dateRange?.startDate).getTime(),
      endDate: new Date(dateRange?.endDate).getTime(),
      isAdmin: loggedIn?.user?.isAdmin ? true : null,
      isJointReport: isJointReport,
    };

    const aysncFetch = async () => {
      try {
        if (
          !isbacklogReport &&
          !isWorkedWithReport &&
          !showlastCR &&
          !isJointReport
        ) {
          await viewCallReport(data).then((res) => {
            setCallReports(res.data);
          });
        } else if (isJointReport) {
          if (!selectedUser) {
            return customToast.error("Please select user !");
          }
          await viewCallReport(data).then((res) => {
            setCallReports(res?.data);
          });
        } else if (isbacklogReport) {
          if (!selectedUser) {
            customToast.error(
              "Please select a user to view the backlog report"
            );
            return;
          }
          await viewBacklogReport(data).then((res) => {
            setCallReports(res.data);
          });
        } else if (showlastCR === true) {
          await viewLastCallReport(data).then((res) => {
            setCallReports(res?.data);
          });
        } else {
          if (!selectedUser) {
            customToast.error("Please select a user to view the report");
            return;
          }
          await viewWorkedWithReport(data).then((res) => {
            setCallReports(res.data);
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    aysncFetch();
  }, [
    dateRange,
    selectedUser,
    isbacklogReport,
    isWorkedWithReport,
    showlastCR,
    isJointReport,
  ]);

  const tableData = useMemo(() => serialise(callReports), [callReports]);

  const calculateAverageCalls = (data, dataType) => {
    let total = 0;
    // Iterate through the array and sum up the number of items of the specified type
    data.forEach((item) => {
      if (item[dataType] && Array.isArray(item[dataType])) {
        total += item[dataType].length;
      }
    });

    // Calculate the average
    const numberOfRecords = data.length;
    const average = total / numberOfRecords;
    const roundedArerage = Number(average.toFixed(2));
    return roundedArerage;
  };

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    switch (reportType.value) {
      case "backlog":
        setIsBacklogReport(!isbacklogReport);
        setCallReports([]);
        setIsDetailedReport(false);
        setIsWorkedWithReport(false);
        setShowlastCR(false);
        setIsJointReport(false);
        break;
      case "detailed":
        setIsDetailedReport(!isDetailedReport);
        setIsWorkedWithReport(false);
        setIsBacklogReport(false);
        setShowlastCR(false);
        setIsJointReport(false);
        break;
      case "workedWith":
        setIsWorkedWithReport(!isWorkedWithReport);
        setCallReports([]);
        setIsDetailedReport(false);
        setIsBacklogReport(false);
        setShowlastCR(false);
        setIsJointReport(false);
        break;
      case "last CR":
        setShowlastCR(!showlastCR);
        setCallReports([]);
        setIsDetailedReport(false);
        setIsBacklogReport(false);
        setIsWorkedWithReport(false);
        setIsJointReport(false);
        break;
      case "joint":
        setIsJointReport(!isJointReport);
        setCallReports([]);
        setShowlastCR(false);
        setIsBacklogReport(false);
        setIsDetailedReport(false);
        setIsWorkedWithReport(false);
        break;
      default:
        setCallReports([]);
        setShowlastCR(false);
        setIsBacklogReport(false);
        setIsDetailedReport(false);
        setIsWorkedWithReport(false);
        setIsJointReport(false);
    }
  }, [reportType]);
  return (
    <div className="px-4 py-2">
      <div className="row">
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="web-app__heading"
            // style={{ display: "flex", alignItems: "center" }}
          >
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              {isbacklogReport ? "Backlog Reports" : "Call Reports"}
            </span>
          </div>
        </div>
        {loggedIn?.user?.des < 121 || loggedIn?.user?.isAdmin ? (
          <form onSubmit={handleSubmit}>
            <div className="util-tp-query d-flex justify-content-between gap-3 align-items-center">
              <div className="d-flex gap-4">
                <div className="util-tp-filter">
                  <p className="util-select-user__label">Select Report Type</p>
                  <Select
                    options={showArr}
                    className="mt-3"
                    styles={selectUserStyles}
                    value={reportType}
                    onChange={(e) => setReportType(e)}
                  />
                </div>
                <div className="util-tp-filter">
                  <p className="util-select-user__label">
                    Select User{" "}
                    {isWorkedWithReport && (
                      <span className="asterisk-imp">*</span>
                    )}
                  </p>
                  <div className="d-flex align-items-center">
                    <MdOutlineGroup className="util-select-user__icon" />
                    <Select
                      styles={selectUserStyles}
                      options={employeeSelect}
                      placeholder="Select User"
                      className="mt-3"
                      value={selectedUser}
                      onChange={(e) => setSelectedUser({ ...e })}
                    />
                    <MdOutlineRefresh
                      size={25}
                      className="icon-color-tertiary mt-4 ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedUser(null);
                        setCallReports([]);
                      }}
                    />
                  </div>
                </div>
              </div>
              {selectedUser &&
                callReports?.length > 0 &&
                !isbacklogReport &&
                !isWorkedWithReport && (
                  <div className="avg_data_call_data">
                    <div className="avg_call_data dashboard-stats__metrics-1">
                      <img
                        src={WhiteCircles}
                        alt="..."
                        className="dashboard-stats__metrics-white-circle"
                      />
                      <div className="avg_data_label">Avg. Doctors Call</div>
                      <div className="avg_data_value">
                        {calculateAverageCalls(callReports, "doctors")}
                      </div>
                    </div>
                    <div className="avg_call_data dashboard-stats__metrics-2">
                      <img
                        src={WhiteCircles}
                        alt="..."
                        className="dashboard-stats__metrics-white-circle"
                      />
                      <div className="avg_data_label">Avg. Chemists call</div>
                      <div className="avg_data_value">
                        {calculateAverageCalls(callReports, "chemists")}
                      </div>
                    </div>
                    <div className="avg_call_data dashboard-stats__metrics-3">
                      <img
                        src={WhiteCircles}
                        alt="..."
                        className="dashboard-stats__metrics-white-circle"
                      />
                      <div className="avg_data_label">Avg. Stockists call</div>
                      <div className="avg_data_value">
                        {calculateAverageCalls(callReports, "stockists")}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </form>
        ) : null}

        <div className="date-range-container my-3">
          <DateRange setDateRange={setDateRange} />
        </div>
        <h2 className="web-app__heading mb-4 d-flex flex-col">
          Showing ({callReports?.length}) Entries
          {/* <div style={{marginLeft:"auto"}}  className="d-flex flex-row align-items-center">
            <div style={{ marginTop: "1.4rem" }}>
              {closeIcon ?
                <IoIosCloseCircleOutline className="util-select-user__icon mx-5 icon-color-error"
                  onClick={() => {
                    setCallReports([]);
                    setShowDivMsg(!showDivMsg)
                    setCloseIcon(!closeIcon)
                    setShowlastCR(false)
                    setIsBacklogReport(false)
                    setIsDetailedReport(false)
                    setIsWorkedWithReport(false)
                  }} />
                :
                <PiDotsThreeCircleLight className="util-select-user__icon mx-5"
                  onClick={() => {
                    setCloseIcon(!closeIcon);
                    setShowDivMsg(!showDivMsg)
                  }} />}
            </div>
            {showDivMsg ?
              <div className="d-flex align-items-center position-relative">
                <Select
                  options={showArr}
                  className="mt-3"
                  styles={selectUserStyles}
                  // onChange={() => setShowlastCR(true)}
                  onChange={(e) => {
                    handleCheckboxChange(e?.value)}} 
                  />
              </div>
              :
              null
            }
          </div> */}
        </h2>
        <div className="filter-table">
          {callReports?.length > 0 && (
            <div>
              {tableData.length > 0 && isbacklogReport && (
                <div
                  className="my-3 bg-danger py-2 px-3 "
                  style={{ borderRadius: "9px", width: "fit-content" }}
                >
                  NOTE: These reports are not sent for approval, please{" "}
                  {loggedIn?.user?.isAdmin ? " ask the user to" : ""} send the
                  call reports for approval from
                  <span className="font-weight-bold">
                    {" "}
                    SETTINGS - BACKLOG 📱
                  </span>
                </div>
              )}
            </div>
          )}
          {tableData.length > 0 ? (
            <div>
              {isDetailedReport ? (
                <CustomTable tableData={tableData} />
              ) : isWorkedWithReport ? (
                <CustomTable tableData={tableData} />
              ) : isJointReport ? (
                <Table data={callReports} columns={jointColumns} />
              ) : (
                <Table
                  columns={isbacklogReport ? columnsBacklogReport : columns}
                  exportExcel={isDetailedReport ? true : true}
                  empl={selectedUser ? employeeDataForExcel : loggedIn?.user}
                  data={tableData}
                  selectedUser={selectedUser}
                  fileSrc={isbacklogReport ? "Backlog Report" : "Call Report"}
                  month={{
                    label: `${monthName[startMonth]}`,
                    value: `${startMonth}`,
                  }}
                />
              )}
              <div className="border-1 border-danger"></div>
              {isDetailedReport || isWorkedWithReport ? (
                <ExcelExportButton
                  tableData={tableData}
                  empl={selectedUser ? employeeDataForExcel : loggedIn?.user}
                  fileSrc={
                    isDetailedReport ? "Detailed Report" : "Worked With Report"
                  }
                  month={{
                    label: `${monthName[startMonth]}`,
                    value: `${startMonth}`,
                  }}
                />
              ) : (
                isWorkedWithReport
              )}
            </div>
          ) : (
            <h3 className="mt-2 no-results-message">No results found</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default UtilitesCR;
