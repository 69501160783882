import Card from "./Card";

const UtilitiesIndex = ({ isAdmin }) => {
  return (
    <div className="utility">
      <div className="row">
        <Card title="Tour Program" link="tour-program" iconName="tp" />
        <Card title="Call Reports" link="call-reports" iconName="cr" />
      </div>
      
      <div className="row mt-4">
        {/* <Card title="Sales" link="sales" iconName="sales" /> */}

        <Card
          title="Reminder Calls Reports"
          link="extra-activity-dcr"
          iconName="ead"
        />
        <Card
          title="Missed Reports"
          link="missed-doctor-reports"
          iconName="missedDoc"
        />
      </div>

      <div className="row mt-4">
        <Card
          title="Primary Sales Reports"
          link="primary-sales-reports"
          iconName="stkWise"
        />
        <Card
          title="Secondary Sales Reports"
          link="secondary-sales-reports"
          iconName="stkWise"
        />
      </div>

      <div className="row mt-4">
        <Card
          title="Product-Wise Reports"
          link="product-wise-sales"
          iconName="prodWise"
        />
        <Card title="Lists" link="lists/doctors-list" iconName="lists" />
      </div>

      <div className="row mt-4">
        <Card title="Target" link="targets/user-target" iconName="target" />
        <Card title="POB Reports" link="pob-reports" iconName="pobRep" />
      </div>
      <div className="row mt-4">
        <Card title="Monthly Reports" link="monthly-reports" iconName="lists" />
        <Card title="Annual Reports" link="annual-reports" iconName="lists" />
      </div>

      <div className="row mt-4">
        {isAdmin && (
          <Card
            title="Geo-Location Analysis Report "
            link="geolocation-reports"
            iconName="location"
          />
        )}
        {isAdmin && (
          <Card
            title="Expense Reports"
            link="monthly-expense-report"
            iconName="expense"
          />
        )}
      </div>

      <div className="row mt-4">
        <Card
          title="CheckIn / CheckOut Report"
          link="punching-report"
          iconName="punching"
        />
        {isAdmin && (
          <Card
            title="DCS Duplicate Entries"
            link="duplicate-entries"
            iconName="duplicate"
          />
        )}
      </div>

      <div className="row mt-4">
        <Card title="Call Planning Reports" link="call-planning" iconName="cr" />
      </div>

      {/* <div className="row mt-4">
        <Card title="Missed Doctor Reports" link="missed-doctor-reports" />

        <Card title="Missed Stockist Reports" link="missed-stockist-reports" />
      </div>

      <div className="row mt-4">
        <Card title="Targets vs Achievements" link="targets-achievements" />

        <Card title="Doctor Wise Sales" link="doctor-wise-sales" />
      </div> */}
    </div>
  );
};

export default UtilitiesIndex;
