export const title = "Area Creation";

export const links = [
  {
    name: "Create State",
    route: "create-state",
    accessor: "state",
  },
  {
    name: "Create HeadQuarters",
    route: "create-hq",
    accessor: "hq",
  },
  {
    name: "Create City / Area",
    route: "create-city",
    accessor: "city",
  },
  {
    name: "Create Route",
    route: "create-route",
    accessor: "route",
  },
  {
    name: "Transfer DCS",
    route: "transfer-dcs",
    accessor: "transfer-dcs",
  },
];
