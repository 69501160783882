import React, { useState, useEffect, useMemo } from 'react';
import styles from './style.module.css';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import logo from '../../../public/img/logo.png'
import appStoreLogo from '../../../public/img/app-store.png'
import playStoreLogo from '../../../public/img/google-play-store.png'

const Popup = () => {
  const [isPopupVisible, setPopupVisible] = useState(true);

  const closePopup = () => {
    setPopupVisible(false);
  };

  if (!isPopupVisible) {
    return null;
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <header className={styles.header}>
          <div className={styles.logoWrapper}>
            <a href="/" className={styles.logoLink}>
              <img src={logo} alt="Company Logo" className={styles.logo} />
            </a>
            <div className={styles.titleWrapper}>
              <span className={styles.title}>MEDORN</span>
            </div>
          </div>
                  <IoIosCloseCircleOutline onClick={closePopup} style={{
                      cursor: "pointer",
                      fontWeight: 500
                  }} size={24} />
        </header>
        
        <div className={styles.content}>
          <div className={styles.headingWrapper}>
            <h2 className={styles.heading}>Pay Attention Please</h2>
          </div>
          
          <p className={styles.message}>
            Our site does not support mobile view. For a better experience, please download
            our app or switch to desktop mode. Thank you!
          </p>
          
          <div className={styles.storeLinks}>
            <a href="https://play.google.com/store/apps/details?id=com.medorn.app" target="_blank" rel="noopener noreferrer" className={styles.storeLink}>
              <img src={playStoreLogo} alt="Google Play Store" className={styles.storeLogo} />
            </a>
            <a href="https://apps.apple.com/in/app/medorn-erp/id1671284186" target="_blank" rel="noopener noreferrer" className={styles.storeLink}>
              <img src={appStoreLogo} alt="App Store" className={styles.storeLogo} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;