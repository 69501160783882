import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { dcrStyles } from "../../../public/stylesheets/selectStyles";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { monthOptions, yearOptions } from "../../../../utils/helper";
import Table from "../../../../components/Table";
import {
  viewMonthlyExpenseReport,
  getExpenseReports,
} from "../../../../services/expenses";
import customToast from "../../../../components/CustomToast";
// import DatePickers from "../../../../components/DatePicker";
const DatePickers = lazy(() => import("../../../../components/DatePicker"))

const MonthyExpenseReport = () => {
  const navigate = useNavigate();
  const [expenseData, setExpenseData] = useState([]);
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [fromMonth, setFromMonth] = useState();
  const [fromYear, setFromYear] = useState();
  const [toMonth, setToMonth] = useState();
  const [toYear, setToYear] = useState();
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState({
    value: "Monthly",
    label: "Monthly",
  });

  const reportTypeOptions = useMemo(
    () => [
      { value: "Monthly", label: "Monthly" },
      { value: "headquarter", label: "Headquarter Wise" },
      { value: "location", label: "Location Wise" },
      { value: "category", label: "Category Wise" },
    ],
    []
  );
  const fetchExpenseReports = useCallback(
    async (sm, sy, em, ey, type) => {
      const filter = {
        startDate: new Date(sy, sm, 1),
        endDate: new Date(ey, em + 1, 0), // month is 0-based, so passing `0` for day gives us the last day of the previous month in our case current month bcz of (em+1)
      };
      const { data } = await getExpenseReports(type, filter);
      setExpenseData(data);
    },
    [setExpenseData]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setExpenseData([]);
        setLoading(true);
        let payload = {
          month: Number(month),
          year: Number(year),
        };

        const res = await viewMonthlyExpenseReport(payload);
        setExpenseData(res?.data);
        setLoading(false);
      } catch (error) {
        customToast.error("error occurred !");
        console.log(error);
      }
    };
    if (month && year && reportType.value === "Monthly") {
      fetchData();
    }
  }, [month, year]);
  useEffect(() => {
    if (
      reportType.value !== "Monthly" &&
      // fromMonth &&
      fromYear &&
      // toMonth &&
      toYear
    ) {
      fetchExpenseReports(
        fromMonth,
        fromYear,
        toMonth,
        toYear,
        reportType.value
      );
    }
  }, [fromMonth, toMonth, fromYear, toYear, reportType]);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Designation",
        accessor: "des",
      },
      {
        Header: "Reporting Manager",
        accessor: "reportingManager",
      },
      {
        Header: "Doc. Coverage (%)",
        accessor: "totalDoc",
      },
      {
        Header: "Total Working Days",
        accessor: "workingDays",
      },
      {
        Header: "Sundays",
        accessor: "sundays",
      },
      {
        Header: "Holiday",
        accessor: "holidays",
      },
      {
        Header: "No. Of Days Worked",
        accessor: "totalDaysWorked",
      },
      {
        Header: "Leaves",
        accessor: "leaves",
      },
      {
        Header: "Absent Days",
        accessor: "absent",
      },
      {
        Header: "Travel",
        accessor: "travelAllowance",
      },
      {
        Header: "Food",
        accessor: "foodAllowance",
      },
      {
        Header: "Hotel",
        accessor: "hotelAllowance",
      },
      {
        Header: "Ticket",
        accessor: "ticketAllowance",
      },
      {
        Header: "Daily",
        accessor: "dailyAllowance",
      },
      {
        Header: "Misc.",
        accessor: "misExpense",
      },
      {
        Header: "Total",
        accessor: "total",
      },
    ],
    []
  );

  const locationWiseColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        minWidth: 170,
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        minWidth: 170,
      },
      {
        Header: "Local Area",
        accessor: "local",
        disableFilters: true,
      },
      {
        Header: "Ex Station",
        accessor: "ex-station",
        disableFilters: true,
      },
      {
        Header: "Out Station",
        accessor: "out-station",
        disableFilters: true,
      },
      {
        Header: "Out Ex Station",
        accessor: "out-ex-station",
        disableFilters: true,
      },
      {
        Header: "Out Station Last Day",
        accessor: "out-station-last-day",
        disableFilters: true,
      },
      {
        Header: "Total",
        accessor: "total",
        showTotal: true,
        totalAccessor: "total",
        disableFilters: true,
      },
    ],
    []
  );
  const headquarterWiseColumns = useMemo(
    () => [
      {
        Header: "Headquarter",
        accessor: "headquarter",
        minWidth: 170,
      },
      // {
      //   Header: "Number Of Users",
      //   accessor: "numOfUsers",
      // },
      {
        Header: "Local Area",
        accessor: "local",
        disableFilters: true,
      },
      {
        Header: "Ex Station",
        accessor: "ex-station",
        disableFilters: true,
      },
      {
        Header: "Out Station",
        accessor: "out-station",
        disableFilters: true,
      },
      {
        Header: "Out Ex Station",
        accessor: "out-ex-station",
        disableFilters: true,
      },
      {
        Header: "Out Station Last Day",
        accessor: "out-station-last-day",
        disableFilters: true,
      },
      {
        Header: "Total",
        accessor: "total",
        showTotal: true,
        totalAccessor: "total",
        disableFilters: true,
      },
    ],
    []
  );

  const categoryWiseColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "submitter",
        minWidth: 170,
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        minWidth: 170,
      },
      {
        Header: "Travel",
        accessor: "totalTravelAllowance",
        disableFilters: true,
      },
      {
        Header: "Daily Allowance",
        accessor: "totalDailyAllowance",
        disableFilters: true,
      },
      {
        Header: "Hotel Allowance",
        accessor: "totalHotelAllowance",
        disableFilters: true,
      },
      {
        Header: "Food Allowance",
        accessor: "totalFoodAllowance",
        disableFilters: true,
      },
      {
        Header: "Ticket Allowance",
        accessor: "totalTicketAllowance",
        disableFilters: true,
      },
      {
        Header: "Miscellaneous Allowance",
        accessor: "totalMisExpense",
        disableFilters: true,
      },
      {
        Header: "Total",
        accessor: "total",
        showTotal: true,
        totalAccessor: "total",
        disableFilters: true,
      },
    ],
    []
  );
  const getAppropriateColumns = () => {
    switch (reportType.value) {
      case "location":
        return locationWiseColumns;
      case "category":
        return categoryWiseColumns;
      case "headquarter":
        return headquarterWiseColumns;
      default:
        return columns;
    }
  };
  return (
    <div className="p-3">
      <div className="row">
        <h2 className="web-app__heading d-flex align-items-center">
          <MdArrowBackIosNew
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }}
          />
          <span className="d-block mx-2 my-2">Expense Reports</span>
        </h2>

        <div>
          <div className="util-tp-query d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div className="util-tp-filter me-5">
                <p>Report Type</p>
                <Select
                  styles={dcrStyles}
                  className="mt-2"
                  placeholder="Select Report Type"
                  options={reportTypeOptions}
                  onChange={(e) => {
                    setReportType(e);
                    setExpenseData([]);
                  }}
                  value={reportType}
                />
              </div>

              {reportType.value === "Monthly" && (
                <>
                  {/* <div className="util-tp-filter me-5">
                    <p>Year</p>
                    <Select
                      styles={dcrStyles}
                      className="mt-2"
                      placeholder="Select Year"
                      options={yearOptions}
                      onChange={(e) => setYear(e)}
                    />
                  </div>
                  <div className="util-tp-filter me-5">
                    <p>Month</p>
                    <Select
                      styles={dcrStyles}
                      className="mt-2"
                      placeholder="Select Month"
                      options={monthOptions}
                      onChange={(e) => setMonth(e)}
                    />
                  </div> */}

                  <div className="util-tp-filter me-5">
                    <p className="mb-2">Select Month</p>
                    <Suspense>
                      <DatePickers
                        placeholder="Select Month"
                        setMonth={setMonth}
                        setYear={setYear}
                      />
                    </Suspense>
                  </div>


                </>
              )}
              {reportType.value !== "Monthly" && (
                <>
                  {/* <div className="util-tp-filter me-5">
                    <p>From Month</p>
                    <Select
                      styles={dcrStyles}
                      className="mt-2"
                      placeholder="Select Month"
                      options={monthOptions}
                      onChange={(e) => setFromMonth(e)}
                      value={fromMonth}
                    />
                  </div>
                  <div className="util-tp-filter me-5">
                    <p>From Year</p>
                    <Select
                      styles={dcrStyles}
                      className="mt-2"
                      placeholder="Select Year"
                      options={yearOptions}
                      onChange={(e) => setFromYear(e)}
                      value={fromYear}
                    />
                  </div>
                  <div className="util-tp-filter me-5">
                    <p>To Month</p>
                    <Select
                      styles={dcrStyles}
                      className="mt-2"
                      placeholder="Select Month"
                      options={monthOptions}
                      onChange={(e) => setToMonth(e)}
                      value={toMonth}
                    />
                  </div>

                  <div className="util-tp-filter me-5">
                    <p>To Year</p>
                    <Select
                      styles={dcrStyles}
                      className="mt-2"
                      placeholder="Select Year"
                      options={yearOptions}
                      onChange={(e) => setToYear(e)}
                      value={toYear}
                    />
                  </div> */}

                  <div className="util-tp-filter me-5">
                    <p className="mb-2">From Month</p>
                    <Suspense>
                    <DatePickers
                      placeholder="Select Month"
                      setMonth={setFromMonth}
                      setYear={setFromYear}
                    />
                    </Suspense>
                  </div>
                  <div className="util-tp-filter me-5">
                    <p className="mb-2">To Month</p>
                    <Suspense>
                    <DatePickers
                      placeholder="Select Month"
                      setMonth={setToMonth}
                      setYear={setToYear}
                    />
                    </Suspense>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {loading === true && expenseData?.length === 0 ? (
          <div>Loading ...</div>
        ) : (
          <div className="filter-table" style={{ maxWidth: "119rem" }}>
            <Table columns={getAppropriateColumns()} data={expenseData} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MonthyExpenseReport;
