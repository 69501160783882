import { useState, useCallback, useMemo, useEffect, Suspense, lazy } from "react";
import {
  viewUsersForUtilities,
  viewUtilTargets,
} from "../../../../../services/utilities";
import {
  dcrStyles,
  selectUserStyles,
} from "../../../../public/stylesheets/selectStyles";
import Select from "react-select";
import { useSelector } from "react-redux";
import { monthOptions, yearOptions } from "../../../../../utils/helper";
import { useNavigate } from "react-router-dom";
import serialise, {
  generateSelectData,
  generateSelectUser,
} from "../../../../../utils/serialiseResponse";
import { MdArrowBackIosNew, MdOutlineGroup, MdOutlineRefresh } from "react-icons/md";
import { fetchMonthTargets } from '../../../../../services/reports';
import HeadquarterTarget from "./Types/Headquarter";
import UserTarget from "./Types/User";
import customToast from "../../../../../components/CustomToast";
import { IoReload } from "react-icons/io5";
import moment from "moment-timezone";
const DatePickers = lazy(() => import("../../../../../components/DatePicker"))

const UtilUserTarget = () => {
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);

  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [reports, setReports] = useState([]);
  const [reportData, setreportData] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [employees, setEmployees] = useState([]);
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === selectedUser?.label) return true;
      })
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  const { settings } = useSelector(state => state)
  // console.log(useSelector(state => state))
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!year) {
        customToast("Please Select Month and Year")
        return;
      }
      if (settings['hq-target'] === "true") {
        const filter = {
          month: month,
          year: Number(year),
        };
        if (loggedIn.user.isAdmin === false) {
          filter.isAdmin = false
        }
        const data = await fetchMonthTargets("hqTarget", filter);
        setReports(data);
      } else {
        if (selectedUser) {
          const filter = {
            userId: selectedUser?.value,
            month: month,
            year: Number(year),
          };

          viewUtilTargets("user", filter).then((res) => {
            
            if (res.data) {
              setreportData(res.data);
              setReports(res?.data[0]?.products);
            }
          });
        } else {
          const filter = {
            month: month,
            year: Number(year),
          };

          await viewUtilTargets("allUsers", filter).then((res) => {
            if (res.data) {
              setreportData(res.data);
              setReports(res?.data);
            }
          });
        }
      }
    }, [year, month, selectedUser,settings,loggedIn])


  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
      // if(settings['hq-target'] === "false"){
      // viewUtilTargets("allUsers").then((res) => {
      //   if (res.data) {
      //     setreportData(res.data);
      //     setReports(res?.data);
      //     // console.log(res.data)
      //   }
      // })};
    } catch (error) {
      console.log(error);
    }
  }, []);


  return (
    <main className="main-content admin-content m-5">
      <h2
        className="web-app__heading"
        style={{ display: "flex", alignItems: "center" }}
      >
        <MdArrowBackIosNew
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <span style={{ display: "inline-block", margin: "0 1rem" }}>
          Target Reports
        </span>
      </h2>
      <div className="area-creation-content w-100">

        <form onSubmit={handleSubmit}>
          <div className="util-list-filters d-flex justify-content-between">
            <div className="d-flex">
              <div className="util-tp-filter me-5">
                <p className="mb-3">
                  Select Month <span className="asterisk-imp">*</span>
                </p>
                <Suspense>
                  <DatePickers
                    placeholder="Select Month"
                    setMonth={setMonth}
                    setYear={setYear}
                  />
                </Suspense>
              </div>

            </div>
            <div className="d-flex">
              {settings['hq-target'] === "false" && ((loggedIn?.user?.des < 121 ||
                loggedIn?.user?.designation?.priority > 1 ||
                loggedIn?.user?.isAdmin) ? (
                <div className="util-tp-filter me-5">
                  <p className="util-select-user__label ms-5">Select User</p>
                  <div className="util-select-user">
                    <MdOutlineGroup className="util-select-user__icon"/>
                    <Select
                      styles={selectUserStyles}
                      options={employeeSelect}
                      placeholder="Select User"
                      className="mt-3"
                      value={selectedUser}
                      onChange={(e) => {
                        setSelectedUser({ ...e });
                        setReports([])
                      }}
                    />
                      <MdOutlineRefresh size={25} className="icon-color-tertiary mt-4 ms-2" style={{ cursor: "pointer" }} onClick={()=> {
                      setSelectedUser(null);
                      setReports([]);
                      }} />
                    {/* <IoReload className="util-select-user__icon ms-3" onClick={()=> {
                      setSelectedUser(null);
                      setReports([]);
                      }}/> */}
                  </div>
                </div>
              ) : null)}
              <div className="util-tp-filter">
                <button type="submit" className="button-blue-gradient mt-5">
                  See Reports
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      {settings['hq-target'] === "true" && <HeadquarterTarget employeeDataForExcel={employeeDataForExcel} reports={reports} month={month} year={year}></HeadquarterTarget>}
      {settings['hq-target'] === "false" && <UserTarget employeeDataForExcel={employeeDataForExcel} reports={reports} month={month} year={year} user={selectedUser} setReports={setReports} setSelectedUser={setSelectedUser} setreportData={setreportData} ></UserTarget>}
    </main>
  );
};

export default UtilUserTarget;
