import { MdArrowBackIosNew, MdOutlineGroup, MdOutlineRefresh } from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import { formatDate, monthOptions, yearOptions } from "../../../../utils/helper";
import {
  dcrStyles,
  selectUserStyles,
} from "../../../public/stylesheets/selectStyles";
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  viewMissedReports,
  viewUsersForUtilities,
} from "../../../../services/utilities";
import Table from "../../../../components/Table";
import { generateSelectData, generateSelectUser } from "../../../../utils/serialiseResponse";
import { FaEye } from "react-icons/fa";
import DateRange from "../../../../components/DateRange";
import { addDays } from "date-fns";
import { RiCloseCircleLine } from "react-icons/ri";
import { viewTargets } from "../../../../services/targets";
import MonthWiseReports from "./MonthWiseReports";
import moment from "moment-timezone";
import customToast from "../../../../components/CustomToast";

const typeOptions = [
  { value: 1, label: "Doctor" },
  { value: 2, label: "Chemist" },
  { value: 3, label: "Stockist" },
];

const MissedDocReports = () => {
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);

  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState(yearOptions[0]);
  const [type, setType] = useState( { value: 1, label: "Doctor" });
  const [reports, setReports] = useState([]);
  const [displayReports, setDisplayReport] = useState([])
  const [monthly, setMonthtly] = useState(false);

  const [showEdit, setShowEdit] = useState(false);

  const [finalReport, setfinalReport] = useState([]);
  const [showAllMissed, setshowAllMissed] = useState(false);

  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();

  //DisplayReports are used to show the reports on calendar page without duplicates
  //Reports contain duplicates and are sent to view details page
  //FinalReport is used for showing missed doc / chem / stock on calendar page

  const EditPopup = ({ data }) => {
    return (
      <section
        className="edit-popup"
        onClick={(e) =>
          showEdit
            ? e.target.className === "edit-popup"
              ? setShowEdit(false)
              : null
            : null
        }
      >
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Missed Reports</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => {
                setShowEdit(false);
                setshowAllMissed(false);
              }}
            />
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowEdit(false);
            }}
          >
            <div className="edit-form">
              <div>
                <label htmlFor="division"></label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container ">
              <div className="util-tp-filter me-5">
                <p>
                  Select Month <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Month"
                  className="mt-3"
                  options={monthOptions}
                  value={month}
                  onChange={(e) => setMonth({ ...e })}
                />
              </div>

              <div className="util-tp-filter me-5 ">
                <p>
                  Select Year <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Year"
                  className="mt-3"
                  options={yearOptions}
                  value={year}
                  onChange={(e) => setYear({ ...e })}
                />
              </div>

              <div className="util-tp-filter me-5 ">
                <label htmlFor="division"></label>
                <button
                  className="mt-3 p-2 button-submit-green"
                  onClick={(e) => handleAllVisit(e)}
                >
                  View Reports
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );

  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
  });

  const columns = useMemo(
    () => [
      {
        Header: "Met/Missed",
        accessor: "",
        minWidth: 80,
        maxWidth: 100,
        Cell: (props) => {
          if (
            props?.row?.original?.target?.category?.label &&
            props?.row?.original?.target?.category?.label
              .toLowerCase()
              .substring(0, 1) === "s"
          ) {
            if (props.row.original?.meetingDates?.length >= 3) {
              return <span className="approved-text">Met</span>;
            } else {
              return <span className="pending-text">Partially Missed</span>;
            }
          } else if (
            props?.row?.original?.target?.category?.label &&
            props?.row?.original?.target?.category?.label
              .toLowerCase()
              .substring(0, 1) === "c"
          ) {
            if (props.row.original?.meetingDates?.length >= 2) {
              return <span className="approved-text">Met</span>;
            } else {
              return <span className="pending-text">Partially Missed</span>;
            }
          } else {
            if (props.row.original?.meetingDates?.length >= 1) {
              return <span className="approved-text">Met</span>;
            } else {
              return <span className="pending-text">Partially Missed</span>;
            }
          }
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Doctor Name",
        accessor: "target.name",
        minWidth: 80,
        maxWidth: 100,
      },
      {
        Header: "Degree",
        accessor: "target.degree",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Category",
        accessor: "target.category.label",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Expected Visit",
        accessor: "",
        Cell: (props) => {
          if (
            props?.row?.original?.target?.category?.label &&
            props?.row?.original?.target?.category?.label
              .toLowerCase()
              .substring(0, 1) === "s"
          ) {
            return <span>3</span>;
          } else if (
            props?.row?.original?.target?.category?.label &&
            props?.row?.original?.target?.category?.label
              .toLowerCase()
              .substring(0, 1) === "c"
          ) {
            return <span>2</span>;
          } else {
            return <span>1</span>;
          }
        },
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 80,
      },
      {
        Header: "Actual Visits",
        accessor: "meetingDates.length",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 80,
      },
      // {
      //   Header: "Duplicate Entires",
      //   accessor: "",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   Cell: (props) => {
      //     return (
      //       <span className={(props.row.original?.visitedCount -
      //         props.row.original?.meetingDates?.length)>0 ? "text-danger": ""}>
      //         {props.row.original?.visitedCount -
      //           props.row.original?.meetingDates?.length}
      //       </span>
      //     );
      //   },
      // },
      {
        Header: "Meeting Date",
        accessor: "",
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.meetingDates?.map((date) => {
                return (
                  <span>
                    {formatDate(date)}
                    {" |\n "}
                  </span>
                );
              })}
            </span>
          );
        },
        minWidth: 80,
        maxWidth: 80,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [selectedUser, loggedIn, displayReports, reports]
  );
  const chemStkColData = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "businessName",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Met / Missed",
        accessor: "status",
        Cell: (props) => {
          return props.row.original?.isMet === 1 ? (
            <span className="approved-text">Met</span>
          ) : (
            <span className="pending-text">Partially Missed</span>
          );
        },
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
      },
      {
        Header: "Date",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props.row.original?.cdtOfReport
                ? formatDate(props.row.original?.cdtOfReport)
                : "NA"}
            </span>
          );
        },
      },
      {
        Header: "Time",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props.row.original?.cdtOfReport
                ? moment(props.row.original?.cdtOfReport)
                    .tz("Asia/Kolkata")
                    .format("hh:mm A")
                : "NA"}
            </span>
          );
        },
      },
    ],
    [displayReports, reports]
  );

  const missedDocs = useMemo(
    () => [
      {
        Header: "Met/Missed",
        accessor: "a",
        Cell: (props) => {
          if (
            props?.row?.original?.doc?.doctorId?.target?.category?.label &&
            props?.row?.original?.doc?.doctorId?.target?.category?.label
              .toLowerCase()
              .substring(0, 1) === "s"
          ) {
            if (props.row.original?.count >= 3) {
              return <span className="approved-text">Met</span>;
            } else {
              return <span className="rejected-text">Missed</span>;
            }
          } else if (
            props?.row?.original?.doc?.doctorId?.target?.category?.label &&
            props?.row?.original?.doc?.doctorId?.target?.category?.label
              .toLowerCase()
              .substring(0, 1) === "c"
          ) {
            if (props.row.original?.count >= 2) {
              return <span className="approved-text">Met</span>;
            } else {
              return <span className="rejected-text">Missed</span>;
            }
          } else {
            if (props.row.original?.count >= 1) {
              return <span className="approved-text">Met</span>;
            } else {
              return <span className="rejected-text">Missed</span>;
            }
          }
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Doctor Name",
        accessor: "doc.doctorId.name",
      },
      {
        Header: "Degree",
        accessor: "doc.doctorId.degree",
        disableFilters: true,
        disableSortBy: true,
      },
      // {
      //   Header: "Specialization",
      //   accessor: "doc.doctorId.target.specialization",
      //   disableFilters: true,
      //   disableSortBy: true,
      // },
      {
        Header: "Category",
        accessor: "doc.doctorId.category.label",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [selectedUser, loggedIn]
  );

  const MissedchemStkColData = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "businessName",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return <span>{props.row.original?.target?.businessName}</span>;
        },
      },
      {
        Header: "Met / Missed",
        accessor: "status",
        Cell: (props) => {
          return props.row.original?.status === 1 ? (
            <span className="approved-text">Met</span>
          ) : (
            <span className="rejected-text">Missed</span>
          );
        },
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
      },
      {
        Header: "Contact",
        accessor:
          type.label === "Chemist" ? "chemistContact" : "stockistContact",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          let contact =
            type.label === "Chemist"
              ? props.row.original?.target?.chemistContact
              : props.row.original?.target?.stockistContact;
          return <span>{contact}</span>;
        },
      },
    ],
    [displayReports]
  );

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === selectedUser.label) return true;
      });
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  useEffect(() => {
    setshowAllMissed(false);
    let data = {
      selectedUser: selectedUser?.value,
      // startDate: new Date(dateRange?.startDate).getTime(),
      // endDate: new Date(dateRange?.endDate),
      startDate: dateRange.startDate.getTime(),
      endDate: dateRange.endDate,
    };

    const aysncFetch = async () => {
      viewMissedReports(type?.label?.toLowerCase(), {
        selectedUser: selectedUser?.value,
        startDate: data.startDate,
        endDate: addDays(data.endDate, 1).getTime(),
        betweenDate: true,
      })
        .then((resp) => {
          setReports(resp.data);
        })
        .catch((err) => {
          setReports([]);
          console.log(err);
        });

      viewMissedReports(type?.label?.toLowerCase(), {
        selectedUser: selectedUser?.value,
        startDate: data.startDate,
        endDate: addDays(data.endDate, 1).getTime(),
        betweenDate: true,
        displayAllDocReport: true,
      })
        .then((resp) => {
          setDisplayReport(resp.data);
        })
        .catch((err) => {
          setDisplayReport([]);
          console.log(err);
        });
    };
    aysncFetch();
  }, [dateRange, type, selectedUser]);

  const showPopUp = (e) => {
    e.preventDefault();
    setShowEdit(true);
  };

  useEffect(() => {
    let report = [];
    const aysncFetch = async () => {
      let resp = await viewMissedReports(type?.label?.toLowerCase(), {
        selectedUser: selectedUser?.value,
        month: month?.value,
        year: year?.label,
        isMonthYear: true,
        fetchMissedDocs: true,
      })
        .then((re) => {
          report = re.data;

          setfinalReport(re.data);
        })
        .catch((err) => {
          setfinalReport([]);
          console.error(err);
        });
    };

    aysncFetch();
  }, [showAllMissed, month, year]);

  const handleAllVisit = (e) => {
    e.preventDefault();
    if (month && year) {
      setShowEdit(false);
      setshowAllMissed(true);
    } else {
      return customToast.error("Please select month and year");
    }
  };

  return (
    <div className="expense">
      <div className="row">
        <div className="d-flex justify-content-between">
          <h2
            className="web-app__heading"
            style={{ display: "flex", alignItems: "center" }}
          >
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              Missed Reports
            </span>
          </h2>

          <div className="form__control " style={{ width: "fit-content" }}>
            <div className="web-app__heading pe-3">Monthly Reports</div>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={monthly}
                onClick={() => {
                  setMonthtly(!monthly);
                }}
              />
              <span />
            </label>
          </div>
        </div>
        {monthly && <MonthWiseReports />}
        {!monthly && (
          <>
            {" "}
            <form>
              <div className="util-tp-query d-flex justify-content-between">
                <div className="d-flex">
                  <div className="d-flex">
                    <div className="util-tp-filter mx-3">
                      <p>
                        Select Doc / Chem / Stk{" "}
                        <span className="asterisk-imp">*</span>
                      </p>
                      <Select
                        styles={dcrStyles}
                        placeholder="Select Type"
                        className="mt-3"
                        options={typeOptions}
                        value={type}
                        onChange={(e) => setType({ ...e })}
                      />
                    </div>
                  </div>

                  {loggedIn?.user?.des < 121 || loggedIn?.user?.isAdmin ? (
                    <div className="util-tp-filter mx-3">
                      <p className="util-select-user__label">Select User</p>
                      <div className="util-select-user">
                        <MdOutlineGroup className="util-select-user__icon" />
                        <Select
                          styles={selectUserStyles}
                          options={employeeSelect}
                          placeholder="Select User"
                          className="mt-3"
                          value={selectedUser}
                          onChange={(e) => setSelectedUser({ ...e })}
                        />
                        <MdOutlineRefresh
                          size={25}
                          className="icon-color-tertiary mt-4 ms-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => setSelectedUser(null)}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                {selectedUser && type.value === 1 ? (
                  <div className="edit-form-button-container">
                    <button
                      className="p-3 m-5 button-submit-green "
                      onClick={(e) => showPopUp(e)}
                    >
                      Show all missed visits
                    </button>
                  </div>
                ) : null}
              </div>
            </form>
            <div className="date-range-container my-3">
              <DateRange setDateRange={setDateRange} />
            </div>
            <div className="filter-table">
              {showAllMissed && finalReport.length > 0 && (
                <h2 className="web-app__heading mb-4">
                  Showing ({finalReport.length}) Missed {type.label}
                </h2>
              )}
              {showAllMissed && finalReport.length === 0 && (
                <h3 className="mt-2 no-results-message">No results found</h3>
              )}

              {showAllMissed && finalReport.length > 0 && (
                <Table
                  columns={
                    type?.label?.toLowerCase() === "doctor"
                      ? missedDocs
                      : MissedchemStkColData
                  }
                  data={finalReport}
                  defaultPageSize={50}
                  empl={selectedUser ? employeeDataForExcel : loggedIn?.user}
                  fileSrc="Missed Reports"
                  month={month}
                  // exportExcel={false}
                  // callback={()=>exportEXCEL()}
                />
              )}
              {!showAllMissed && displayReports.length > 0 && (
                <h2 className="web-app__heading my-4">
                  Showing ({displayReports.length}) Entries
                </h2>
              )}
              {!showAllMissed && displayReports.length > 0 && (
                <Table
                  columns={
                    type?.label?.toLowerCase() === "doctor"
                      ? columns
                      : chemStkColData
                  }
                  data={displayReports}
                  defaultPageSize={50}
                  empl={selectedUser ? employeeDataForExcel : loggedIn?.user}
                  fileSrc="Missed Reports"
                  month={monthOptions.at(dateRange.startDate.getMonth())}
                  // exportExcel={false}
                  // callback={(data)=>console.log(displayReports)}
                />
              )}

              {!showAllMissed && displayReports.length === 0 && (
                <h3 className="mt-2 no-results-message">No results found</h3>
              )}
            </div>{" "}
          </>
        )}
        {showEdit && <EditPopup />}
      </div>
    </div>
  );
};

export default MissedDocReports;
